import React from "react";
import cl from "classnames";

// Images
import { HighlightOffOutlinedIcon } from "assets/icons";

// Scss
import styles from "../GroupParamsList.module.scss";

// Typescript
import { ParamItemProps } from "./ParamItem.props";
import { ParamsLangDecode } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";

const ParamItem: React.FC<ParamItemProps> = ({ param, selected, onDelete }): JSX.Element => {
  return (
    <li className={cl(styles["param"], { [styles["param_selected"]]: selected })}>
      <span className={cl(styles["param__title"])}>{param.translation.title}</span>
      <small className={cl(styles["param__type"])}>{ParamsLangDecode[param.type] ?? "Не указан"}</small>
      {checkAccessLevel("catalogue.params.admin") && (
        <HighlightOffOutlinedIcon onClick={onDelete} className={cl(styles["param__cross-close"])} />
      )}
    </li>
  );
};

export default React.memo(ParamItem);
