import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";
import { v4 } from "uuid";

// Material UI
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// Services
import { ProducersAPI, ProductsTypesAPI } from "services/catalogue";

// Popups
import { ProducerFormPopup, ProductTypeFormPopup } from "popups";
// Components
import { Loader } from "components/Elements";
// Blocks
import { ImagesList } from "blocks";
// Images
import { DeleteOutlineOutlinedIcon, EditOutlinedIcon, FileUploadOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ProductForm.module.scss";

// Typescript
import { ProductFormFields, ProductFormProps } from "./ProductForm.props";
import { IProducer, IProductType, ListImage } from "types/models";

export const ProductForm: React.FC<ProductFormProps> = ({
  actionType,
  initialImages = [],
  changePopupVisibility,
  onSavedImageDelete,
  onSubmit,
  onCancel,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const filesInputRef = useRef<HTMLInputElement>(null);

  const {
    setValue,
    setError,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
    control,
  } = useFormContext<ProductFormFields>();
  const watchedValues = useWatch({ control });
  const { imagesFiles, producer, productType } = watchedValues;

  const [images, setImages] = useState<ListImage[]>(initialImages);
  const [flags, setFlags] = useState({ isSubmitting: false, isPending: true });
  const [suggestions, setSuggestions] = useState<{ producers: IProducer[]; types: IProductType[] }>({
    producers: [],
    types: [],
  });

  const loadProducers = async () => {
    const producers = await ProducersAPI.findAll();
    setSuggestions((state) => ({ ...state, producers: producers.rows }));
  };

  const loadProductsTypes = async () => {
    const productsTypes = await ProductsTypesAPI.findAll();
    setSuggestions((state) => ({ ...state, types: productsTypes.rows }));
  };

  useEffect(() => {
    Promise.all([loadProductsTypes(), loadProducers()]).finally(() =>
      setTimeout(() => setFlags((state) => ({ ...state, isPending: false })), 200),
    );
  }, []);

  const handleFormSubmit: SubmitHandler<ProductFormFields> = async (data, event) => {
    setFlags((state) => ({ ...state, isSubmitting: true }));

    await new Promise((resolve) => {
      resolve(onSubmit(data, event));
    }).finally(() => setFlags((state) => ({ ...state, isSubmitting: false })));
  };

  // Слушатель открытия поп-ап окна выбора фотографии
  const handleAttachPhotoPopupOpen = () => {
    if (filesInputRef && filesInputRef.current) {
      filesInputRef.current.click();
    }
  };

  const handleCreateProductTypeClick = () => {
    popupManager.open(ProductTypeFormPopup, {
      context: {},
      actionType: "creation",
      onSuccessServerResponse: async () => {
        setFlags((state) => ({ ...state, isPending: true }));
        await loadProductsTypes().finally(() => setFlags((state) => ({ ...state, isPending: false })));
      },
    });
  };

  const handleCreateProducerClick = () => {
    popupManager.open(ProducerFormPopup, {
      context: {},
      actionType: "creation",
      onSuccessServerResponse: async () => {
        setFlags((state) => ({ ...state, isPending: true }));
        await loadProducers().finally(() => setFlags((state) => ({ ...state, isPending: false })));
      },
    });
  };

  const handleFilesInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;

    if (target && target.files) {
      const newDisplayedImages: ListImage[] = [];
      const attachedFiles: { id: string; file: File }[] = [];

      for (let i = 0; i < target.files.length; i++) {
        const file = target.files[i];
        const fileId = v4();

        attachedFiles.push({ id: v4(), file });
        newDisplayedImages.push({ id: fileId, name: file.name, url: window.URL.createObjectURL(file) });
      }

      setValue("imagesFiles", [...(imagesFiles as { id: string; file: File }[]), ...attachedFiles]);
      setImages((state) => [...state, ...newDisplayedImages]);
    }
  };

  // Слушатель клика по иконке "корзина" на карточке фотографии
  const onImageDeleteClick = async (image: ListImage) => {
    // Если id изображения – uuid, то просто удаляем его из массивов
    if (image.id.toString().includes("-")) {
      setImages((state) => state.filter((i) => i.id !== image.id));

      const filteredFiles = (imagesFiles as { id: string; file: File }[]).filter((i) => i.id !== image.id);
      setValue("imagesFiles", filteredFiles);
    } else {
      onSavedImageDelete(image.id).then(() => setImages((state) => state.filter((i) => i.id !== image.id)));
    }
  };

  const handleAutocompleteChange =
    (inputName: "producer" | "productType") => (_: React.SyntheticEvent, value: IProducer | IProductType | null) => {
      if (value === null) {
        setValue(inputName, value);
        return;
      }

      if (inputName === "producer") setValue(inputName, value as IProducer);
      if (inputName === "productType") setValue(inputName, value as IProductType);
    };

  const validateValue = (fieldName: keyof ProductFormFields) => () => {
    if (watchedValues[fieldName] === undefined) setError(fieldName, { message: "Поле не может быть пустым" });
    else clearErrors(fieldName);
  };

  const handleEntityRemove = (entityType: "producer" | "product-type", id: string) => async (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (entityType === "producer") {
      await ProducersAPI.delete(id).then(() => {
        enqueueSnackbar("Производитель успешно удален", { variant: "success" });
        loadProducers();
      });
    } else {
      await ProductsTypesAPI.delete(id).then(() => {
        enqueueSnackbar("Тип товаров успешно удален", { variant: "success" });
        loadProductsTypes();
      });
    }
  };

  const handleEntityEdit =
    (entityType: "producer" | "product-type", entity: IProducer | IProductType) => async (evt: React.MouseEvent) => {
      evt.stopPropagation();
      changePopupVisibility(true);

      if (entityType === "producer") {
        popupManager.open(ProducerFormPopup, {
          actionType: "editing",
          context: { editProducerId: (entity as IProducer).name },
          defaultValues: { name: (entity as IProducer).name, description: (entity as IProducer).description },
          onSuccessServerResponse: loadProducers,
          onClose: () => changePopupVisibility(false),
        });
      } else {
        popupManager.open(ProductTypeFormPopup, {
          actionType: "editing",
          context: { editProductTypeId: (entity as IProductType).identifier },
          defaultValues: {
            title: (entity as IProductType).title,
            description: (entity as IProductType).description,
            identifier: (entity as IProductType).identifier,
          },
          onSuccessServerResponse: loadProductsTypes,
          onClose: () => changePopupVisibility(false),
        });
      }
    };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
      <Loader isActive={flags.isPending} />

      <TextField
        required
        fullWidth
        type='text'
        label='Название'
        placeholder='Введите название товара'
        {...register("title")}
        error={errors["title"]?.message !== undefined}
        helperText={errors["title"]?.message}
      />
      <TextField
        required
        fullWidth
        type='text'
        label='Артикул'
        placeholder='Введите артикул товара'
        {...register("reference")}
        error={errors["reference"]?.message !== undefined}
        helperText={errors["reference"]?.message}
      />

      <div className={cl(styles["form__autocomplete"])}>
        <Autocomplete
          fullWidth
          value={(producer as IProducer) ?? null}
          onChange={handleAutocompleteChange("producer")}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option?.name ?? "Неизвестно"}
          options={suggestions.producers}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              onBlur={validateValue("producer")}
              label='Производитель'
              placeholder='Поиск производителя'
              error={!!errors["producer"]}
              helperText={errors["producer"]?.message}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.name}
              <Box sx={{ marginLeft: "auto", display: "flex", gap: "8px" }}>
                <IconButton type='button' onClick={handleEntityEdit("producer", option)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton type='button' onClick={handleEntityRemove("producer", option.name)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
            </li>
          )}
        />
        <Button
          size='large'
          type='button'
          variant='outlined'
          sx={{ "height": "56px", "width": "160px" }}
          onClick={handleCreateProducerClick}
        >
          Добавить
        </Button>
      </div>

      <div className={cl(styles["form__autocomplete"])}>
        <Autocomplete
          fullWidth
          value={(productType as IProductType) ?? null}
          onChange={handleAutocompleteChange("productType")}
          isOptionEqualToValue={(option, value) => option.identifier === value.identifier}
          getOptionLabel={(option) => option?.title ?? "Неизвестно"}
          options={suggestions.types}
          renderInput={(params) => (
            <TextField
              required
              label='Тип товара'
              placeholder='Поиск типа товара'
              {...params}
              onBlur={validateValue("productType")}
              error={!!errors["productType"]}
              helperText={errors["productType"]?.message}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.title}
              <Box sx={{ marginLeft: "auto", display: "flex", gap: "8px" }}>
                <IconButton type='button' onClick={handleEntityEdit("product-type", option)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton type='button' onClick={handleEntityRemove("product-type", option.identifier)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
            </li>
          )}
        />
        <Button
          type='button'
          variant='outlined'
          size='large'
          sx={{ "height": "56px", "width": "160px" }}
          onClick={handleCreateProductTypeClick}
        >
          Добавить
        </Button>
      </div>

      <TextField
        rows={4}
        fullWidth
        multiline
        type='text'
        label='Описание'
        placeholder='Введите описание товара'
        {...register("description")}
        error={errors["description"]?.message !== undefined}
        helperText={errors["description"]?.message}
      />

      <Button type='button' variant='text' startIcon={<FileUploadOutlinedIcon />} onClick={handleAttachPhotoPopupOpen}>
        Добавить фото
      </Button>

      <input
        multiple
        type='file'
        ref={filesInputRef}
        style={{ display: "none" }}
        onChange={handleFilesInputChange}
        accept='image/jpeg,image/pjpeg,image/png,image/svg+xml,image/webp'
      />

      <ImagesList images={images} onImageDelete={onImageDeleteClick} className={cl(styles["form__images"])} />

      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          sx={{ width: "100%" }}
          loading={flags.isSubmitting}
          disabled={flags.isSubmitting}
        >
          {actionType === "creation" ? "Создать" : "Сохранить"}
        </LoadingButton>
        <Button size='large' onClick={onCancel} type='button' variant='outlined' sx={{ width: "100%" }}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
