import { atom } from "jotai";
import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { isDefined } from "class-validator";
import { GlobalAPI } from "../GlobalAPI";

// Redux
import { store } from "index";

// Typescript
import { IParamValue, IParamValueWithLang, IProduct, IProducer, IProductAnalogueWithProduct } from "types/models";
import { MultiResponse } from "types/MultiResponse";

class ProductsAPI extends GlobalAPI {
  async create(categoryId: number | string, productData: FormData): Promise<IProduct> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет создание продукта"),
      variant: "action",
    });

    return this.axios
      .post(`/categories/${categoryId}/products`, productData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findAll(
    categoryId: number | string,
    params: { page: number; amount: number; scope: string[] } & Record<string, unknown>,
  ): Promise<MultiResponse<IProduct>> {
    const { catalogueReducer } = store.getState();
    const { sortingType, showParental } = catalogueReducer;

    return this.axios
      .get(`/categories/${categoryId}/products`, {
        params: isDefined(showParental)
          ? { ...params, sorting: sortingType, scope: params.scope.join(",") }
          : { ...params, sorting: sortingType, scope: params.scope.join(",") },
      })
      .then((res) => res.data);
  }

  async findOne<T extends IProduct>(productId: string | number, scope: string[] = []): Promise<T> {
    return this.axios.get(`/products/${productId}`, { params: { scope: scope.join(",") } }).then((res) => res.data);
  }

  async findValues(productId: number | string): Promise<MultiResponse<IParamValue>> {
    return this.axios.get(`/products/${productId}/params/values`).then((res) => res.data);
  }

  async findAnalogues(productId: number | string, params: Record<string, unknown>): Promise<MultiResponse<IProduct>> {
    return this.axios
      .get(`/products/${productId}/analogues`, { params: { page: 1, amount: 100, ...params } })
      .then((res) => res.data);
  }

  async createAnalogue(
    productId: number | string,
    analogueId: number | string,
  ): Promise<MultiResponse<IProductAnalogueWithProduct>> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет создание аналога продукта"),
      variant: "action",
    });

    return this.axios
      .put(`/products/${productId}/analogues/${analogueId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async deleteAnalogue(
    productId: number | string,
    analogueId: number | string,
  ): Promise<MultiResponse<IProductAnalogueWithProduct>> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет удаление аналога продукта"),
      variant: "action",
    });

    return this.axios
      .delete(`/products/${productId}/analogues/${analogueId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findValuesWithLang(productId: number | string): Promise<MultiResponse<IParamValueWithLang>> {
    return this.axios.get(`/products/${productId}/params/values`, { params: { params: true } }).then((res) => res.data);
  }

  async createReference(categoryId: number | string, productId: number | string): Promise<IProduct> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет удаление ярлыка продукта"),
      variant: "action",
    });

    return this.axios
      .post(`/categories/${categoryId}/products/${productId}/references`, {})
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async update(
    productId: number | string,
    productData: FormData | Record<string, unknown>,
    isFormData: boolean,
  ): Promise<IProduct> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет обновление продукта"),
      variant: "action",
    });

    return this.axios
      .patch(`/products/${productId}`, productData, {
        headers: isFormData ? { "Content-Type": "multipart/form-data" } : {},
      })
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async delete(productId: number, skipSnackbar = false): Promise<IProduct> {
    let snackBarKey: string | number = "";
    if (!skipSnackbar) {
      snackBarKey = enqueueSnackbar({
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        atomMessage: atom("Идет удаление продукта"),
        variant: "action",
      });
    }

    return this.axios
      .delete(`/products/${productId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(snackBarKey), 200));
  }

  async deleteReference(categoryId: number | string, productId: number | string): Promise<IProduct> {
    const key = enqueueSnackbar({
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      atomMessage: atom("Идет удаление ярлыка продукта"),
      variant: "action",
    });

    return this.axios
      .delete(`/categories/${categoryId}/products/${productId}/references`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async deleteImage(productId: number | string, imageId: number | string): Promise<IProduct> {
    const key = enqueueSnackbar("Идет удаление фотографии продукта", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/products/${productId}/images/${imageId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async updateValues(productId: number, values: Record<string, string>) {
    const key = enqueueSnackbar("Идет обновление значений параметров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/products/${productId}/params/values`, { values })
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async copy(productId: number) {
    const key = enqueueSnackbar("Идет дублирование продукта", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/products/${productId}/duplicate`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async search(text: string, params?: Record<string, unknown>): Promise<MultiResponse<IProduct>> {
    return this.axios.get(`/products/search-by-text?text=${text}`, { params }).then((res) => res.data);
  }

  async loadProducers(page = 1, amount = 100): Promise<MultiResponse<IProducer>> {
    return this.axios.get(`/producers`, { params: { page, amount } }).then((res) => res.data);
  }
}

export default new ProductsAPI();
