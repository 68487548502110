import React, { useRef } from "react";
import cl from "classnames";
import { useOnClickOutside } from "usehooks-ts";

// Material UI
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";

// Services
import SessionsAPI from "services/passport/SessionsAPI";

// Images
import { LogoutOutlinedIcon, ManageAccountsOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ProfileModal.module.scss";

// Redux
import { useAppSelector } from "hooks/redux";

// Typescript
import { ProfileModalProps } from "./ProfileModal.props";

export const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose, ...props }): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { user } = useAppSelector((state) => state.authReducer);

  const handlePopupClose = () => {
    if (onClose) onClose({}, "backdropClick");
  };

  useOnClickOutside(modalRef, handlePopupClose);

  const handleLogoutClick = async () => {
    await SessionsAPI.logout().then(() => window.location.reload());
  };

  const handleSetupProfileClick = () => {
    window.location.href = "https://passport.rm-solution.ru/settings/account";
  };

  return (
    <Popover
      ref={modalRef}
      open={isOpen ?? false}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ marginTop: "8px", borderRadius: "8px" }}
      TransitionComponent={Fade}
      onClose={onClose}
      {...props}
    >
      <div className={cl(styles["modal"])}>
        <div className={cl(styles["modal__profile"])}>
          <img className={cl(styles["modal__profile-image"])} src={user?.image || ""} />
          <span className={cl(styles["modal__profile-fullName"])}>
            {user?.name ?? "Неизвестно"} {user?.surname ?? "Неизвестно"}
          </span>
          <span className={cl(styles["modal__profile-email"])}>{user?.email ?? "Неизвестно"}</span>
        </div>
        <i className={cl(styles["modal__section-separator"])} />
        <div className={cl(styles["modal__actions"])}>
          <button type='button' onClick={handleSetupProfileClick} className={cl(styles["modal__button"])}>
            <ManageAccountsOutlinedIcon className={cl(styles["modal__button-icon"])} />
            <span className={cl(styles["modal__button-label"])}>Настроить профиль</span>
          </button>
        </div>
        <i className={cl(styles["modal__section-separator"])} />
        <button
          type='button'
          onClick={handleLogoutClick}
          className={cl(styles["modal__button"], styles["modal__button_light"])}
        >
          <LogoutOutlinedIcon className={cl(styles["modal__button-icon"])} />
          <span className={cl(styles["modal__button-label"])}>Выйти из аккаунта</span>
        </button>
      </div>
    </Popover>
  );
};
