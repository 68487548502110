import { SubmitHandler } from "react-hook-form";
import * as Yup from "yup";

export interface GroupFormProps {
  actionType: "creation" | "editing";
  onSubmit: SubmitHandler<GroupFormFields>;
  onCancel: () => void;
}

export interface GroupFormFields {
  title: string;
  description: string;
}

export const paramsGroupValidationSchema = Yup.object()
  .shape({
    title: Yup.string()
      .min(4, "Минимальная длина 4 символа")
      .max(80, "Максимальная длина 80 символов")
      .required("Название группы не может быть пустым полем"),
    description: Yup.string().nullable(),
  })
  .required();
