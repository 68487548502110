import { Dispatch } from "@reduxjs/toolkit";

// Services
import PassportAPI from "services/passport/PassportAPI";

// Slices
import { authSlice } from "store/reducers/AuthSlice";

export const fetchUserData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const userData = await PassportAPI.loadUser().catch(() => {
      dispatch(authSlice.actions.handleLoginError());
      return null;
    });

    if (!userData) return;

    dispatch(authSlice.actions.setUserACL(userData.acl.map((i) => i.microserviceACLId)));
    dispatch(authSlice.actions.setCurrentUser(userData));
  };
