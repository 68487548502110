import React, { useCallback, useState } from "react";
import cl from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import _get from "lodash/get";

// Material UI
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Images
import { BugReportOutlinedIcon } from "assets/icons";
// Layouts
import { AutomationAction } from "layouts";
// Forms
import { AutomationFormFields } from "blocks/Forms";

// Scss
import styles from "./SetupParametersForm.module.scss";

export const SetupParametersForm: React.FC = (): JSX.Element => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<AutomationFormFields>();
  const watchedFormFields = useWatch({ control });

  const [currentMode, setCurrentMode] = useState<"default" | "debug">("default");
  const [productDebugHelperTexts, setProductDebugHelperTexts] = useState<
    Partial<{ parameterIdKey: string; parameterValueKey: string }>
  >({});

  const handleProductFieldsFill = useCallback(() => {
    if (currentMode === "debug") {
      setCurrentMode("default");
      return;
    }

    const { fieldsMap } = watchedFormFields;

    const rootStartPoint = watchedFormFields.startPoint?.isArray
      ? watchedFormFields.startPoint.startKey + "[0]."
      : watchedFormFields.startPoint?.startKey + "";

    const newDebugInfo = { parameterIdKey: "", parameterValueKey: "" };
    const testZeroPoint = rootStartPoint + fieldsMap?.parameters?.startKey + "[0].";

    newDebugInfo.parameterIdKey = _get(
      watchedFormFields.testResponse,
      (testZeroPoint + fieldsMap?.parameters?.parameterIdKey + "") as string,
    );

    newDebugInfo.parameterValueKey = _get(
      watchedFormFields.testResponse,
      (testZeroPoint + fieldsMap?.parameters?.parameterValueKey + "") as string,
    );

    setProductDebugHelperTexts(newDebugInfo);
    setCurrentMode("debug");
  }, [currentMode, watchedFormFields]);

  return (
    <AutomationAction
      title='Настройка параметров'
      buttons={
        <Tooltip title='Проверка подстановки'>
          <IconButton size='small' type='button' onClick={handleProductFieldsFill}>
            <BugReportOutlinedIcon color={currentMode === "default" ? "inherit" : "primary"} />
          </IconButton>
        </Tooltip>
      }
    >
      <div className={cl(styles["fields"])}>
        <TextField
          required
          size='small'
          label='Ключ массива с параметрами'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.parameters.startKey")}
          error={!!errors["fieldsMap"]?.parameters?.startKey}
          helperText={!!errors["fieldsMap"]?.parameters?.startKey?.message}
        />
        <TextField
          required
          size='small'
          label='Ключ ID параметра'
          disabled={currentMode === "debug"}
          placeholder='Отталкивайтесь от объекта, а не от корня'
          {...register("fieldsMap.parameters.parameterIdKey")}
          error={!!errors["fieldsMap"]?.parameters?.parameterIdKey}
          helperText={!!errors["fieldsMap"]?.parameters?.parameterIdKey?.message}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.parameters?.parameterIdKey ?? ""
              : productDebugHelperTexts.parameterIdKey ?? ""
          }
        />
        <TextField
          required
          size='small'
          label='Ключ значения параметра'
          disabled={currentMode === "debug"}
          placeholder='Отталкивайтесь от объекта, а не от корня'
          {...register("fieldsMap.parameters.parameterValueKey")}
          error={!!errors["fieldsMap"]?.parameters?.parameterValueKey}
          helperText={!!errors["fieldsMap"]?.parameters?.parameterValueKey?.message}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.parameters?.parameterValueKey ?? ""
              : productDebugHelperTexts.parameterValueKey ?? ""
          }
        />
      </div>
    </AutomationAction>
  );
};
