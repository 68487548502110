import { SubmitHandler } from "react-hook-form";
import * as Yup from "yup";

export interface CategoryFormProps {
  actionType: "creation" | "editing";
  onSubmit: SubmitHandler<CategoryFormFields>;
  onCancel: () => void;
}

export interface CategoryFormFields {
  title: string;
  description: string;
}

export const categoryValidationSchema = Yup.object()
  .shape({
    title: Yup.string()
      .min(4, "Минимальная длина 4 символа")
      .max(80, "Максимальная длина 80 символов")
      .required("Название категория не может быть пустым полем"),
    description: Yup.string().nullable(),
    image: Yup.mixed().test("fileSize", "Файл должен быть не больше 20 Мб", (value) => {
      if (value && value[0]) return value[0].size <= 20 * 1024 * 1024;
      else return true;
    }),
  })
  .required();
