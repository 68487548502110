import React, { useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Scss
import styles from "./DictionaryValueForm.module.scss";

// Typescript
import { DictionaryValueFormFields, DictionaryValueFormProps } from "./DictionaryValueForm.props";

export const DictionaryValueForm: React.FC<DictionaryValueFormProps> = ({ onSubmit }): JSX.Element => {
  const {
    register,
    formState: { errors, isValid },
    control,
  } = useForm<DictionaryValueFormFields>();
  const values = useWatch({ control });

  const handleFormSubmitClick = useCallback(() => {
    onSubmit(values as DictionaryValueFormFields);
  }, [onSubmit, values]);

  return (
    <div className={cl(styles["form-wrapper"])}>
      <TextField
        type='text'
        label='Значение параметра'
        {...register("id")}
        error={!!errors["id"]}
        helperText={errors["id"]?.message}
      />
      <TextField
        type='text'
        label='Алиас значения параметра'
        {...register("as")}
        error={!!errors["as"]}
        helperText={errors["as"]?.message}
      />
      <Button size='large' type='button' variant='outlined' disabled={!isValid} onClick={handleFormSubmitClick}>
        Добавить значение
      </Button>
    </div>
  );
};
