import React from "react";
import cl from "classnames";
import { NavLink } from "react-router-dom";

// Icons
import { ArrowForwardIosOutlinedIcon } from "assets/icons";

// Typescript
import { BreadCrumbProps } from "./BreadCrumb.props";

// Scss
import styles from "./BreadCrumb.module.scss";

const BreadCrumb: React.FC<BreadCrumbProps> = ({ text, to, isArrowActive }): JSX.Element => {
  return (
    <li className={cl(styles["bread-crumb"])}>
      <NavLink className={cl(styles["bread-crumb__link"])} to={to}>
        <span className={cl(styles["bread-crumb__text"])}>{text}</span>
        {isArrowActive && <ArrowForwardIosOutlinedIcon className={cl(styles["bread-crumb__arrow"])} />}
      </NavLink>
    </li>
  );
};

export default React.memo(BreadCrumb);
