import React, { useCallback } from "react";
import { BaseModalButton } from "components/Modals";
import { usePopupManager } from "react-popup-manager";
import { SnackbarKey, useSnackbar } from "notistack";
import { atom, useSetAtom } from "jotai";

// Services
import { ProductsAPI } from "services/catalogue";

// Images
import {
  AppRegistrationOutlinedIcon,
  ContentCopyOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  DriveFileMoveOutlinedIcon,
  EditOutlinedIcon,
  LoopOutlinedIcon,
} from "assets/icons";
// Popups
import { AnaloguesPopup, CallbackPopup, MoveProductPopup, ParamsForm, ProductFormPopup } from "popups";

// Redux
import { useAppDispatch } from "hooks/redux";
import { fetchProductsRecursively } from "store/actions/CatalogueActions";

// Typescript
import { IProduct } from "types/models";

type ProductActionReturn = (product: IProduct) => BaseModalButton;

export const useProductEditAction = (currentCategoryId: number, currentPage: number): ProductActionReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();

  const onSuccessProductSave = useCallback(async () => {
    await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
    enqueueSnackbar(`Товар успешно обновлен`, { variant: "success" });
  }, [currentCategoryId, currentPage, dispatch, enqueueSnackbar]);

  // Тип: Действие модального окна
  // Слушатель нажатия "переместить" в модальном окне продукта
  const handleProductEdit = useCallback(
    async (product: IProduct) => {
      popupManager.open(ProductFormPopup, {
        actionType: "editing",
        defaultValues: {
          title: product.title,
          description: product.description,
          reference: product.reference,
          producer: product.producer,
          productType: product.productType,
        },
        initialImages: product.images,
        context: { categoryId: Number(currentCategoryId), editProductId: product.id },
        onSuccessServerResponse: onSuccessProductSave,
      });
    },
    [popupManager, currentCategoryId, onSuccessProductSave],
  );

  return (product: IProduct) => {
    return {
      key: "edit",
      text: "Редактировать",
      icon: EditOutlinedIcon,
      onClick: () => handleProductEdit(product),
    };
  };
};

export const useProductParametersEditAction = (): ProductActionReturn => {
  const popupManager = usePopupManager();

  // Тип: Действие модального окна
  // Слушатель нажатия "редактировать параметры" в модальном окне продукта
  const handleProductParamsEdit = useCallback(
    (product: IProduct) => {
      popupManager.open(ParamsForm, {
        categoryId: product.categories.find((i) => i.productCategory.parental)?.id + "",
        product,
      });
    },
    [popupManager],
  );

  return (product: IProduct) => {
    return {
      key: "parameters",
      icon: AppRegistrationOutlinedIcon,
      text: "Изменить параметры",
      onClick: () => handleProductParamsEdit(product),
    };
  };
};

export const useProductAnaloguesViewAction = (): ProductActionReturn => {
  const popupManager = usePopupManager();

  return (product: IProduct) => {
    return {
      key: "analogues",
      text: "Аналоги",
      icon: LoopOutlinedIcon,
      onClick: () => {
        popupManager.open(AnaloguesPopup, { product });
      },
    };
  };
};

export const useProductDuplicateAction = (currentCategoryId: number, currentPage: number): ProductActionReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  // Тип: Действие модального окна
  // Слушатель нажатия "переместить" в модальном окне продукта
  const handleProductDuplicate = useCallback(
    async (product: IProduct) => {
      await ProductsAPI.copy(product.id).then(async () => {
        enqueueSnackbar(`Товар "${product.reference}" продублирован`, { variant: "success" });
        await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
      });
    },
    [enqueueSnackbar, dispatch, currentCategoryId, currentPage],
  );

  return (product: IProduct) => {
    return {
      key: "duplicate",
      text: "Дублировать",
      icon: ContentCopyOutlinedIcon,
      onClick: () => handleProductDuplicate(product),
    };
  };
};

export const useProductMoveAction = (currentCategoryId: number, currentPage: number): ProductActionReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();

  // Тип: Действие модального окна
  // Слушатель нажатия "переместить" в модальном окне продукта
  const handleProductMove = useCallback(
    (product: IProduct) => {
      popupManager.open(MoveProductPopup, {
        product,
        onSuccessMoveResponse: async () => {
          enqueueSnackbar(`Товар "${product.reference}" успешно перемещен в другую категорию`, { variant: "success" });
          await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
        },
        onSuccessReferenceCreateResponse: async () => {
          enqueueSnackbar(`Ярлык для товара "${product.reference}" успешно создан`, { variant: "success" });
        },
      });
    },
    [popupManager, enqueueSnackbar, dispatch, currentCategoryId, currentPage],
  );

  return (product: IProduct) => {
    return {
      key: "move",
      text: "Переместить",
      icon: DriveFileMoveOutlinedIcon,
      onClick: () => handleProductMove(product),
    };
  };
};

export const useProductDeleteAction = (currentCategoryId: number, currentPage: number): ProductActionReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();

  // Тип: Действие модального окна
  // Слушатель нажатия "удалить" в модальном окне продукта
  const handleProductDelete = useCallback(
    async (product: IProduct) => {
      await ProductsAPI.delete(product.id).then(async () => {
        enqueueSnackbar(`Товар "${product.reference}" успешно удален`, { variant: "success" });
        await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
      });
    },
    [currentCategoryId, currentPage, dispatch, enqueueSnackbar],
  );

  // Тип: Действие модального окна
  // Слушатель нажатия "удалить" в модальном окне продукта
  const handleProductReferenceDelete = useCallback(
    async (product: IProduct) => {
      await ProductsAPI.deleteReference(currentCategoryId, product.id).then(async () => {
        enqueueSnackbar(`Ярлык товара "${product.reference}" удален`, { variant: "success" });
        await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
      });
    },
    [currentCategoryId, currentPage, dispatch, enqueueSnackbar],
  );

  return (product: IProduct) => {
    const isRootProduct = product.categories.find((c) => c.id == currentCategoryId)?.productCategory.parental || false;

    return {
      key: "delete",
      icon: DeleteOutlineOutlinedIcon,
      text: "Удалить",
      onClick: () => {
        const popupTitle = isRootProduct
          ? `Вы действительно хотите удалить товар\n"${product.title}"`
          : `Вы действительно хотите удалить ярлык товара\n"${product.title}"`;

        const onSubmitClick = isRootProduct
          ? () => handleProductDelete(product)
          : () => handleProductReferenceDelete(product);

        popupManager.open(CallbackPopup, {
          title: popupTitle,
          submitButtonText: "Удалить",
          cancelButtonText: "Отменить",
          onSubmitClick,
        });
      },
    };
  };
};

export const useManyProductsDeleteAction = (
  currentCategoryId: number,
  currentPage: number,
): ((products: IProduct[]) => () => void) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [messageAtom] = React.useState(() => atom("Идет удаление (0 / 0)"));
  const setMessage = useSetAtom(messageAtom);

  // Тип: Действие модального окна
  // Слушатель нажатия "переместить" в модальном окне продукта
  const handleManyProductDelete = useCallback(
    async (products: IProduct[]) => {
      const notDeletedProducts: IProduct[] = [];
      let snackBarId: SnackbarKey = -1;
      snackBarId = enqueueSnackbar({
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        atomMessage: messageAtom,
        variant: "action",
        persist: true,
      });

      await products.reduce(async (asyncDeletedProducts: Promise<IProduct[]>, product, idx) => {
        const deletedProducts = await asyncDeletedProducts;

        setMessage(`Идет удаление продуктов (${idx + 1} / ${products.length})`);

        await ProductsAPI.delete(product.id, true).catch(() => {
          notDeletedProducts.push(product);
        });

        return [...deletedProducts, product];
      }, Promise.resolve([]));

      setTimeout(() => closeSnackbar(snackBarId), 3000);

      if (notDeletedProducts.length > 0) {
        const message = `Не удалось удалить следующие продукты: ${notDeletedProducts
          .map((p) => `"${p.title}"`)
          .join(", ")}`;

        enqueueSnackbar(message, { variant: "error", persist: true });
      } else {
        enqueueSnackbar(`Все выбранные товары успешно удалены`, { variant: "success" });
      }

      await dispatch(fetchProductsRecursively(currentCategoryId, currentPage, 50));
    },
    [closeSnackbar, currentCategoryId, currentPage, dispatch, enqueueSnackbar, messageAtom, setMessage],
  );

  return (products: IProduct[]) => () => {
    handleManyProductDelete(products);
  };
};
