/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

// Redux
import { store } from "index";
import { enqueueSnackbar } from "notistack";
import { authSlice } from "store/reducers/AuthSlice";

// Utils
import { HttpError } from "types/HttpError";

export class GlobalAPI {
  axios: AxiosInstance;

  staticHeaders: any =
    process.env.NODE_ENV === "development"
      ? {
          "x-lang": "RU",
          "x-real-ip": "1234567890",
          "x-client-id": "17",
          "x-client-type": "user",
          "x-client-access": JSON.stringify(["catalogue.*"]),
        }
      : { "x-lang": "RU" };

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_CATALOGUE_BASE_URL,
      headers: this.staticHeaders,
    });

    this._handleError = this._handleError.bind(this);

    this.axios.interceptors.response.use((resp) => resp, this._handleError);
    this.axios.interceptors.request.use(this._extendHeaders);
  }

  async _extendHeaders(config: AxiosRequestConfig): Promise<AxiosRequestConfig<any>> {
    const { authReducer } = store.getState();
    const { authToken } = authReducer;

    if (authToken) {
      config.headers = { ...config.headers, Authorization: "Bearer " + authToken };
    }

    return config;
  }

  _loadToken(): Promise<{ authToken: string }> {
    return new Promise(async (resolve, reject) => {
      if (!Cookies.get("rms_fingerprint")) {
        window.location.href = `${process.env.REACT_APP_AUTH_URL}?referer=${window.location.href}`;
        return;
      }

      return axios
        .get(`${process.env.REACT_APP_PASSPORT_BASE_URL}/sessions/users/actions/refresh-tokens`, {
          headers: { "x-user-fingerprint": Cookies.get("rms_fingerprint"), ...this.staticHeaders },
          withCredentials: true,
        })
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response?.status === 401 && process.env.NODE_ENV !== "development") {
            window.location.href = `${process.env.REACT_APP_AUTH_URL}?referer=${window.location.href}`;
          }

          reject(error);
        });
    });
  }

  async _handleError(error: AxiosError): Promise<ErrorConstructor | void> {
    if (error.response?.status === 401) {
      const newToken = await this._loadToken();

      if (error.config && newToken.authToken) {
        store.dispatch(authSlice.actions.setAuthToken(newToken.authToken));

        error.config.headers = {
          ...error.config.headers,
          ...this.staticHeaders,
          Authorization: "Bearer " + newToken.authToken,
        };

        error.config.baseURL = undefined;
        return this.axios.request(error.config);
      }
    }

    if (error.response) {
      enqueueSnackbar(error.response.data.message ?? "Не удалось выполнить запрос", { variant: "error" });
      throw new HttpError(error.response.data.status, error.response.data.message);
    }

    throw new HttpError();
  }
}
