import React, { useEffect, useMemo, useState } from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// Services
import CategoriesAPI from "services/catalogue/CategoriesAPI";
import ProductsAPI from "services/catalogue/ProductsAPI";

// Layouts
import { PopupLayout } from "layouts";
// Elements
import { Loader, MoveCategoryRow } from "components/Elements";

// Typescript
import { MoveProductPopupProps } from "./MoveProductPopup.props";
import { ICategory } from "types/models";

// Scss
import styles from "./MoveProductPopup.module.scss";

export const MoveProductPopup: React.FC<MoveProductPopupProps> = ({
  product,
  onSuccessMoveResponse,
  onSuccessReferenceCreateResponse,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const [flags, setFlags] = useState({ isPending: true, isProductMoving: false });

  // Массив начальных категорий
  const [initialCategories, setInitialCategories] = useState<ICategory[]>([]);
  // Идентификатор выбранной пользователем категории
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);

  // Слушатель изменения родительской категории продукта
  const handleProductCategoryChange = async () => {
    setFlags((state) => ({ ...state, isProductMoving: true }));

    await ProductsAPI.update(product.id, { categoryId: Number(selectedCategoryId) }, false)
      .then(async () => {
        await onSuccessMoveResponse();
        onClose();
      })
      .finally(() => setFlags((state) => ({ ...state, isProductMoving: false })));
  };

  // Слушатель создания ярлыка товара в другой категории
  const handleProductReferenceCreate = async () => {
    setFlags((state) => ({ ...state, isProductMoving: true }));

    await ProductsAPI.createReference(selectedCategoryId, product.id)
      .then(async () => {
        await onSuccessReferenceCreateResponse();
        onClose();
      })
      .finally(() => setFlags((state) => ({ ...state, isProductMoving: false })));
  };

  useEffect(() => {
    setFlags((state) => ({ ...state, isPending: true }));

    CategoriesAPI.findChildren(1, { page: 1, amount: 100 }).then(({ rows }) => {
      setInitialCategories(rows);
      setTimeout(() => setFlags((state) => ({ ...state, isPending: false })), 200);
    });
  }, []);

  // Слушатель выбора категории из списка
  const handleCategorySelect = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
  };

  const CategoriesList = useMemo(
    () =>
      initialCategories.map((category) => (
        <MoveCategoryRow
          key={category.id}
          category={category}
          selectedCategoryId={selectedCategoryId}
          onSelect={handleCategorySelect}
        />
      )),
    [initialCategories, selectedCategoryId],
  );

  return (
    <PopupLayout onClose={onClose} title='Выберите папку для перемещения' {...props}>
      <div className={cl(styles["popup__content"])}>
        <Loader isActive={flags.isPending} />

        <div className={cl(styles["popup__list-wrapper"])}>
          <ul className={cl(styles["popup__list"])}>{CategoriesList}</ul>
        </div>

        <div className={cl(styles["popup__buttons"])}>
          <LoadingButton
            fullWidth
            disabled={flags.isProductMoving}
            loading={flags.isProductMoving}
            onClick={handleProductCategoryChange}
            variant='contained'
            type='button'
          >
            Переместить
          </LoadingButton>
          <LoadingButton
            fullWidth
            disabled={flags.isProductMoving}
            loading={flags.isProductMoving}
            onClick={handleProductReferenceCreate}
            variant='outlined'
            type='button'
          >
            Создать ярлык
          </LoadingButton>
          <Button fullWidth variant='outlined' type='button' onClick={onClose}>
            Отменить
          </Button>
        </div>
      </div>
    </PopupLayout>
  );
};
