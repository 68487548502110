import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser } from "types/models/User";

// 1. Описываем стейт в интерфейсе
interface AuthState {
  authToken: string | null;
  isAuthorized: null | boolean;
  user: IUser | null;
  acl: string[];
}

// 2. Подюклчаем и устанавливаем начальные значения в стейт
const initialState: AuthState = {
  authToken: "",
  isAuthorized: null,
  user: null,
  acl: [],
};

// 3. Описываем функции для слайса (редьюсеры)
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthToken(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.isAuthorized = true;
    },
    setUserACL(state, action: PayloadAction<string[]>) {
      state.acl = action.payload;
    },
    handleLoginError(state) {
      state.isAuthorized = false;
      state.user = null;
      state.authToken = "";
      state.acl = [];
    },
  },
});

// 4. Экспортируем и подключаем импортом там, где необходимо вызвать
export default authSlice.reducer;
