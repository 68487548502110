import React from "react";
import cl from "classnames";

// Images
import { DeleteOutlineOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ProductImage.module.scss";

// Typescript
import { ProductImageProps } from "./ProductImage.props";

const ProductImage: React.FC<ProductImageProps> = ({ name, src, selected, onDeleteClick }): JSX.Element => {
  return (
    <li className={cl(styles["product-image"], { [styles["product-image_selected"]]: selected })}>
      <div className={cl(styles["product-image__image-box"])}>
        <div onClick={onDeleteClick} className={cl(styles["product-image__dots-box"])}>
          <DeleteOutlineOutlinedIcon className={cl(styles["product-image__dots"])} />
        </div>
        <div className={cl(styles["product-image__white-layer"])}></div>
        <img className={cl(styles["product-image__image"])} src={src} alt='' />
      </div>
      <span title={name} className={cl(styles["product-image__name"])}>
        {name}
      </span>
    </li>
  );
};

export default React.memo(ProductImage);
