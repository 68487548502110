import React, { useMemo } from "react";

// Layouts
import { FiltersGroupLayout } from "layouts";
// Components
import { CheckboxFilterParameter, RangeFilterParameter } from "components/Fields";

// Typescript
import { FiltersGroupProps } from "./FiltersGroup.props";

export const FiltersGroup: React.FC<FiltersGroupProps> = ({ params, onFilterClear, ...props }): JSX.Element => {
  const ParamsComponents = useMemo(
    () =>
      params.map((param) => {
        if ((param.type === "INT" || param.type === "FLOAT") && param.productsValues.length >= 3) {
          return (
            <RangeFilterParameter
              key={param.id}
              type={param.type}
              title={param.translation.title}
              identifier={param.identifier}
              values={param.productsValues}
              onClear={onFilterClear(param.identifier)}
            />
          );
        }

        let aliasMap = param.allowedValues
          ? param.allowedValues.reduce((prev, { id, as }) => ({ ...prev, [id]: as }), {})
          : undefined;

        if (param.type === "BOOLEAN") {
          aliasMap = { "true": "Да", "false": "Нет" };
        }

        return (
          <CheckboxFilterParameter
            key={param.id}
            type={param.type}
            title={param.translation.title}
            aliasMap={aliasMap}
            identifier={param.identifier}
            values={param.productsValues}
            onClear={onFilterClear(param.identifier)}
          />
        );
      }),
    [onFilterClear, params],
  );

  return <FiltersGroupLayout {...props}>{ParamsComponents}</FiltersGroupLayout>;
};
