import { Dispatch } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Redux
import { catalogueSlice } from "store/reducers/CatalogueSlice";

// Services
import { CategoriesAPI, ProductsAPI } from "services/catalogue";

const _analyzeQueryFilters = (): Record<string, unknown> => {
  let searchQueryParams: Record<string, unknown> = {};

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((rawValue, key) => {
    const value = rawValue.split(",");

    if (key.startsWith("gf_")) {
      const newKey = key.replace("gf_", "");
      const searchValue = value.join(";");
      searchQueryParams = { ...searchQueryParams, [`${newKey}[in]`]: searchValue };
    }

    // f_arr_PARAM-IDENTIFIER
    if (key.startsWith("f_")) {
      const newKey = key.replace("f_", "filter_");
      const [, filterType, ...otherParts] = newKey.split("_");
      const parameterIdentifier = otherParts.join("_");

      const searchValue = value.join(";");
      searchQueryParams = { ...searchQueryParams, [`filter_${parameterIdentifier}[${filterType}]`]: searchValue };
    }
  });

  return searchQueryParams;
};

// Получение и обновление информации о текущей категории и обновление ее в redux state
export const fetchCurrentCategory =
  (categoryId: number | string, independentLoading = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (independentLoading) {
      dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: true }));
    }

    // Дожидаемся ответа от сервера
    const response = await CategoriesAPI.findOne(categoryId);
    dispatch(catalogueSlice.actions.changeCurrentCategory(response));

    if (independentLoading) {
      setTimeout(() => {
        dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: false }));
      }, 200);
    }
  };

// Получение и обновление информации о дочерних категориях по id дочерней
export const fetchParentCategories =
  (categoryId: number | string, independentLoading = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (independentLoading) {
      dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: true }));
    }

    // Дожидаемся ответа от сервера
    const response = await CategoriesAPI.findParents(categoryId);
    dispatch(catalogueSlice.actions.changeParentCategories(response));

    if (independentLoading) {
      setTimeout(() => {
        dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: false }));
      }, 200);
    }
  };

export const fetchChildrenCategories =
  (currentCategoryId: number | string, page: number, amount = 50, resetPreviousState = false) =>
  async (dispatch: Dispatch): Promise<{ currentPage: number; maxPage: number; count: number }> => {
    dispatch(catalogueSlice.actions.setFlags({ isCategoriesLoading: true }));

    const response = await CategoriesAPI.findChildren(currentCategoryId, { page, amount });

    if (resetPreviousState) dispatch(catalogueSlice.actions.setChildrenCategoriesState(response.rows));

    dispatch(
      catalogueSlice.actions.setCategoriesPaginationState({ currentPage: page, maxPage: response.count / amount }),
    );

    setTimeout(() => {
      dispatch(catalogueSlice.actions.setFlags({ isCategoriesLoading: false }));
    }, 200);

    return { currentPage: page, maxPage: response.count / amount, count: response.count };
  };

export const fetchProducts =
  (currentCategoryId: number | string, page: number, amount = 50, savePrevState = false) =>
  async (dispatch: Dispatch): Promise<{ currentPage: number; maxPage: number; count: number }> => {
    dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: true }));

    const searchQueryParams = _analyzeQueryFilters();

    const response = await ProductsAPI.findAll(currentCategoryId, {
      scope: ["withImages", "withProducer", "withProductType"],
      page,
      amount,
      ...searchQueryParams,
    });

    if (savePrevState) dispatch(catalogueSlice.actions.expandProductsState(response.rows));
    else dispatch(catalogueSlice.actions.setProductsState(response.rows));

    const maxPage = response.count / amount;
    dispatch(catalogueSlice.actions.setProductsPaginationState({ currentPage: page, maxPage }));

    setTimeout(() => {
      dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: false }));
    }, 200);

    return { currentPage: page, maxPage, count: response.count };
  };

export const fetchProductsRecursively =
  (currentCategoryId: number | string, page: number, amount = 50) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: true }));

    const searchQueryParams = _analyzeQueryFilters();
    const totalCount = 0;

    const loadMockArray = Array(page).fill(0);
    const products = await loadMockArray.reduce(async (asyncPrev, _, idx) => {
      const prev = await asyncPrev;

      const scope = ["withImages", "withProducer", "withProductType"];
      const options = { page: idx + 1, amount, scope, ...searchQueryParams };
      const response = await ProductsAPI.findAll(currentCategoryId, options);

      return [...prev, ...response.rows];
    }, Promise.resolve([]));

    dispatch(catalogueSlice.actions.setProductsState(products));
    dispatch(catalogueSlice.actions.setProductsPaginationState({ currentPage: page, maxPage: totalCount / amount }));

    setTimeout(() => {
      dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: false }));
    }, 200);
  };

// Получение и обновление информации о дочерних категориях по id категории
export const fetchCategoryData =
  (categoryId: number | string) =>
  async (dispatch: Dispatch): Promise<void> => {
    // Начинаем загрузку
    dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: true }));

    // Дожидаемся ответа от сервера
    await Promise.all([
      fetchCurrentCategory(categoryId)(dispatch),
      fetchParentCategories(categoryId)(dispatch),
      fetchChildrenCategories(categoryId, 1, 50, true)(dispatch),
      fetchProducts(categoryId, 1, 50, true)(dispatch),
    ]);

    // Немного крутим значок загрузки и обновляем стейт
    setTimeout(() => {
      dispatch(catalogueSlice.actions.setFlags({ isCurrentCategoryLoading: false }));
    }, 300);
  };

export const changeElementsPreview =
  (state: "rows" | "cards") =>
  (dispatch: Dispatch): void => {
    dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: true, isCategoriesLoading: true }));

    setTimeout(() => {
      Cookies.set("rms-catalogue_elements-preview", state, { sameSite: "strict", secure: false, expires: 365 });
      dispatch(catalogueSlice.actions.changeElementsPreview(state));
    }, 100);

    setTimeout(() => {
      dispatch(catalogueSlice.actions.setFlags({ isProductsLoading: false, isCategoriesLoading: false }));
    }, 500);
  };

export const resetPreviousCategoryState =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(catalogueSlice.actions.setProductsState([]));
    dispatch(catalogueSlice.actions.setChildrenCategoriesState([]));
    dispatch(catalogueSlice.actions.changeParentCategories([]));
    dispatch(catalogueSlice.actions.changeCurrentCategory(null));
  };
