import React from "react";
import cl from "classnames";
import { useAtomValue } from "jotai";

// Material UI
import Alert from "@mui/material/Alert";

// Images
import { AutorenewOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ActionStatusBox.module.scss";

// Typescript
import { ActionStatusBoxProps } from "./ActionStatusBox.props";

const ActionStatusBox: React.ForwardRefRenderFunction<HTMLDivElement, ActionStatusBoxProps> = (
  { atomMessage },
  ref,
): JSX.Element => {
  const message = useAtomValue(atomMessage);

  return (
    <Alert
      ref={ref}
      variant='filled'
      color='info'
      icon={<AutorenewOutlinedIcon className={cl(styles["icon"])} />}
      sx={{ backgroundColor: "#A4AAB5" }}
    >
      {message}
    </Alert>
  );
};

export default React.forwardRef(ActionStatusBox);
