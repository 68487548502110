import React from "react";
import { useParams } from "react-router-dom";

// Components
import { NotFound } from "components/Elements";
// Blocks
import { CategoriesList, ProductsList } from "blocks";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchChildrenCategories, fetchProducts } from "store/actions/CatalogueActions";

export const CatalogueContent: React.FC<{
  stickyHeaderRef: React.RefObject<HTMLDivElement>;
}> = ({ stickyHeaderRef }): JSX.Element => {
  // Id категории в URL
  const { categoryId } = useParams();
  const dispatch = useAppDispatch();

  const { categories, products, productsPagination, categoriesPagination } = useAppSelector(
    (state) => state.catalogueReducer,
  );

  // Слушатель загрузки новой страницы категорий
  const handleNewCategoriesPageLoadingRequest = () => {
    if (categoriesPagination.currentPage < categoriesPagination.maxPage) {
      dispatch(fetchChildrenCategories(categoryId + "", categoriesPagination.currentPage + 1, 50));
    }
  };

  // Слушатель загрузки новой страницы продуктов
  const handleNewProductsPageLoadingRequest = () => {
    if (productsPagination.currentPage < productsPagination.maxPage) {
      dispatch(fetchProducts(categoryId + "", productsPagination.currentPage + 1, 50, true));
    }
  };

  return (
    <>
      <CategoriesList
        categories={categories}
        currentCategoryId={categoryId || ""}
        onObservableCardEnterView={handleNewCategoriesPageLoadingRequest}
      />

      <ProductsList
        products={products}
        currentCategoryId={categoryId || ""}
        stickyHeaderPortalRef={stickyHeaderRef}
        onObservableCardEnterView={handleNewProductsPageLoadingRequest}
      />

      {products.length === 0 && categories.length === 0 && <NotFound text='Здесь пока ничего нет' />}
    </>
  );
};
