import * as Yup from "yup";
import { SubmitHandler } from "react-hook-form";

export interface ProductTypeFormProps {
  actionType: "creation" | "editing";
  onSubmit: SubmitHandler<ProductTypeFormFields>;
  onCancel: () => void;
}

export interface ProductTypeFormFields {
  identifier: string;
  title: string;
  description: string;
}

export const productTypeValidationSchema = Yup.object()
  .shape({
    identifier: Yup.string()
      .matches(/^[a-zA-Z-]+/, "Идентификатор может состоять только из букв латинского алфавита или -")
      .required("Поле обязательно для заполнения"),
    title: Yup.string().required("Название товара не может быть пустым полем"),
    description: Yup.string().nullable().optional(),
  })
  .required();
