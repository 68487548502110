import React from "react";
import cl from "classnames";

// Material UI
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

// Material UI
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";

// Images
import { ModalPolygonSVG } from "assets/icons";

// Scss
import styles from "./CallbackModal.module.scss";

// Typescript
import { CallbackModalProps } from "./CallbackModal.props";

const WhiteButton = styled(Button)<ButtonProps>(() => ({
  color: "white",
  border: "2px solid white",
  "&:hover": {
    border: "2px solid white",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  color: "white",
  backgroundColor: "#004F9F",
  border: "2px solid #004F9F",
  "&:hover": {
    backgroundColor: "#007CF9",
    border: "2px solid #007CF9",
  },
}));

export const CallbackModal: React.FC<CallbackModalProps> = ({
  title,
  isOpen,
  successButtonContext,
  cancelButtonContext,
  transformOrigin = { vertical: "bottom", horizontal: "right" },
  onClose,
  ...props
}): JSX.Element => {
  const handleButtonClick =
    (func: (evt: React.MouseEvent<HTMLButtonElement>) => void) => (evt: React.MouseEvent<HTMLButtonElement>) => {
      if (onClose) onClose({}, "backdropClick");

      func(evt);
    };

  return (
    <Popover
      open={isOpen ?? false}
      transformOrigin={transformOrigin}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      slotProps={{ "paper": { sx: { background: "none", overflow: "initial", borderRadius: "8px" } } }}
      TransitionComponent={Fade}
      onClose={onClose}
      {...props}
    >
      <div
        className={cl(
          styles["modal"],
          styles[`modal_vertical-${transformOrigin.vertical}`],
          styles[`modal_horizontal-${transformOrigin.horizontal}`],
        )}
      >
        <div className={cl(styles["modal__content"])}>
          <h3 className={cl(styles["modal__title"])}>{title}</h3>
          <div className={cl(styles["modal__buttons"])}>
            <WhiteButton
              fullWidth
              size='small'
              type='button'
              variant='outlined'
              onClick={handleButtonClick(cancelButtonContext.onClick)}
            >
              {cancelButtonContext.label}
            </WhiteButton>
            <SecondaryButton
              fullWidth
              size='small'
              type='button'
              variant='contained'
              onClick={handleButtonClick(successButtonContext.onClick)}
            >
              {successButtonContext.label}
            </SecondaryButton>
          </div>
        </div>
        <ModalPolygonSVG
          className={cl(
            styles["polygon"],
            styles[`polygon_vertical-${transformOrigin?.vertical ?? "bottom"}`],
            styles[`polygon_horizontal-${transformOrigin?.horizontal ?? "right"}`],
          )}
        />
      </div>
    </Popover>
  );
};
