export enum AutomationAuthorizationTypes {
  "url" = "url",
}

export enum AutomationAuthorizationTypesTranslation {
  "url" = "По параметру в ссылке",
}

export enum AutomationActionTypes {
  "create-product" = "create-product",
}

export interface AutomationFormField {
  value: string;
  fillMode: "auto" | "default";
}

export interface UrlParameter {
  key: string;
  value: string;
}

export interface IAutomation {
  id: number;

  description: string;
  url: string;

  urlParams: UrlParameter[];

  authorizationType: keyof typeof AutomationAuthorizationTypes;
  authorizationFields: {
    authorizationToken: string;
    authorizationKey: string;
  };

  actionType: keyof typeof AutomationActionTypes;

  startPoint: {
    startKey: string;
  };

  fieldsMap: {
    product?: {
      title: AutomationFormField;
      reference: AutomationFormField;
      description: AutomationFormField;
      producer: AutomationFormField;
      productType: AutomationFormField;
    };
  };
}
