import { SubmitHandler } from "react-hook-form";
import * as Yup from "yup";

export interface ProducerFormProps {
  actionType: "creation" | "editing";
  onSubmit: SubmitHandler<ProducerFormFields>;
  onCancel: () => void;
}

export interface ProducerFormFields {
  name: string;
  description: string;
  image: File | null;
}

export const producerValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Название товара не может быть пустым полем"),
    description: Yup.string().nullable().optional(),
  })
  .required();
