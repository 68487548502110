import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

// Layouts
import { FilterParameterLayout } from "layouts";

// Scss
import styles from "./CheckboxFilterParameter.module.scss";

// Typescript
import { CheckboxFilterParameterProps } from "./CheckboxFilterParameter.props";

export const CheckboxFilterParameter: React.FC<CheckboxFilterParameterProps> = ({
  title,
  values,
  identifier,
  aliasMap,
  onClear,
}): JSX.Element => {
  const { setValue, control } = useFormContext();
  const watchedFields = useWatch({ control });

  const handleChangeEvent = useCallback(
    (fieldId: string, value: unknown) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.target.checked) {
        setValue(fieldId, [...(watchedFields[fieldId] ?? []), value]);
        return;
      }

      if (!Array.isArray(watchedFields[fieldId])) {
        return;
      }

      const newValues = watchedFields[fieldId].filter((v: string) => v !== value);
      setValue(fieldId, newValues);
    },
    [setValue, watchedFields],
  );

  const ValuesComponents = useMemo(
    () =>
      values.map((value) => (
        <li key={value + ""} className={cl(styles["filter-parameter__value"])}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                size='small'
                checked={(watchedFields[`f_array_${identifier}`] ?? []).includes(value)}
                onChange={handleChangeEvent(`f_array_${identifier}`, value)}
              />
            }
            label={aliasMap ? aliasMap[value + ""] : value + ""}
          />
        </li>
      )),
    [aliasMap, handleChangeEvent, identifier, values, watchedFields],
  );

  return (
    <FilterParameterLayout title={title} onClear={onClear}>
      <FormGroup>
        <ul className={cl(styles["fields"])}>{ValuesComponents}</ul>
      </FormGroup>
    </FilterParameterLayout>
  );
};
