import React from "react";
import cl from "classnames";

// Material UI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";

// Images
import { ArrowBackIosNewOutlinedIcon, CloseOutlinedIcon } from "assets/icons";

// Scss
import styles from "./PopupLayout.module.scss";

// Typescript
import { PopupLayoutProps } from "./PopupLayout.props";

export const PopupLayout: React.FC<PopupLayoutProps> = ({
  title,
  children,
  isHidden,
  headerButton,
  isOpen = false,
  onClose,
  onBack,
}): JSX.Element => {
  return (
    <Dialog
      id='popup'
      open={isOpen && !isHidden}
      PaperProps={{ sx: { maxWidth: "max-content", overflowY: "initial" } }}
      onClose={onClose}
    >
      <IconButton
        size='large'
        disableRipple
        disableFocusRipple
        sx={{
          position: "absolute",
          right: "-32px",
          top: "-32px",
          color: "white",
          transition: "all 0.2s linear",
          padding: "4px",
          ":hover": { color: "white", opacity: "0.6" },
        }}
        onClick={onClose}
      >
        <CloseOutlinedIcon fontSize='inherit' />
      </IconButton>
      <div className={cl(styles["popup__wrapper"])}>
        <div className={cl(styles["popup__content-box"])}>
          <div className={cl(styles["popup__header"])}>
            {onBack && (
              <Tooltip title='Вернуться назад'>
                <IconButton size='small' type='button' onClick={onBack} sx={{ marginRight: "12px" }}>
                  <ArrowBackIosNewOutlinedIcon color='inherit' />
                </IconButton>
              </Tooltip>
            )}
            {title && <h3 className={cl(styles["popup__title"])}>{title}</h3>}
            {headerButton}
          </div>
          {children}
        </div>
      </div>
    </Dialog>
  );
};
