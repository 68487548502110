import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import cl from "classnames";
import { AnyObject } from "yup/lib/types";
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { isEmpty } from "class-validator";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// Services
import ParamsGroupsAPI from "services/catalogue/ParamsGroupsAPI";
import ProductsAPI from "services/catalogue/ProductsAPI";

// Layouts
import { RightPopup } from "layouts";
// Components
import { Loader } from "components/Elements";
import { GroupFieldset } from "./GroupFieldset/GroupFieldset";

// Scss
import styles from "./ParamsForm.module.scss";

// Typescript
import { ParamsFormProps, generateValidationSchema } from "./ParamsForm.props";
import { IParam, IParamsGroupWithParams, IProductWithParamsValues } from "types/models";

export type SchemaType = OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>;

export const ParamsForm: React.FC<ParamsFormProps> = ({
  isOpen,
  product,
  categoryId,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [flags, setFlags] = useState({ isSubmitting: false, isPending: true });

  const [paramsGroups, setParamsGroups] = useState<IParamsGroupWithParams[]>([]);
  const [mergedParams, setMergedParams] = useState<IParam[]>([]);

  const [validationSchema, setValidationSchema] = useState(Yup.object().required());

  const formContext = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const newSchema = mergedParams.reduce((prev, param) => {
      const parameterSchema = generateValidationSchema(param);

      if (parameterSchema === null) return prev;
      return { ...prev, [param.identifier]: parameterSchema };
    }, {}) as Record<string, Yup.StringSchema | Yup.NumberSchema>;

    setValidationSchema(Yup.object().shape(newSchema).required());
  }, [mergedParams]);

  // Метод для генерации настроек формы
  const loadInitialData = useCallback(async () => {
    setFlags((state) => ({ ...state, isPending: true }));

    // Получаем группы параметров для категории, в которой находится товар
    const primaryCategory = product.categories.find((i) => i.productCategory.parental);
    const scope = ["withParams", "withParentCategoriesGroups"];
    const categoryParamsGroups = await ParamsGroupsAPI.findAllWithParentGroups(primaryCategory?.id + "", scope);

    // Получаем значения параметров для товара (те, что уже были сохранены)
    const dbProduct = (await ProductsAPI.findOne(product.id, ["withParamValues"])) as IProductWithParamsValues;

    // Готовим массив параметров, объединенных со всех группа параметров
    const mergedParams = categoryParamsGroups.map((group) => group.params).flat(1);

    // Сопоставляем уже существующие значения параметров с общим массивом параметров
    const dbValues = mergedParams.reduce((prevResult, param) => {
      const existedValue = dbProduct.paramValues.find((i) => i.param.identifier === param.identifier);

      if (!existedValue) return prevResult;
      return { ...prevResult, [param.identifier]: existedValue.value };
    }, {});

    // Сохраняем промежуточные результаты в стейт
    setMergedParams(mergedParams);
    setParamsGroups(categoryParamsGroups);
    formContext.reset(dbValues);

    setTimeout(() => setFlags((state) => ({ ...state, isPending: false })), 200);
  }, [formContext, product.categories, product.id]);

  useEffect(() => {
    if (categoryId) loadInitialData();
  }, [categoryId, loadInitialData]);

  // Слушатель отправки формы на обновление параметров товара
  const handleFormSubmit: SubmitHandler<Record<string, unknown>> = async (values) => {
    setFlags((state) => ({ ...state, isSubmitting: true }));

    // Подготавливаем объект значений параметров для товара
    // в данном объекте обязательно должны быть как старые параметры, так и новые
    // поскольку база данных стирает все значения при сохранении и записывает новые
    const preparedValues: Record<string, string> = Object.entries(values).reduce((prevValues, [identifier, value]) => {
      // Если при сохранении значение в поле ввода пустое,
      if (isEmpty(value)) {
        // Если значение параметра было в списке первоначальных, то это значит, что пользователь
        // стер старое значение и хочет записать туда пустое (удалить значение параметра)
        if ((formContext.formState.defaultValues as Record<string, unknown>)[identifier] !== undefined) {
          return { ...prevValues, [identifier]: value };
        }

        // Если значение параметра не было в списке первоначальных и оно пустое, то
        return prevValues;
      }

      // сохраняем значение параметра, которое было введено в поле ввода
      return { ...prevValues, [identifier]: value };
    }, {});

    // Если после обработки значений, не было сформировано ни одного изменения, то
    // возвращаем пользователю ошибку
    if (Object.keys(preparedValues).length === 0) {
      setFlags((state) => ({ ...state, isSubmitting: false }));
      onClose();
    }

    // Отправляем запрос на сервер для обновления данных о значениях параметров для товара
    await ProductsAPI.updateValues(product.id, preparedValues)
      .then(async () => {
        enqueueSnackbar(`Новые значения параметров для товара "${product.reference}" сохранены`, {
          variant: "success",
        });

        await loadInitialData();
      })
      .finally(() => setFlags((state) => ({ ...state, isSubmitting: false })));
  };

  const ParamsGroupsElements = useMemo(
    () => paramsGroups.map((group) => <GroupFieldset key={group.id} paramsGroup={group} />),
    [paramsGroups],
  );

  return (
    <RightPopup isOpen={isOpen} onClose={onClose} title='Параметры товара'>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
          <Loader isActive={flags.isPending} />

          {ParamsGroupsElements}

          <div className={cl(styles["form__buttons"])}>
            <LoadingButton
              fullWidth
              type='submit'
              variant='contained'
              loading={flags.isSubmitting}
              disabled={flags.isSubmitting || !formContext.formState.isValid}
            >
              Сохранить
            </LoadingButton>
            <Button fullWidth variant='outlined' onClick={onClose} type='button'>
              Отмена
            </Button>
          </div>
        </form>
      </FormProvider>
    </RightPopup>
  );
};
