import React, { useState } from "react";
import cl from "classnames";

// Material UI
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Icons
import { ArrowDropDownOutlinedIcon } from "assets/icons";

// Scss
import styles from "./AutomationAction.module.scss";

// Typescript
import { AutomationActionProps } from "./AutomationAction.props";

const AutomationAction: React.ForwardRefRenderFunction<HTMLDivElement, AutomationActionProps> = (
  { title, buttons, children },
  ref,
): JSX.Element => {
  const [isExpanded, setExpandStatus] = useState(false);

  const handleExpandStatusToggle = () => {
    setExpandStatus((state) => !state);
  };

  return (
    <div ref={ref} className={cl(styles["action"])}>
      <div className={cl(styles["action__header"])}>
        <h4 className={cl(styles["action__title"])}>{title}</h4>
        <div className={cl(styles["action__header-buttons"])}>
          {buttons}
          <Tooltip title='Подробнее'>
            <IconButton
              size='small'
              type='button'
              onClick={handleExpandStatusToggle}
              className={cl(styles["action__expand-btn"], { [styles["action__expand-btn_active"]]: isExpanded })}
            >
              <ArrowDropDownOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Collapse in={isExpanded} collapsedSize={0} orientation='vertical'>
        {children}
      </Collapse>
    </div>
  );
};

export default React.forwardRef(AutomationAction);
