import React from "react";
import cl from "classnames";

// Images
import { DeleteOutlineOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ProductAnalogueRow.module.scss";

// Typescript
import { ProductAnalogueRowProps } from "./ProductAnalogueRow.props";

const ProductAnalogueRow: React.FC<ProductAnalogueRowProps> = ({ product, onDelete }): JSX.Element => {
  const handleDeleteClick = (evt: React.MouseEvent) => {
    onDelete(product.id, evt);
  };

  return (
    <li className={cl(styles["analogue-row"])}>
      <img className={cl(styles["analogue-row__photo"])} src={product.images[0].url} />
      <div className={cl(styles["analogue-row__content"])}>
        <h3 className={cl(styles["analogue-row__title"])}>{product.title}</h3>
        <span className={cl(styles["analogue-row__reference"])}>{product.reference}</span>
        <span className={cl(styles["analogue-row__producer"])}>{product.producer.name}</span>
      </div>
      <DeleteOutlineOutlinedIcon onClick={handleDeleteClick} className={cl(styles["analogue-row__delete"])} />
    </li>
  );
};

export default React.memo(ProductAnalogueRow);
