import React, { useCallback, useEffect, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";

// Material UI
import Button from "@mui/material/Button";

// Services
import ParamsGroupsAPI from "services/catalogue/ParamsGroupsAPI";

// Images
import { AddOutlinedIcon } from "assets/icons";
// Blocks
import { ParamsGroupsList } from "blocks";
// Layouts
import { RightPopup } from "layouts";
// Components
import { Loader } from "components/Elements";
// Popups
import { CategoryFormPopup, GroupFormPopup, ParamsSelectPopup } from "popups";

// Redux
import { fetchChildrenCategories, fetchCurrentCategory } from "store/actions/CatalogueActions";
import { useAppDispatch, useAppSelector } from "hooks/redux";

// Typescript
import { CategoryViewPopupProps } from "./CategoryViewPopup.props";
import { ICategory, IParam, IParamsGroup, IParamsGroupWithParams } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";

export const CategoryViewPopup: React.FC<CategoryViewPopupProps> = ({
  initialCategory,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();

  const { currentCategory, categoriesPagination } = useAppSelector((state) => state.catalogueReducer);

  const [flags, setFlags] = useState({ isPending: true, isPopupHidden: false });
  const [context, setContext] = useState<{ category: ICategory | null; paramsGroups: IParamsGroupWithParams[] }>({
    category: initialCategory,
    paramsGroups: [],
  });

  useEffect(() => {
    setContext((state) => ({ ...state, category: initialCategory }));
  }, [initialCategory]);

  // Функция для загрузки групп параметров категории вместе с их параметрами
  const loadCategoryGroups = useCallback(async () => {
    setFlags((state) => ({ ...state, isPending: true }));

    const scopes = ["withParams", "withParentCategoriesGroups"];
    const response = await ParamsGroupsAPI.findAllWithParentGroups(context.category?.id + "", scopes);
    setContext((state) => ({ ...state, paramsGroups: response.reverse() }));

    setTimeout(() => setFlags((state) => ({ ...state, isPending: false })), 200);
  }, [context.category]);

  useEffect(() => {
    // Выгружаем данные, при этом только тогда, когда поп-ап отображается
    // Например, может быть ситуация, когда он скрыт, а данные продолжат загружаться
    // Для этого добавляем условие проверки отображения поп-ап окна
    if (!flags.isPopupHidden) loadCategoryGroups();
  }, [flags.isPopupHidden, loadCategoryGroups]);

  // Слушатель нажатия на кнопку "Редактировать"
  // в модальном окне "Группы параметров"
  const onParamsGroupEditClick = (group: IParamsGroup) => {
    // Скрываем текущий поп-ап
    setFlags((state) => ({ ...state, isPopupHidden: true }));

    // Открываем поп-ап просмотра группы параметров
    // При закрытии другого поп-апа, снова показываем текущий
    popupManager.open(GroupFormPopup, {
      action: "editing",
      context: { editGroupId: group.id },
      defaultValues: { title: group.translation.title, description: group.translation.description },
      onSuccessServerResponse: (updatedGroup: IParamsGroup) => {
        setContext((state) => ({ ...state, group: updatedGroup }));
        // Добавляем уведомление об успешном завершении операции
        enqueueSnackbar(`Группа параметров "${updatedGroup.translation.title}" успешно обновлена`, {
          variant: "success",
        });
      },
      onClose: () => setFlags((state) => ({ ...state, isPopupHidden: false })),
    });
  };

  // Колбэк функция обработки успешного создания новой группы параметров
  const onSuccessGroupCreate = (createdGroup: IParamsGroup) => {
    setFlags((state) => ({ ...state, isPopupHidden: false }));
    enqueueSnackbar(`Группа параметров "${createdGroup.translation.title}" успешно создана`, { variant: "success" });
  };

  // Слушатель нажатия на кнопку "Добавить группу"
  const onGroupParamsSetupClick = (group: IParamsGroup) => {
    setFlags((state) => ({ ...state, isPopupHidden: true }));

    popupManager.open(ParamsSelectPopup, {
      context: { paramsGroupId: group.id },
      onFormSubmit: async (selectedParams: IParam[]) => {
        await ParamsGroupsAPI.overrideExistedParams(group.id, {
          parameters: selectedParams.map((p) => p.id),
          override: true,
        }).then(async () => {
          enqueueSnackbar(`Новый набор параметров группы "${group.translation.title}" успешно сохранен`, {
            variant: "success",
          });

          await loadCategoryGroups();
        });
      },
      onClose: () => setFlags((state) => ({ ...state, isPopupHidden: false })),
    });
  };

  // Слушатель нажатия на кнопку "Добавить группу"
  const handleGroupCreateClick = () => {
    if (!context.category) return;

    setFlags((state) => ({ ...state, isPopupHidden: true }));

    popupManager.open(GroupFormPopup, {
      action: "creation",
      context: { categoryId: context.category.id },
      onSuccessServerResponse: onSuccessGroupCreate,
      onClose: () => setFlags((state) => ({ ...state, isPopupHidden: false })),
    });
  };

  // Колбэк функция успешного изменения данных о категории
  const onSuccessCategoryUpdate = (updatedCategory: ICategory) => {
    // Обновляем данные о категории
    setContext((state) => ({ ...state, category: updatedCategory }));
    // Добавляем уведомление об успешном обновлении категории
    enqueueSnackbar(`Категория "${updatedCategory.translation.title}" успешно обновлена`, { variant: "success" });

    // Обновляем категорию в фоне (поп-ап имеет серую область, но за ней видно часть категорий)
    if (currentCategory && currentCategory.id === updatedCategory.id) {
      dispatch(fetchCurrentCategory(updatedCategory.id));
    } else {
      dispatch(
        fetchChildrenCategories(updatedCategory.parentCategoryId || "", categoriesPagination.currentPage, 50, true),
      );
    }
  };

  // Слушатель нажатия на кнопку редактирования категории
  const handleCategoryEditClick = () => {
    if (!context.category) return;

    setFlags((state) => ({ ...state, isPopupHidden: true }));

    popupManager.open(CategoryFormPopup, {
      action: "editing",
      context: { editCategoryId: context.category.id },
      defaultValues: {
        title: context.category.translation.title,
        description: context.category.translation.description,
      },
      onSuccessServerResponse: onSuccessCategoryUpdate,
      onClose: () => setFlags((state) => ({ ...state, isPopupHidden: false })),
    });
  };

  return (
    <RightPopup
      isHidden={flags.isPopupHidden}
      title={context.category?.translation.title ?? "Неизвестно"}
      onEdit={handleCategoryEditClick}
      onClose={onClose}
      {...props}
    >
      <Loader isActive={flags.isPending} />

      <ParamsGroupsList
        groups={context.paramsGroups}
        onListChange={loadCategoryGroups}
        onGroupEditClick={onParamsGroupEditClick}
        onParamsSelectClick={onGroupParamsSetupClick}
      />

      {checkAccessLevel("catalogue.params-groups.admin") && (
        <Button
          type='button'
          variant='text'
          startIcon={<AddOutlinedIcon />}
          sx={{ width: "max-content", margin: "24px 0" }}
          onClick={handleGroupCreateClick}
        >
          Добавить группу
        </Button>
      )}
    </RightPopup>
  );
};
