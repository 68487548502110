import React, { useEffect, useMemo, useState } from "react";
import cl from "classnames";

// Services
import CategoriesAPI from "services/catalogue/CategoriesAPI";

// Images
import { ArrowDropDownOutlinedIcon, FolderIcon } from "assets/icons";

// Scss
import styles from "./MoveCategoryRow.module.scss";

// Typescript
import { MoveCategoryRowProps } from "./MoveCategoryRow.props";
import { ICategory } from "types/models";

const MoveCategoryRow: React.FC<MoveCategoryRowProps> = ({ category, selectedCategoryId, onSelect }): JSX.Element => {
  const [isExpanded, setExpandStatus] = useState(false);
  const [hasNoChildren, setChildrenStatus] = useState(false);
  const [childrenCategories, setChildrenCategories] = useState<ICategory[]>([]);

  const onCategoryExpandClick = () => setExpandStatus((state) => !state);
  const handleCategorySelect = () => onSelect(category.id);

  useEffect(() => {
    if (isExpanded) {
      CategoriesAPI.findChildren(category.id, { page: 1, amount: 100 }).then(({ rows }) => {
        setChildrenCategories(rows);
        setChildrenStatus(rows.length === 0);
      });
    }
  }, [category, isExpanded]);

  const ChildrenCategories = useMemo(
    () =>
      childrenCategories.map((childCategory) => (
        <MoveCategoryRow
          onSelect={onSelect}
          selectedCategoryId={selectedCategoryId}
          key={category.id}
          category={childCategory}
        />
      )),
    [category.id, childrenCategories, onSelect, selectedCategoryId],
  );

  return (
    <li className={cl(styles["category-row"])}>
      <div
        onClick={handleCategorySelect}
        className={cl(styles["category-row__info"], {
          [styles["category-row__info_selected"]]: category.id === selectedCategoryId,
        })}
      >
        <ArrowDropDownOutlinedIcon
          onClick={onCategoryExpandClick}
          className={cl(styles["category-row__tree-arrow"], {
            [styles["category-row__tree-arrow_expanded"]]: isExpanded,
            [styles["category-row__tree-arrow_hidden"]]: hasNoChildren,
          })}
        />
        <FolderIcon className={cl(styles["category-row__category"])} />
        <h2 className={cl(styles["category-row__title"])}>{category.translation.title}</h2>
      </div>

      {isExpanded && childrenCategories.length > 0 && (
        <ul className={cl(styles["category-row__children"])}>{ChildrenCategories}</ul>
      )}
    </li>
  );
};

export default React.memo(MoveCategoryRow);
