import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import cl from "classnames";

// Material UI
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Services
import { ParamsGroupsAPI } from "services/catalogue";

// Images
import { FilterAltOffOutlinedIcon } from "assets/icons";
// Blocks
import { GlobalFilters } from "blocks";
// Components
import { FiltersGroup, Loader } from "components/Elements";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./FiltersSection.module.scss";

// Typescript
import { IParamWithProductsValues, IParamsGroupWithParams } from "types/models";
import { FiltersFormField } from "./FiltersSection.props";

export const FiltersSection: React.FC = (): JSX.Element => {
  const [search, setSearchParams] = useSearchParams();
  const { currentCategory } = useAppSelector((state) => state.catalogueReducer);

  const formContext = useForm<FiltersFormField>();
  const watchedFields = useWatch({ control: formContext.control });

  const [isPending, setPendingStatus] = useState(true);
  const [paramsGroups, setParamsGroups] = useState<IParamsGroupWithParams<IParamWithProductsValues>[]>([]);

  const loadParametersInfo = useCallback(async () => {
    if (!currentCategory) return;

    setPendingStatus(true);

    const scope = ["withParentCategoriesGroups", "withFilters"];
    const paramsGroups = await ParamsGroupsAPI.findAllWithParentGroups<IParamWithProductsValues>(
      currentCategory?.id,
      scope,
    );

    setParamsGroups(paramsGroups.filter((g) => g.params.length !== 0));

    setTimeout(() => setPendingStatus(false), 200);
  }, [currentCategory]);

  useEffect(() => {
    loadParametersInfo();
  }, [loadParametersInfo]);

  // При каждом изменении параметров в форме, подгружаем страницу заново
  useEffect(() => {
    const newSearchParams = Object.entries(watchedFields ?? {}).reduce((prev, [key, value]) => {
      if (Array.isArray(value)) {
        if (value.length === 0) return prev;
        else return { ...prev, [key]: value.join(",") };
      }

      return { ...prev, [key]: value };
    }, {});

    setSearchParams(newSearchParams);
  }, [setSearchParams, watchedFields]);

  // При первой загрузке страницы, подгружаем информацию из фильтров URL
  useEffectOnce(() => {
    let newFilters: Record<string, string[]> = {};

    search.forEach((val, key) => {
      newFilters = { ...newFilters, [key]: val.includes(",") ? val.split(",") : [val] };
    });

    formContext.reset({ ...newFilters });
  });

  const handleFiltersResetClick = () => {
    for (const key in search.keys()) {
      search.delete(key);
    }

    setSearchParams(search);
    formContext.reset({});
  };

  const handleFilterClear = useCallback(
    (filterId: string) => () => {
      const newFields = JSON.parse(JSON.stringify(watchedFields));

      search.forEach((_v, key) => {
        if (key.includes(`_${filterId}`)) {
          search.delete(key);
          delete newFields[key];
        }
      });

      setSearchParams(search);
      formContext.reset(newFields);
    },
    [formContext, search, setSearchParams, watchedFields],
  );

  const FiltersGroupsElements = useMemo(
    () =>
      paramsGroups.map((paramsGroup) => {
        return (
          <FiltersGroup
            key={paramsGroup.id}
            title={paramsGroup.translation.title}
            params={paramsGroup.params}
            onFilterClear={handleFilterClear}
          />
        );
      }),
    [paramsGroups, handleFilterClear],
  );

  return (
    <FormProvider {...formContext}>
      <Loader isActive={isPending} style={{ zIndex: 5 }} />
      <div className={cl(styles["filters-header"])}>
        <h2 className={cl(styles["filters-header__title"])}>Фильтры</h2>
        <Tooltip title='Сбросить фильтры'>
          <IconButton type='button' onClick={handleFiltersResetClick} size='medium' sx={{ marginLeft: "auto" }}>
            <FilterAltOffOutlinedIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </div>
      <ul className={cl(styles["filters"])}>
        {currentCategory && <GlobalFilters categoryId={currentCategory?.id} />}
        {FiltersGroupsElements}
      </ul>
    </FormProvider>
  );
};
