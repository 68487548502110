import React from "react";
import cl from "classnames";

import { ReactComponent as NotFoundSVG } from "assets/images/not-found.svg";

// Typescript
import { NotFoundProps } from "./NotFound.props";

// Scss
import styles from "./NotFound.module.scss";

export const NotFound: React.FC<NotFoundProps> = ({ text }): JSX.Element => {
  return (
    <div className={cl(styles["not-found"])}>
      <span className={cl(styles["not-found__text"])}>{text}</span>
      <NotFoundSVG className={cl(styles["not-found__image"])} />
    </div>
  );
};
