import React, { useState } from "react";
import cl from "classnames";

// Scss
import styles from "./CategorySearchElement.module.scss";

// Typescript
import { CategorySearchElementProps } from "./CategorySearchElement.props";

// Constants
import { Constants } from "utils/Constants";

const CategorySearchElement: React.FC<CategorySearchElementProps> = ({ category, onClick }): JSX.Element => {
  const [isImageLoadError, setImageLoadError] = useState(false);
  const handleImageLoadError = () => setImageLoadError(true);

  return (
    <li className={cl(styles["category"])}>
      <div className={cl(styles["category__link"])} onClick={onClick}>
        <img
          alt='Фотография товара'
          src={!category.image || isImageLoadError ? Constants["LOADING_PHOTO_ERROR"] : category.image}
          className={cl(styles["category__photo"])}
          onError={handleImageLoadError}
        />
        <h2 className={cl(styles["category__name"])}>{category.translation.title}</h2>
      </div>
    </li>
  );
};

export default React.memo(CategorySearchElement);
