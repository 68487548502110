import React, { useCallback, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import cl from "classnames";

// Components
import { ProductImage } from "components/Elements";
// Modals
import { CallbackModal } from "components/Modals";

// Scss
import styles from "./ImagesList.module.scss";

// Typescript
import { ImagesListProps } from "./ImagesList.props";
import { ListImage } from "types/models";

export const ImagesList: React.FC<ImagesListProps> = ({
  images,
  className,
  initialSelectedImages = [],
  onImageDelete,
  ...props
}): JSX.Element => {
  const popupManager = usePopupManager();

  const [selectedImages, setSelectedImages] = useState(initialSelectedImages);

  const handleExistedImageDelete = useCallback(
    (image: ListImage) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setSelectedImages((state) => [...state, image.id]);

      popupManager.open(CallbackModal, {
        key: "delete-image-modal",
        title: "Удалить изображение?",
        anchorEl: evt.target as HTMLElement,
        anchorOrigin: { vertical: "center", horizontal: "left" },
        transformOrigin: { vertical: "center", horizontal: "right" },
        successButtonContext: { label: "Да", onClick: () => onImageDelete(image) },
        cancelButtonContext: { label: "Нет", onClick: () => {} },
        onClose: () => setSelectedImages((state) => state.filter((i) => i !== image.id)),
      });
    },
    [popupManager, onImageDelete],
  );

  const ImagesComponents = useMemo(
    () =>
      images.map((image) => (
        <ProductImage
          key={image.id}
          src={image.url}
          name={image.name}
          selected={selectedImages.includes(image.id)}
          onDeleteClick={handleExistedImageDelete(image)}
        />
      )),
    [images, selectedImages, handleExistedImageDelete],
  );

  return (
    <div className={cl(styles["images"], className)} {...props}>
      <ul className={cl(styles["images__list"])}>{ImagesComponents}</ul>
    </div>
  );
};
