import React, { useCallback, useMemo } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Services
import ParamsGroupsAPI from "services/catalogue/ParamsGroupsAPI";

// Images
import { DeleteOutlineOutlinedIcon, EditOutlinedIcon, FormatListBulletedOutlinedIcon } from "assets/icons";
// Components
import { GroupParamsList } from "components/Elements";
// Modals
import { BaseModal } from "components/Modals";
// Popups
import { CallbackPopup } from "popups";

// Scss
import styles from "./ParamsGroupsList.module.scss";

// Typescript
import { ParamsGroupsListProps } from "./ParamsGroupsList.props";
import { IParam, IParamsGroupWithParams } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";

export const ParamsGroupsList: React.FC<ParamsGroupsListProps> = ({
  groups,
  onListChange,
  onGroupEditClick,
  onParamsSelectClick,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();

  const onParamsGroupDelete = useCallback(
    (group: IParamsGroupWithParams) => {
      ParamsGroupsAPI.delete(group.id).then(async () => {
        enqueueSnackbar(`Группа параметров "${group.translation.title}" удалена`, { variant: "success" });
        onListChange();
      });
    },
    [enqueueSnackbar, onListChange],
  );

  const handleParamsGroupDeleteClick = useCallback(
    (group: IParamsGroupWithParams) => {
      popupManager.open(CallbackPopup, {
        title: `Вы действительно хотите удалить группу параметров\n"${group.translation.title}"?`,
        submitButtonText: "Удалить",
        cancelButtonText: "Отменить",
        style: { zIndex: 102 },
        onSubmitClick: () => onParamsGroupDelete(group),
      });
    },
    [popupManager, onParamsGroupDelete],
  );

  const handleModalOpen = useCallback(
    (paramsGroup: IParamsGroupWithParams) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      const buttons = [];

      if (checkAccessLevel("catalogue.params-groups.editor")) {
        buttons.push({
          key: "1",
          text: "Редактировать",
          icon: EditOutlinedIcon,
          onClick: () => onGroupEditClick(paramsGroup),
        });
      }

      if (checkAccessLevel("catalogue.params-groups.editor")) {
        buttons.push({
          key: "2",
          text: "Настроить параметры",
          icon: FormatListBulletedOutlinedIcon,
          onClick: () => onParamsSelectClick(paramsGroup, paramsGroup.params),
        });
      }

      if (checkAccessLevel("catalogue.params-groups.admin")) {
        buttons.push({
          key: "3",
          text: "Удалить",
          icon: DeleteOutlineOutlinedIcon,
          onClick: () => handleParamsGroupDeleteClick(paramsGroup),
        });
      }

      popupManager.open(BaseModal, { anchorEl: evt.target as HTMLElement, key: "group-modal", buttons });
    },
    [popupManager, onGroupEditClick, onParamsSelectClick, handleParamsGroupDeleteClick],
  );

  const handleParamDelete = useCallback(
    (group: IParamsGroupWithParams) => async (param: IParam) => {
      await ParamsGroupsAPI.deleteRelation(group.id, param.id).then(() => {
        enqueueSnackbar(`Параметр "${param.translation.title}" удален`, { variant: "success" });
        onListChange();
      });
    },
    [enqueueSnackbar, onListChange],
  );

  const ParamsGroupsElements = useMemo(
    () =>
      groups.map((group) => (
        <GroupParamsList
          key={group.id}
          paramsGroup={group}
          params={group.params}
          onParamDelete={handleParamDelete(group)}
          onModalOpen={handleModalOpen(group)}
        />
      )),
    [groups, handleModalOpen, handleParamDelete],
  );

  return (
    <div className={cl(styles["params-groups"])}>
      <h3 className={cl(styles["params-groups__title"])}>Параметры категории</h3>
      <ul className={cl(styles["params-groups__list"])}>{ParamsGroupsElements}</ul>
    </div>
  );
};
