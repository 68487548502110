import React from "react";
import cl from "classnames";

// Scss
import styles from "./FiltersGroupLayout.module.scss";

// TYpescript
import { FiltersGroupLayoutProps } from "./FiltersGroupLayout.props";

export const FiltersGroupLayout: React.FC<FiltersGroupLayoutProps> = ({ title, children }): JSX.Element => {
  return (
    <li className={cl(styles["filters-group"])}>
      {title && <h3 className={cl(styles["filters-group__title"])}>{title}</h3>}
      <ul className={cl(styles["filters-group__params"])}>{children}</ul>
    </li>
  );
};
