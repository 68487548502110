import React, { useCallback, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import cl from "classnames";

// Images
import { ArrowDropDownOutlinedIcon } from "assets/icons";

// Scss
import styles from "./DropdownSelect.module.scss";

// Typescript
import { DropdownSelectProps } from "./DropdownSelect.props";

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  title,
  value,
  values,
  className,
  onChange,
  ...props
}): JSX.Element => {
  const ref = useRef(null);

  const [isListVisible, setListVisibility] = useState(false);

  const toggleList = () => setListVisibility((state) => !state);
  const closeList = () => setListVisibility(false);

  useOnClickOutside(ref, closeList);

  const handleValueChange = useCallback(
    (filter: string) => () => {
      onChange(filter);
    },
    [onChange],
  );

  const DropdownElements = useMemo(
    () =>
      Object.entries(values).map(([key, value]) => {
        return (
          <li key={key} onClick={handleValueChange(key)} className={cl(styles["filter-field__values-list-value"])}>
            {value}
          </li>
        );
      }),
    [handleValueChange, values],
  );

  return (
    <div ref={ref} className={cl(styles["filter-field"], className)} {...props}>
      <span className={cl(styles["filter-field__title"])}>{title}</span>
      <div
        onClick={toggleList}
        className={cl(styles["filter-field__input-wrapper"], {
          [styles["filter-field__input-wrapper_active"]]: isListVisible,
        })}
      >
        <span className={cl(styles["filter-field__value"])}>{values[value]}</span>
        <div className={cl(styles["filter-field__values-list"])}>{DropdownElements}</div>
        <ArrowDropDownOutlinedIcon className={cl(styles["filter-field__icon"])} />
      </div>
    </div>
  );
};
