import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import Slider from "@mui/material/Slider";

// Layouts
import { FilterParameterLayout } from "layouts";

// Scss
import styles from "./RangeFilterParameter.module.scss";

// Typescript
import { RangeFilterParameterProps } from "./RangeFilterParameter.props";

export const RangeFilterParameter: React.FC<RangeFilterParameterProps> = ({
  title,
  values,
  identifier,
  onClear,
}): JSX.Element => {
  const { setValue, control } = useFormContext();
  const watchedFields = useWatch({ control });

  const [numValues, setNumValues] = useState<number[]>([]);

  useEffect(() => {
    const processedValues = values.map((v) => Number(v));
    setNumValues(processedValues);
  }, [values]);

  const handleSliderFilterFieldChange = (filterId: string) => (_: Event, value: number | number[]) => {
    setValue(filterId, value);
  };

  return (
    <FilterParameterLayout title={title} overflow='disabled' onClear={onClear}>
      <div className={cl(styles["slider-filter"])}>
        <Slider
          step={null}
          valueLabelDisplay='auto'
          value={Number(watchedFields[`f_value_${identifier}`] ?? 0)}
          onChange={handleSliderFilterFieldChange(`f_value_${identifier}`)}
          marks={numValues.map((v) => ({ value: v }))}
          min={Math.min(...numValues)}
          max={Math.max(...numValues)}
        />
      </div>
    </FilterParameterLayout>
  );
};
