import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

// Services
import { ProducersAPI } from "services/catalogue";

// Layouts
import { RightPopup } from "layouts";
// Blocks
import { ProducerForm, ProducerFormFields, producerValidationSchema } from "blocks/Forms";

// Typescript
import { ProducerFormPopupProps } from "./ProducerFormPopup.props";

export const ProducerFormPopup: React.FC<ProducerFormPopupProps> = ({
  isOpen,
  context,
  actionType,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm({ mode: "all", resolver: yupResolver(producerValidationSchema), defaultValues });

  const handleProducerCreate: SubmitHandler<ProducerFormFields> = async (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("description", values.description);
    if (values.image) formData.append("image", values.image, v4());

    await ProducersAPI.create(formData).then((producer) => {
      enqueueSnackbar(`Новый производитель "${values.name}" успешно создан`, { variant: "success" });
      onSuccessServerResponse(producer);
      onClose();
    });
  };

  const handleProducerUpdate: SubmitHandler<ProducerFormFields> = async (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("description", values.description);
    if (values.image) formData.append("image", values.image, v4());

    await ProducersAPI.update(context.editProducerId + "", formData).then((producer) => {
      enqueueSnackbar(`Производитель "${values.name}" успешно обновлен`, { variant: "success" });
      onSuccessServerResponse(producer);
      onClose();
    });
  };

  return (
    <RightPopup
      isOpen={isOpen}
      title={actionType === "editing" ? "Редактирование производителя" : "Создание производителя"}
      onClose={onClose}
    >
      <FormProvider {...formContext}>
        <ProducerForm
          actionType={actionType}
          onSubmit={actionType === "editing" ? handleProducerUpdate : handleProducerCreate}
          onCancel={onClose}
        />
      </FormProvider>
    </RightPopup>
  );
};
