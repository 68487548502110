import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// Icons
import { MoreVertOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ParameterElement.module.scss";

// Typescript
import { ParameterElementProps } from "./ParameterElement.props";

const ParameterElement: React.FC<ParameterElementProps> = ({
  title,
  selected,
  buttonProps,
  onMoreButtonClick,
}): JSX.Element => {
  return (
    <li
      className={cl(styles["parameter"], {
        [styles["parameter_selected"]]: selected,
      })}
    >
      <h5 className={cl(styles["parameter__title"])}>{title}</h5>
      <div className={cl(styles["parameter__buttons"])}>
        <Button type='button' variant='text' {...buttonProps} className={cl(styles["parameter__button"])} />
        <IconButton type='button' size='small' onClick={onMoreButtonClick}>
          <MoreVertOutlinedIcon fontSize='inherit' />
        </IconButton>
      </div>
    </li>
  );
};

export default React.memo(ParameterElement);
