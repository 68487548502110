import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

// Services
import { ParamsAPI } from "services/catalogue";

// Layouts
import { RightPopup } from "layouts";
// Blocks
import { ParamForm, ParamFormFields, paramValidationSchema } from "blocks/Forms";

// Typescript
import { ParamFormPopupProps } from "./ParamFormPopup.props";
import { IParam } from "types/models";

export const ParamFormPopup: React.FC<ParamFormPopupProps> = ({
  action,
  context,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm<ParamFormFields>({
    resolver: yupResolver(paramValidationSchema),
    mode: "all",
    defaultValues,
  });

  const handleParamCreate = async (values: ParamFormFields) => {
    // const allowedValues = values.type === "DIRECTORY" ? availableValues : null;
    await ParamsAPI.create({ ...values }).then(async (param: IParam) => {
      enqueueSnackbar(`Параметр "${param.translation.title}" успешно создан`, { variant: "success" });
      onClose();

      return onSuccessServerResponse(param);
    });
  };

  const handleParamUpdate = async (values: ParamFormFields) => {
    // const allowedValues = values.type === "DIRECTORY" ? availableValues : null;
    await ParamsAPI.update(context.editParamId + "", { ...values }).then(async (param: IParam) => {
      enqueueSnackbar(`Параметр "${param.translation.title}" успешно обновлен`, { variant: "success" });
      onClose();

      return onSuccessServerResponse(param);
    });
  };

  return (
    <RightPopup
      title={action === "editing" ? "Редактирование параметра" : "Создание параметра"}
      onClose={onClose}
      {...props}
    >
      <FormProvider {...formContext}>
        <ParamForm
          actionType={action}
          onSubmit={action === "creation" ? handleParamCreate : handleParamUpdate}
          onCancel={onClose}
        />
      </FormProvider>
    </RightPopup>
  );
};
