import React from "react";
import cl from "classnames";

// Scss
import styles from "./Footer.module.scss";

export const Footer: React.FC = (): JSX.Element => {
  return (
    <footer className={cl(styles["footer"])}>
      <div className={cl(styles["footer__content"])}>
        <small className={cl(styles["footer__copyright"])}>&copy; {new Date().getFullYear()} ООО «РМ Солюшн»</small>
      </div>
    </footer>
  );
};
