import React, { useCallback, useEffect, useMemo, useState } from "react";
import cl from "classnames";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";

// Material UI
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";

// Forms
import { DictionaryValueForm, DictionaryValueFormFields } from "blocks/Forms";

// Scss
import styles from "./ParamForm.module.scss";

// Typescript
import { ParamsType, ParamsTypeDropdownValues } from "types/models";
import { ParamFormFields, ParamFormProps } from "./ParamForm.props";

export const ParamForm: React.FC<ParamFormProps> = ({ actionType, onSubmit, onCancel }): JSX.Element => {
  const {
    register,
    setValue,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
  } = useFormContext<ParamFormFields>();
  const { type, isRequired, isFilter, allowedValues } = useWatch({
    defaultValue: { isFilter: false, isRequired: true },
    control,
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const handleFormSubmit: SubmitHandler<ParamFormFields> = async (data, event) => {
    setSubmitting(true);
    await new Promise((resolve) => {
      resolve(onSubmit(data, event));
    }).finally(() => setSubmitting(false));
  };

  useEffect(() => {
    const upType = (type ?? "").toUpperCase() as ParamsType;

    if (upType === "DIRECTORY" || upType === "BOOLEAN" || upType === "COLOR" || upType === "DATE") {
      resetField("prefix");
      resetField("suffix");
    }

    if (upType !== "DIRECTORY") {
      resetField("allowedValues");
    }
  }, [resetField, type]);

  const handleAllowedValueAdd = useCallback(
    (value: DictionaryValueFormFields) => {
      setValue("allowedValues", [...(allowedValues ?? []), value] as DictionaryValueFormFields[]);
    },
    [allowedValues, setValue],
  );

  const handleAllowedValueDelete = useCallback(
    (value: DictionaryValueFormFields) => () => {
      if (allowedValues) {
        const newValues = allowedValues.filter((val) => val.id != value.id);
        setValue("allowedValues", newValues as DictionaryValueFormFields[]);
      }
    },
    [allowedValues, setValue],
  );

  const AllowedValues = useMemo(
    () =>
      (allowedValues ?? []).map((value) => {
        const isDuplicate = (allowedValues ?? []).findIndex(({ id, as }) => value.as == as || value.id == id) !== -1;

        return (
          <Chip
            key={value.id}
            variant='outlined'
            label={`${value.as} (${value.id})`}
            color={isDuplicate ? "error" : "default"}
            onDelete={handleAllowedValueDelete(value as DictionaryValueFormFields)}
          />
        );
      }),
    [allowedValues, handleAllowedValueDelete],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id='create-param' className={cl(styles["form"])}>
      <TextField
        required
        fullWidth
        label='Название'
        type='text'
        placeholder='Введите название параметра'
        {...register("title")}
        error={errors["title"]?.message !== undefined}
        helperText={errors["title"]?.message}
      />
      <TextField
        required
        fullWidth
        label='Идентификатор'
        type='text'
        placeholder='Введите идентификатор параметра'
        disabled={actionType === "editing"}
        {...register("identifier")}
        error={errors["identifier"]?.message !== undefined}
        helperText={errors["identifier"]?.message ?? "Значение может состоять только из букв латинского алфавита или -"}
      />
      <TextField
        select
        required
        label='Тип параметра'
        placeholder='Выберите тип параметра'
        error={!!errors["type"]}
        helperText={errors["type"]?.message}
        {...register("type")}
        value={type?.toUpperCase() ?? null}
      >
        {ParamsTypeDropdownValues.map(({ value, as }) => (
          <MenuItem key={value} value={value}>
            {as}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        rows={4}
        multiline
        type='text'
        label='Описание'
        placeholder='Введите описание параметра'
        {...register("description")}
        error={errors["description"]?.message !== undefined}
        helperText={errors["description"]?.message}
      />

      {type !== "BOOLEAN" && (
        <>
          <TextField
            type='text'
            label='Префикс'
            {...register("prefix")}
            error={errors["prefix"]?.message !== undefined}
            helperText={errors["prefix"]?.message}
          />
          <TextField
            type='text'
            label='Суффикс'
            {...register("suffix")}
            error={errors["suffix"]?.message !== undefined}
            helperText={errors["suffix"]?.message}
          />
        </>
      )}
      <div className={cl(styles["form__flags"])}>
        <FormControlLabel
          control={<Checkbox checked={isRequired} {...register("isRequired")} />}
          label='Сделать параметр обязательным'
        />
        <FormControlLabel
          control={<Checkbox checked={isFilter} {...register("isFilter")} />}
          label='Разрешить фильтрацию по параметру'
        />
      </div>

      {type === "DIRECTORY" && (
        <fieldset className={cl(styles["form__fieldset"])}>
          <h3 className={cl(styles["form__fieldset-title"])}>Настройка значений справочника</h3>
          <DictionaryValueForm onSubmit={handleAllowedValueAdd} />
          {(allowedValues ?? []).length > 0 && <ul className={cl(styles["form__allowed-values"])}>{AllowedValues}</ul>}
        </fieldset>
      )}

      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          loading={isSubmitting}
          disabled={isSubmitting}
          type='submit'
          variant='contained'
          sx={{ width: "100%" }}
        >
          {actionType === "creation" ? "Создать параметр" : "Сохранить"}
        </LoadingButton>
        <Button size='large' onClick={onCancel} type='button' variant='outlined' sx={{ width: "100%" }}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
