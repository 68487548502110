import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { IParam, IParamsGroup, IParamsGroupWithParams } from "types/models";
import { GroupFormFields } from "blocks/Forms";

class ParamsGroupsAPI extends GlobalAPI {
  async create(categoryId: number | string, categoryData: GroupFormFields): Promise<IParamsGroup> {
    const key = enqueueSnackbar("Идет создание группы параметров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/categories/${categoryId}/params-groups`, categoryData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async update(groupId: number | string, categoryData: Partial<GroupFormFields>): Promise<IParamsGroup> {
    const key = enqueueSnackbar("Идет обновление группы параметров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .patch(`/params-groups/${groupId}`, categoryData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async delete(groupId: number): Promise<IParamsGroup> {
    const key = enqueueSnackbar("Идет удаление группы параметров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/params-groups/${groupId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findAllByCategory(categoryId: number): Promise<IParamsGroupWithParams[]> {
    return this.axios.get(`/categories/${categoryId}/params-groups?params=true`).then((res) => res.data);
  }

  async findAllWithParentGroups<T extends IParam = IParam>(
    categoryId: number | string,
    scope: string[] = [],
  ): Promise<IParamsGroupWithParams<T>[]> {
    return this.axios
      .get(`/categories/${categoryId}/params-groups`, { params: { scope: scope.join(",") } })
      .then((res) => res.data);
  }

  async findOne(groupId: number): Promise<IParamsGroup> {
    return this.axios.get(`/params-groups/${groupId}`).then((res) => res.data);
  }

  async overrideExistedParams(
    groupId: number,
    payload: { parameters: number[]; override: boolean },
  ): Promise<IParam[]> {
    return this.axios.put(`/params-groups/${groupId}/params`, payload).then((res) => res.data);
  }

  async deleteRelation(groupId: number, paramId: number): Promise<IParam[]> {
    const key = enqueueSnackbar("Идет удаление связи с параметром", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/params-groups/${groupId}/params/${paramId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new ParamsGroupsAPI();
