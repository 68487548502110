import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

// Services
import { ParamsAPI } from "services/catalogue";

// Icons
import {
  AddOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  EditOutlinedIcon,
  RemoveOutlinedIcon,
  SearchOutlinedIcon,
} from "assets/icons";
// Layouts
import { RightPopup } from "layouts";
// Components
import { Loader, ParameterElement } from "components/Elements";
import { BaseModal, BaseModalButton } from "components/Modals";
// Popups
import { CallbackPopup, ParamFormPopup } from "popups";

// Scss
import styles from "./ParamsSelectPopup.module.scss";

// Typescript
import { ParamsSelectPopupProps } from "./ParamsSelectPopup.props";
import { IParam } from "types/models";

export const ParamsSelectPopup: React.FC<ParamsSelectPopupProps> = ({
  isOpen,
  context,
  onFormSubmit,
  onClose = () => {},
}): JSX.Element => {
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setPendingStatus] = useState(true);
  const [isPopupHidden, setPopupAppearance] = useState(false);
  const [selectedParamId, setSelectedParamId] = useState<null | number>(null);

  const [searchText, setSearchText] = useState("");
  const [selectedParams, setSelectedParams] = useState<IParam[]>([]);
  const [allParams, setAllParams] = useState<IParam[]>([]);

  const loadExistedParams = useCallback(async () => {
    setPendingStatus(true);

    const dbAllParams = await ParamsAPI.getByParamsGroup(context.paramsGroupId);
    setSelectedParams(dbAllParams.rows);

    setTimeout(() => setPendingStatus(false), 200);
  }, [context.paramsGroupId]);

  useEffectOnce(() => {
    loadExistedParams();
  });

  const loadAllParams = useCallback(async () => {
    setPendingStatus(true);

    const dbAllParams = await ParamsAPI.findAll({ page: 1, amount: 100, searchText });
    setAllParams(dbAllParams.rows);

    setTimeout(() => setPendingStatus(false), 200);
  }, [searchText]);

  useEffect(() => {
    loadAllParams();
  }, [loadAllParams]);

  const onParameterSelect = (param: IParam) => () => {
    setSelectedParams((state) => [...state, param]);
  };

  const onParameterRemove = (param: IParam) => () => {
    setSelectedParams((state) => state.filter((p) => p.id !== param.id));
  };

  // Слушатель нажатия на кнопку "Добавить параметр"
  const handleParamCreateClick = () => {
    setPopupAppearance(true);

    popupManager.open(ParamFormPopup, {
      context: {},
      action: "creation",
      onSuccessServerResponse: loadAllParams,
      onClose: () => setPopupAppearance(false),
    });
  };

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(evt.target.value);
  };

  const handleFormSubmitClick = () => {
    onFormSubmit(selectedParams);
  };

  const onAnyParamChangeOrDelete = useCallback(async () => {
    await Promise.all([loadExistedParams(), loadAllParams()]);
  }, [loadAllParams, loadExistedParams]);

  const handleParamEditClick = useCallback(
    (parameter: IParam) => {
      setPopupAppearance(true);

      popupManager.open(ParamFormPopup, {
        action: "editing",
        context: { editParamId: parameter.id },
        defaultValues: {
          title: parameter.translation.title,
          description: parameter.translation.description,
          prefix: parameter.translation.prefix,
          suffix: parameter.translation.suffix,
          allowedValues: parameter.allowedValues,
          type: parameter.type,
          identifier: parameter.identifier,
          isRequired: parameter.isRequired,
          isFilter: parameter.isFilter,
        },
        onSuccessServerResponse: () => onAnyParamChangeOrDelete,
        onClose: () => setPopupAppearance(false),
      });
    },
    [onAnyParamChangeOrDelete, popupManager],
  );

  const handleParamDeleteClick = useCallback(
    (parameter: IParam) => {
      setPopupAppearance(true);

      popupManager.open(CallbackPopup, {
        title: `После того, как вы удалите параметр, он больше не будет отображаться в группах параметров, к которым он был привязан. Также данный параметр нельзя будет восстановить в будущем.\n\nВы уверены, что хотите безвозвратно удалить параметр\n"${parameter.translation.title}"?`,
        submitButtonText: "Удалить",
        cancelButtonText: "Отмена",
        onClose: () => setPopupAppearance(false),
        onSubmitClick: async () => {
          await ParamsAPI.delete(parameter.id).then(async () => {
            enqueueSnackbar(`Параметр "${parameter.translation.title}" успешно удален!`);
            await onAnyParamChangeOrDelete();
          });
        },
      });
    },
    [enqueueSnackbar, onAnyParamChangeOrDelete, popupManager],
  );

  const handleMoreButtonClick = useCallback(
    (param: IParam) => (evt: React.MouseEvent) => {
      evt.stopPropagation();

      const buttons: BaseModalButton[] = [];

      buttons.push({
        key: "1",
        icon: EditOutlinedIcon,
        text: "Редактировать",
        onClick: () => handleParamEditClick(param),
      });

      buttons.push({
        key: "2",
        icon: DeleteOutlineOutlinedIcon,
        text: "Удалить",
        onClick: () => handleParamDeleteClick(param),
      });

      setSelectedParamId(param.id);
      popupManager.open(BaseModal, {
        buttons,
        anchorEl: evt.currentTarget,
        onClose: () => setSelectedParamId(null),
      });
    },
    [popupManager, handleParamEditClick, handleParamDeleteClick],
  );

  const SelectedParametersItems = useMemo(
    () =>
      selectedParams.map((parameter) => (
        <ParameterElement
          key={parameter.id}
          title={parameter.translation.title}
          buttonProps={{
            children: "Исключить из группы",
            startIcon: <RemoveOutlinedIcon />,
            onClick: onParameterRemove(parameter),
          }}
          onMoreButtonClick={handleMoreButtonClick(parameter)}
        />
      )),
    [selectedParams, handleMoreButtonClick],
  );

  const ParametersItems = useMemo(
    () =>
      allParams
        .filter((p) => selectedParams.findIndex((sP) => p.id === sP.id) === -1)
        .map((parameter) => (
          <ParameterElement
            key={parameter.id}
            title={parameter.translation.title}
            selected={selectedParamId === parameter.id}
            buttonProps={{
              children: "Добавить в группу",
              startIcon: <AddOutlinedIcon />,
              onClick: onParameterSelect(parameter),
            }}
            onMoreButtonClick={handleMoreButtonClick(parameter)}
          />
        )),
    [allParams, selectedParams, selectedParamId, handleMoreButtonClick],
  );

  return (
    <RightPopup isOpen={isOpen} isHidden={isPopupHidden} title='Добавление параметров' onClose={onClose}>
      <Loader isActive={isPending} />
      <div className={cl(styles["popup__content"])}>
        <div className={cl(styles["section"])}>
          <p className={cl(styles["section__suggestion"])}>
            В&nbsp;данном разделе необходимо выбрать доступные в&nbsp;библиотеке параметры для&nbsp;товаров
            или&nbsp;создать новый, если&nbsp;нужный параметр не&nbsp;удалось найти.
          </p>
        </div>
        <div className={cl(styles["section"])}>
          <div className={cl(styles["section__header"])}>
            <h3 className={cl(styles["section__title"])}>Выбранные параметры</h3>
          </div>
          {selectedParams.length > 0 ? (
            <ul className={cl(styles["section__params-list"])}>{SelectedParametersItems}</ul>
          ) : (
            <span className={cl(styles["section__empty-list"])}>Пока не выбрано ни одного параметра</span>
          )}
        </div>
        <div className={cl(styles["section"])}>
          <div className={cl(styles["section__header"])}>
            <h3 className={cl(styles["section__title"])}>Все параметры каталога</h3>
            <Button type='button' startIcon={<AddOutlinedIcon />} variant='text' onClick={handleParamCreateClick}>
              Создать новый параметр
            </Button>
          </div>
          <div className={cl(styles["section__search-wrapper"])}>
            <TextField
              fullWidth
              size='small'
              onChange={handleTextFieldChange}
              value={searchText}
              label='Поиск параметров'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <ul className={cl(styles["section__params-list"])}>{ParametersItems}</ul>
        </div>
        <div className={cl(styles["buttons"])}>
          <LoadingButton variant='contained' type='button' onClick={handleFormSubmitClick}>
            Сохранить набор параметров
          </LoadingButton>
          <Button variant='outlined' type='button' onClick={onClose}>
            Отмена
          </Button>
        </div>
      </div>
    </RightPopup>
  );
};
