import * as Yup from "yup";
import { IParam, IProduct } from "types/models";

export interface ParamsFormProps {
  product: IProduct;
  categoryId: number | string;

  onClose?: (...args: unknown[]) => unknown;
  isOpen?: boolean;
}

export const generateValidationSchema = (parameter: IParam): null | Yup.StringSchema | Yup.NumberSchema => {
  let validationOptions = null;

  if (parameter.type === "FLOAT" || parameter.type === "INT") {
    validationOptions = Yup.number().typeError("Значение должно быть числом");
  }

  if (parameter.type === "COLOR" || parameter.type === "STRING" || parameter.type === "DIRECTORY") {
    validationOptions = Yup.string().typeError("Значение должно быть строкой");
  }

  if (validationOptions instanceof Yup.StringSchema && parameter.type === "COLOR") {
    validationOptions = validationOptions.matches(/^#[0-9A-F]{6}$/i, {
      message: "Формат цвета не соответствует HEX",
    });
  }

  if (validationOptions instanceof Yup.NumberSchema && parameter.type === "INT") {
    validationOptions = validationOptions.integer("Число должно быть целым");
  }

  if (validationOptions instanceof Yup.StringSchema && parameter.type === "DIRECTORY") {
    validationOptions = validationOptions.oneOf(
      parameter.allowedValues.map((val) => val.id),
      "Параметр может принимать только разрешенные значения",
    );
  }

  if (validationOptions && parameter.isRequired) {
    validationOptions = validationOptions.required("Значение параметра не может быть пустым");
  }

  if (validationOptions && !parameter.isRequired) {
    validationOptions = validationOptions.optional();
  }

  return validationOptions;
};
