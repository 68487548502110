import React, { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";

// Services
import { ProductsAPI } from "services/catalogue";

// Blocks
import { ProductForm, ProductFormFields, productValidationSchema } from "blocks/Forms";
// Layouts
import { RightPopup } from "layouts";

// Typescript
import { ProductFormPopupProps } from "./ProductFormPopup.props";

export const ProductFormPopup: React.FC<ProductFormPopupProps> = ({
  isOpen,
  context,
  actionType,
  defaultValues,
  initialImages = [],
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm<ProductFormFields>({
    mode: "all",
    resolver: yupResolver(productValidationSchema),
    defaultValues: { imagesFiles: [], ...defaultValues },
  });
  const [isPopupHidden, setPopupAppearance] = useState(false);

  // Слушатель отправки формы при создании товара
  const handleProductCreate: SubmitHandler<ProductFormFields> = async ({
    imagesFiles,
    producer,
    productType,
    ...restValues
  }) => {
    // Подготовка фотографий и значений полей формы для товара
    const formData = new FormData();

    formData.append("producerId", producer?.name + "");
    formData.append("productTypeId", productType?.identifier + "");

    Object.entries(restValues).forEach(([key, value]) => formData.append(key, value));
    imagesFiles.forEach((file) => formData.append("images", file.file, file.file.name));

    // Отправка данных на создание товаре
    await ProductsAPI.create(context?.categoryId + "", formData).then(async (createdProduct) => {
      onClose();
      await onSuccessServerResponse(createdProduct, true);
    });
  };

  // Слушатель отправки формы при сохранении информации о товаре
  const handleProductUpdate: SubmitHandler<ProductFormFields> = async (values) => {
    const { imagesFiles, producer, productType, ...restValues } = values;

    // Подготовка фотографий и значений полей формы для товара
    const formData = new FormData();

    formData.append("producerId", producer?.name + "");
    formData.append("productTypeId", productType?.identifier + "");

    Object.entries(restValues).forEach(([key, value]) => formData.append(key, value));
    imagesFiles.forEach((file) => formData.append("images", file.file, file.file.name));

    // Отправка данных на обновление информации о товаре
    await ProductsAPI.update(context?.editProductId + "", formData, true).then(async (updatedProduct) => {
      onClose();
      onSuccessServerResponse(updatedProduct, true);
    });
  };

  const onSavedImageDelete = async (imageId: string | number) => {
    await ProductsAPI.deleteImage(context?.editProductId + "", imageId).then(async () => {
      enqueueSnackbar("Изображение успешно удалено", { variant: "success" });
      await onSuccessServerResponse(null, false);
    });
  };

  const handlePopupAppearanceChange = (status: boolean) => {
    setPopupAppearance(status);
  };

  return (
    <RightPopup
      isOpen={isOpen}
      isHidden={isPopupHidden}
      title={actionType === "editing" ? "Редактирование товара" : "Создание товара"}
      onClose={onClose}
    >
      <FormProvider {...formContext}>
        <ProductForm
          actionType={actionType}
          initialImages={initialImages.map((img) => ({ id: img.id, name: img.name, url: img.url }))}
          onSubmit={actionType === "editing" ? handleProductUpdate : handleProductCreate}
          changePopupVisibility={handlePopupAppearanceChange}
          onSavedImageDelete={onSavedImageDelete}
          onCancel={onClose}
        />
      </FormProvider>
    </RightPopup>
  );
};
