import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import _isEqual from "lodash/isEqual";

// Services
import ParamsGroupsAPI from "services/catalogue/ParamsGroupsAPI";

// Layouts
import { RightPopup } from "layouts";
// Blocks
import { GroupForm, GroupFormFields, paramsGroupValidationSchema } from "blocks/Forms";

// Typescript
import { GroupFormProps } from "./GroupFormPopup.props";

export const GroupFormPopup: React.FC<GroupFormProps> = ({
  action,
  context,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const formContext = useForm({ mode: "all", defaultValues, resolver: yupResolver(paramsGroupValidationSchema) });

  const handleGroupCreate: SubmitHandler<GroupFormFields> = useCallback(
    async (values: GroupFormFields) => {
      if (context.categoryId) {
        await ParamsGroupsAPI.create(context.categoryId, values).then(async (createdGroup) => {
          onSuccessServerResponse(createdGroup);
          onClose();
        });
      }
    },
    [context.categoryId, onClose, onSuccessServerResponse],
  );

  const handleGroupUpdate: SubmitHandler<GroupFormFields> = useCallback(
    async (values: GroupFormFields) => {
      if (!_isEqual(defaultValues, values) && context.editGroupId) {
        await ParamsGroupsAPI.update(context.editGroupId, values).then(async (updatedGroup) => {
          onSuccessServerResponse(updatedGroup);
        });
      }

      onClose();
    },
    [context.editGroupId, defaultValues, onClose, onSuccessServerResponse],
  );

  return (
    <RightPopup title={action === "editing" ? "Редактирование группы" : "Создание группы"} onClose={onClose} {...props}>
      <FormProvider {...formContext}>
        <GroupForm
          actionType={action}
          onSubmit={action === "creation" ? handleGroupCreate : handleGroupUpdate}
          onCancel={onClose}
        />
      </FormProvider>
    </RightPopup>
  );
};
