import fileDownload from "js-file-download";
import { closeSnackbar, enqueueSnackbar } from "notistack";

// Redux
import { store } from "index";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Types
import { ICategory } from "types/models";
import { MultiResponse } from "types/MultiResponse";
import { CategoryFormFields } from "blocks/Forms";

class CategoriesAPI extends GlobalAPI {
  async create(categoryData: CategoryFormFields & { parentCategoryId?: number }): Promise<ICategory> {
    const key = enqueueSnackbar("Идет создание категории", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post("/categories", categoryData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async update(categoryId: number | string, categoryData: Partial<CategoryFormFields>): Promise<ICategory> {
    const key = enqueueSnackbar("Идет обновление категории", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .patch(`/categories/${categoryId}`, categoryData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async delete(categoryId: number | string): Promise<ICategory> {
    const key = enqueueSnackbar("Идет удаление категории", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/categories/${categoryId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findOne(categoryId: number | string): Promise<ICategory> {
    return this.axios.get(`/categories/${categoryId}`).then((res) => res.data);
  }

  async findAll(params: { page?: number; amount?: number; searchQuery?: string }): Promise<MultiResponse<ICategory>> {
    const { catalogueReducer } = store.getState();
    const { sortingType } = catalogueReducer;

    return this.axios.get(`/categories`, { params: { ...params, sorting: sortingType } }).then((res) => res.data);
  }

  async findChildren(
    categoryId: number | string,
    params: { page?: number; amount?: number; searchQuery?: string },
  ): Promise<MultiResponse<ICategory>> {
    const { catalogueReducer } = store.getState();
    const { sortingType } = catalogueReducer;

    return this.axios
      .get(`/categories/${categoryId}/children`, { params: { ...params, sorting: sortingType } })
      .then((res) => res.data);
  }

  async findParents(categoryId: number | string): Promise<ICategory[]> {
    return this.axios.get(`/categories/${categoryId}/parents`).then((res) => res.data);
  }

  async appendPhoto(categoryId: number | string, imageData: FormData): Promise<ICategory> {
    return this.axios.post(`/categories/${categoryId}/image`, imageData).then((res) => res.data);
  }

  async uploadTemplate(categoryId: number | string, templateData: FormData) {
    const key = enqueueSnackbar("Идет загрузка шаблона", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/categories/${categoryId}/products/upload`, templateData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async downloadTemplate(categoryId: number | string): Promise<void> {
    const key = enqueueSnackbar("Идет генерация шаблона", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .get(`/categories/${categoryId}/products/export`, {
        responseType: "blob",
      })
      .then((res) => {
        const fileName =
          res.headers["x-suggested-filename"] || `Шаблон таблицы параметров для категории ${categoryId}.xlsx`;
        fileDownload(res.data, fileName, res.data.type);
      })
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async deletePhoto(categoryId: number | string): Promise<ICategory> {
    const key = enqueueSnackbar("Идет удаление фотографии", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/categories/${categoryId}/image`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new CategoriesAPI();
