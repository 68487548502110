import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

// Scss
import styles from "./GroupFieldset.module.scss";

// Typescript
import { GroupFieldsetProps } from "./GroupFieldset.props";
import { ParamsLangDecode, ParamsType } from "types/models";

export const GroupFieldset: React.FC<GroupFieldsetProps> = ({ paramsGroup }): JSX.Element => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const values = useWatch({ control });

  const ParamInputs = useMemo(
    () =>
      paramsGroup.params.map((param) => {
        if (param.type === "BOOLEAN") {
          return (
            <FormControlLabel
              label={param.translation.title}
              labelPlacement='start'
              key={param.identifier}
              sx={{ margin: 0 }}
              className={cl(styles["fieldset__checkbox"])}
              control={<Checkbox checked={values[param.identifier]} {...register(param.identifier)} />}
            />
          );
        }

        if (param.type === "DIRECTORY") {
          return (
            <TextField
              select
              required={param.isRequired}
              key={param.identifier}
              label={param.translation.title}
              {...register(param.identifier, { value: undefined })}
              value={values[param.identifier] ?? undefined}
              helperText='Выберите значение из предложенных'
            >
              {(param.allowedValues || []).map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.as}
                </MenuItem>
              ))}
            </TextField>
          );
        }

        return (
          <TextField
            label={param.translation.title}
            key={param.identifier}
            required={param.isRequired}
            {...register(param.identifier, { value: undefined })}
            value={values[param.identifier]}
            InputProps={{
              startAdornment: param.translation.prefix ? (
                <InputAdornment position='start'>{param.translation.prefix}</InputAdornment>
              ) : undefined,
              endAdornment: param.translation.suffix ? (
                <InputAdornment position='end'>{param.translation.suffix}</InputAdornment>
              ) : undefined,
            }}
            error={!!errors[param.identifier]}
            helperText={
              errors[param.identifier]
                ? errors[param.identifier]?.message + ""
                : ParamsLangDecode[param.type.toUpperCase() as ParamsType]
            }
          />
        );
      }),
    [paramsGroup.params, errors, values, register],
  );

  return (
    <fieldset className={cl(styles["fieldset"])}>
      <h4 className={cl(styles["fieldset__title"])}>Группа параметров: &quot;{paramsGroup.translation.title}&quot;</h4>
      <ul className={cl(styles["fieldset__fields"])}>{ParamInputs}</ul>
    </fieldset>
  );
};
