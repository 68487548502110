import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntersectionObserver, useIsomorphicLayoutEffect } from "usehooks-ts";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Services
import CategoriesAPI from "services/catalogue/CategoriesAPI";

// Images
import { DeleteOutlineOutlinedIcon, EditOutlinedIcon } from "assets/icons";
// Components
import { CategoryCard } from "components/Elements";
// Modals
import { BaseModal } from "components/Modals";
// Popups
import { CallbackPopup, CategoryViewPopup } from "popups";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchChildrenCategories } from "store/actions/CatalogueActions";

// Scss
import styles from "./CategoriesList.module.scss";

// Typescript
import { CategoriesListProps } from "./CategoriesList.props";
import { ICategory } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";

export const CategoriesList: React.FC<CategoriesListProps> = ({
  categories,
  currentCategoryId,
  onObservableCardEnterView,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();

  const { viewOrder, elementsPreview } = useAppSelector((state) => state.catalogueReducer);

  // Массив "выбранных"/"активных" категорий (Active UI)
  const [selectedCategories, setSelectedCategories] = useState<(string | number)[]>([]);
  // Референс элемента, к которому будет производиться пролистывании
  const observableCategory = useRef<HTMLLIElement | null>(null);
  const entry = useIntersectionObserver(observableCategory, {});
  const isVisible = !!entry?.isIntersecting;

  // Эффект для автоматической загрузке недостающих данных при пролистывании
  useIsomorphicLayoutEffect(() => {
    if (isVisible) onObservableCardEnterView();
  }, [onObservableCardEnterView]);

  // Слушатель нажатия редактировать в модальном окне
  const handleCategoryEdit = useCallback(
    (category: ICategory) => {
      setSelectedCategories([]);
      popupManager.open(CategoryViewPopup, { initialCategory: category });
    },
    [popupManager],
  );

  // Слушатель нажатия "удалить" категорию в модальном окне
  // Также добавляем статус действия для улучшения UX
  // После ответа сервера всегда помечаем действие как выполненное
  const handleCategoryDelete = useCallback(
    async (category: ICategory) => {
      await CategoriesAPI.delete(category.id).then(async (deletedCategory) => {
        enqueueSnackbar(`Категория "${deletedCategory.translation.title}" успешно удалена`, { variant: "success" });
        await dispatch(fetchChildrenCategories(currentCategoryId, 1, 50));
      });
    },
    [currentCategoryId, dispatch, enqueueSnackbar],
  );

  // Слушатель открытия модального окна при нажатии ПКМ или троеточие
  const handleCategoryModalOpen = useCallback(
    (category: ICategory) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setSelectedCategories([category.id]);

      const buttons = [];

      if (checkAccessLevel("catalogue.categories.editor")) {
        buttons.push({
          key: "1",
          icon: EditOutlinedIcon,
          text: "Редактировать",
          onClick: () => handleCategoryEdit(category),
        });
      }

      if (checkAccessLevel("catalogue.categories.admin")) {
        buttons.push({
          key: "2",
          icon: DeleteOutlineOutlinedIcon,
          text: "Удалить",
          onClick: () =>
            popupManager.open(CallbackPopup, {
              title: `Вы действительно хотите удалить категорию\n"${category.translation.title}"?`,
              submitButtonText: "Удалить",
              cancelButtonText: "Отменить",
              onSubmitClick: () => handleCategoryDelete(category),
            }),
        });
      }

      popupManager.open(BaseModal, {
        anchorEl: evt.target as HTMLElement,
        key: "category-modal",
        buttons,
      });
    },
    [handleCategoryDelete, handleCategoryEdit, popupManager],
  );

  // Сброс подсвеченной категории
  const handleCategoryUnSelect = (categoryId: number | string) => {
    setSelectedCategories((state) => state.filter((c) => c !== categoryId));
  };

  // Рендер карточек категорий
  const CategoriesElements = useMemo(
    () =>
      categories.map((category, index) => {
        const observerRef = index === categories.length - 2 ? observableCategory : null;

        return (
          <CategoryCard
            key={category.id}
            ref={observerRef}
            category={category}
            to={`/categories/${category.id}`}
            isSelected={selectedCategories.includes(category.id)}
            variant={elementsPreview === "cards" ? "card" : "row"}
            onModalOpen={handleCategoryModalOpen(category)}
            onCategoryUnSelect={handleCategoryUnSelect}
          />
        );
      }),
    [categories, selectedCategories, elementsPreview, handleCategoryModalOpen],
  );

  return (
    <ul
      style={{ order: viewOrder === "categories" ? "1" : "2" }}
      className={cl(styles["categories-list"], styles[`categories-list_${elementsPreview}`], {
        [styles["categories-list_hidden"]]: categories.length === 0,
      })}
    >
      {CategoriesElements}
    </ul>
  );
};
