import * as Yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { IProducer, IProductType, ListImage } from "types/models";

export interface ProductFormProps {
  actionType: "creation" | "editing";
  initialImages?: ListImage[];
  onSavedImageDelete: (imageId: number | string) => Promise<void>;
  changePopupVisibility: (status: boolean) => void;
  onSubmit: SubmitHandler<ProductFormFields>;
  onCancel: () => void;
}

export interface ProductFormFields {
  title: string;
  serial: string;
  reference: string;
  description: string;
  producer: IProducer | null;
  productType: IProductType | null;
  imagesFiles: { id: string; file: File }[];
}

export const productValidationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Название товара не может быть пустым полем"),
    producer: Yup.object().required("Производитель не может быть пустым полем"),
    productType: Yup.object().required("Тип продукта не может быть пустым полем"),
    reference: Yup.string().required("Референс не может быть пустым полем"),
    serial: Yup.mixed().optional(),
    description: Yup.string().nullable().optional(),
  })
  .required();
