import { SubmitHandler } from "react-hook-form";
import * as Yup from "yup";

// Forms
import { DictionaryValueFormFields } from "blocks/Forms";
import { ParamsType } from "types/models";

export interface ParamFormProps {
  actionType: "creation" | "editing";
  onSubmit: SubmitHandler<ParamFormFields>;
  onCancel: () => void;
}

export interface ParamFormFields {
  title: string;
  identifier: string;
  description?: string;
  type: ParamsType;
  isFilter: boolean;
  isRequired: boolean;
  prefix?: string;
  suffix?: string;
  allowedValues?: DictionaryValueFormFields[];
}

export const paramValidationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Поле обязательно для заполнения"),
    description: Yup.string().nullable().optional(),
    identifier: Yup.string()
      .trim()
      .min(2, "Значение не может быть короче двух символов")
      .required("Поле обязательно для заполнения")
      .matches(/^[a-zA-Z0-9-]+$/, "Идентификатор может состоять только из букв латинского алфавита или -"),
    type: Yup.string().typeError("Поле обязательно для заполнения").required("Поле обязательно для заполнения"),
    isRequired: Yup.boolean().defined("Поле обязательно для заполнения"),
    isFilter: Yup.boolean().defined("Поле обязательно для заполнения"),
    prefix: Yup.string().max(32, "Максимум 32 символа").nullable().optional(),
    suffix: Yup.string().max(32, "Максимум 32 символа").nullable().optional(),
  })
  .required();
