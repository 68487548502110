import React, { useState } from "react";
import cl from "classnames";
import { SubmitHandler, useFormContext } from "react-hook-form";

// Material UI
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";

// Scss
import styles from "./CategoryForm.module.scss";

// Typescript
import { CategoryFormFields, CategoryFormProps } from "./CategoryForm.props";

export const CategoryForm: React.FC<CategoryFormProps> = ({ actionType, onSubmit, onCancel }): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CategoryFormFields>();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleFormSubmit: SubmitHandler<CategoryFormFields> = async (data, event) => {
    setSubmitting(true);

    await new Promise((resolve) => {
      resolve(onSubmit(data, event));
    }).finally(() => setSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
      <TextField
        required
        type='text'
        label='Название'
        placeholder='Введите название категории'
        {...register("title")}
        error={errors["title"]?.message !== undefined}
        helperText={errors["title"]?.message ?? " "}
      />
      <TextField
        rows={4}
        multiline
        type='text'
        label='Описание'
        placeholder='Введите описание категории'
        {...register("description")}
        error={errors["description"]?.message !== undefined}
        helperText={errors["description"]?.message ?? " "}
      />
      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          loading={isSubmitting}
          disabled={isSubmitting}
          type='submit'
          variant='contained'
          sx={{ width: "100%" }}
        >
          {actionType === "editing" ? "Сохранить" : "Создать"}
        </LoadingButton>
        <Button size='large' onClick={onCancel} type='button' variant='outlined' sx={{ width: "100%" }}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
