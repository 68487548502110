import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Forms
import { AutomationFormFields } from "blocks/Forms";
import { IAutomation } from "types/models";

class CategoriesAPI extends GlobalAPI {
  async executeAutomation(
    categoryId: number,
    payload: AutomationFormFields,
  ): Promise<{ statusCode: number; message: string }> {
    const key = enqueueSnackbar("Идет обработка автоматизации", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/categories/${categoryId}/automation/${payload.actionType}`, payload)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findAll(): Promise<IAutomation[]> {
    return this.axios.get(`/automation`).then((res) => res.data);
  }

  async executeOne<T>(
    categoryId: number,
    automationId: number,
    automationType: string,
    payload: unknown,
  ): Promise<T[]> {
    const key = enqueueSnackbar("Идет обработка автоматизации", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/categories/${categoryId}/automation/${automationId}/${automationType}`, payload)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new CategoriesAPI();
