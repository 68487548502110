import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { mergeRefs } from "react-merge-refs";
import cl from "classnames";

// Material UI
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";

// Services
import CategoriesAPI from "services/catalogue/CategoriesAPI";
import ProductsAPI from "services/catalogue/ProductsAPI";

// Images
import { SearchOutlinedIcon } from "assets/icons";
// Components
import { Loader, CategorySearchElement, ProductSearchElement } from "components/Elements";

// Scss
import styles from "./CatalogueSearch.module.scss";

// Typescript
import { ICategory, IProduct } from "types/models";
import { CatalogueSearchProps } from "./CatalogueSearch.props";

export const CatalogueSearch: React.FC<CatalogueSearchProps> = ({
  className,
  searchOptions,
  onCategoryClick,
  onProductClick,
  ...props
}): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [squareRef, { width }] = useElementSize();

  const [isPending, setLoadingStatus] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [isSuggestionsVisible, setSuggestionsVisibility] = useState(false);

  const [searchedCategories, setSearchedCategories] = useState<ICategory[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);

  const handleInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const target = evt.target;
    setSearchText(target.value);
  };

  const searchByCatalogue = useCallback(
    async (searchQuery: string) => {
      setLoadingStatus(true);

      let categories: ICategory[] = [];
      let products: IProduct[] = [];

      if (searchOptions.categories) {
        const categoriesSearchResponse = await CategoriesAPI.findAll({ page: 1, amount: 100, searchQuery });
        categories = categoriesSearchResponse.rows;
      }

      if (searchOptions.products) {
        const productsSearchResponse = await ProductsAPI.search(searchQuery, {
          excludeIds: searchOptions.excludeProductsIds,
          scope: ["withImages", "withCategory"],
        });

        products = productsSearchResponse.rows;
      }

      setSuggestionsVisibility(true);
      setSearchedCategories(categories);
      setSearchedProducts(products);

      setLoadingStatus(false);
    },
    [searchOptions],
  );

  useEffect(() => {
    if (searchText.length !== 0) {
      searchByCatalogue(searchText);
    } else {
      setSuggestionsVisibility(false);
      setSearchedCategories([]);
      setSearchedProducts([]);
    }
  }, [searchByCatalogue, searchText]);

  const handleInputFocus = () => {
    setSuggestionsVisibility(true);
  };

  const handleClickAway = (evt: MouseEvent | TouchEvent) => {
    const target = evt.target as HTMLElement;
    const closestTarget = target.closest("#search-catalogue-input");

    if (!closestTarget) {
      setSuggestionsVisibility(false);
    }
  };

  const handleCategoryRowClick = useCallback(
    (category: ICategory) => () => {
      setSuggestionsVisibility(false);
      setSearchText("");
      if (onCategoryClick) onCategoryClick(category);
    },
    [onCategoryClick],
  );

  const handleProductRowClick = useCallback(
    (product: IProduct) => () => {
      setSuggestionsVisibility(false);
      setSearchText("");
      if (onProductClick) onProductClick(product);
    },
    [onProductClick],
  );

  const CategoriesRows = useMemo(
    () =>
      searchedCategories.map((category) => (
        <CategorySearchElement key={category.id} category={category} onClick={handleCategoryRowClick(category)} />
      )),
    [handleCategoryRowClick, searchedCategories],
  );

  const ProductsRows = useMemo(
    () =>
      searchedProducts.map((product) => (
        <ProductSearchElement key={product.id} product={product} onClick={handleProductRowClick(product)} />
      )),
    [handleProductRowClick, searchedProducts],
  );

  return (
    <>
      <div
        id='search-catalogue-input'
        ref={mergeRefs([squareRef, ref])}
        className={cl(styles["search"], className)}
        {...props}
      >
        <input
          type='text'
          value={searchText}
          placeholder='Поиск...'
          className={cl(styles["search__input"])}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
        />
        <div className={cl(styles["search__button"])}>
          <SearchOutlinedIcon className={cl(styles["search__button-icon"])} />
          <span className={cl(styles["search__button-text"])}>Найти</span>
        </div>
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          transition
          placement='bottom'
          anchorEl={ref.current}
          open={isSuggestionsVisible}
          sx={{ width: width, zIndex: 20 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={400}>
              <div className={cl(styles["search__results"])}>
                <Loader isActive={isPending} />
                {(searchedCategories.length > 0 || searchedProducts.length > 0) && (
                  <>
                    <ul className={cl(styles["search__categories"])}>{CategoriesRows}</ul>
                    <ul className={cl(styles["search__products"])}>{ProductsRows}</ul>
                  </>
                )}
              </div>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};
