import React, { useMemo } from "react";

// Material UI
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// Typescript
import { ProductCharacteristicsProps } from "./ProductCharacteristics.props";

export const ProductCharacteristics: React.FC<ProductCharacteristicsProps> = ({ values }): JSX.Element => {
  const CharacteristicsList = useMemo(
    () =>
      values.map(({ param, value, paramId }) => {
        let valuesMap: Record<string, string> | undefined = undefined;

        if (param.type === "BOOLEAN") {
          valuesMap = { "true": "Да", "false": "Нет" };
        }

        if (param.type === "DIRECTORY") {
          valuesMap = param.allowedValues.reduce((prev, { id, as }) => {
            return { ...prev, [id]: as };
          }, {});
        }

        return (
          <TableRow key={paramId}>
            <TableCell>{param.identifier}</TableCell>
            <TableCell>{param.translation.title}</TableCell>
            <TableCell>{`${param.translation.prefix ?? ""} ${valuesMap ? valuesMap[value] : value} ${
              param.translation.suffix ?? ""
            }`}</TableCell>
          </TableRow>
        );
      }),
    [values],
  );

  return (
    <TableContainer>
      <Table stickyHeader aria-label='характеристики товара' sx={{ borderCollapse: "collapse" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "30%" }}>Идентификатор</TableCell>
            <TableCell sx={{ width: "40%" }}>Название</TableCell>
            <TableCell sx={{ width: "20%" }}>Значение</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{CharacteristicsList}</TableBody>
      </Table>
    </TableContainer>
  );
};
