import React from "react";
import cl from "classnames";

// Scss
import styles from "./FilterParameterLayout.module.scss";

// Typescript
import { FilterParameterLayoutProps } from "./FilterParameterLayout.props";

export const FilterParameterLayout: React.FC<FilterParameterLayoutProps> = ({
  title,
  children,
  overflow = "auto",
  onClear,
}): JSX.Element => {
  return (
    <li className={cl(styles["filter-parameter"], styles[`filter-parameter_overflow-${overflow}`])}>
      <div className={cl(styles["filter-parameter__header"])}>
        <h4 className={cl(styles["filter-parameter__title"])}>{title}</h4>
        <button className={cl(styles["filter-parameter__reset"])} type='button' onClick={onClear}>
          Очистить
        </button>
      </div>
      <div className={cl(styles["filter-parameter__fields-wrapper"])}>{children}</div>
    </li>
  );
};
