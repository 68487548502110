import React from "react";
import cl from "classnames";

// Material UI
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// Material UI
import IconButton from "@mui/material/IconButton";

// Images
import { CloseOutlinedIcon, ModeEditOutlineOutlinedIcon } from "assets/icons";

// Scss
import styles from "./RightPopup.module.scss";

// Typescript
import { RightPopupProps } from "./RightPopup.props";

export const RightPopup: React.FC<RightPopupProps> = ({
  title,
  isHidden,
  children,
  isOpen = false,
  contentBoxClassName,
  onClose,
  onEdit,
}): JSX.Element => {
  return (
    <Modal open={isOpen && !isHidden} onClose={onClose} sx={{ zIndex: 100, outline: "none" }}>
      <Fade in={isOpen && !isHidden}>
        <div className={cl(styles["popup__content"], contentBoxClassName)}>
          <IconButton
            size='medium'
            sx={{ margin: "0 0 24px auto" }}
            className={cl(styles["popup__close"])}
            onClick={onClose}
          >
            <CloseOutlinedIcon fontSize='inherit' />
          </IconButton>
          <div className={cl(styles["popup__header"])}>
            <h2 className={cl(styles["popup__title"])}>{title}</h2>
            {onEdit && (
              <IconButton
                size='medium'
                onClick={onEdit}
                sx={{ marginLeft: "16px" }}
                className={cl(styles["popup__edit"])}
              >
                <ModeEditOutlineOutlinedIcon fontSize='inherit' />
              </IconButton>
            )}
          </div>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};
