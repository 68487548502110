import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import cl from "classnames";

// Images
import { ReactComponent as LogoSVG } from "assets/images/logo.svg";
// Modals
import { ProfileModal } from "components/Modals";
// Components
import { CatalogueSearch } from "components/Elements";

// Scss
import styles from "./HeaderWrapper.module.scss";

// Redux
import { useAppSelector } from "hooks/redux";

// Typescript
import { ICategory, IProduct } from "types/models";

export const HeaderWrapper: React.FC = (): JSX.Element => {
  const popupAnchorRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [isPopupActive, setPopupActive] = useState(false);

  const { user } = useAppSelector((state) => state.authReducer);

  const openPopup = () => setPopupActive(true);
  const closePopup = () => setPopupActive(false);

  const onSearchCategoryRowClick = (category: ICategory) => {
    navigate(`/categories/${category.id}`);
  };

  const onSearchProductRowClick = (product: IProduct) => {
    const parentalCategoryId = product.categories.find((i) => i.productCategory.parental)?.id;
    navigate(`/categories/${parentalCategoryId}?product=${product.id}`);
  };

  return (
    <header className={cl(styles["header"])}>
      <div className={cl(styles["header__content"])}>
        <a href={process.env.REACT_APP_HOME_URL} target='_self'>
          <LogoSVG className={cl(styles["header__logo"])} />
        </a>
        <CatalogueSearch
          className={cl(styles["header__search-field"])}
          searchOptions={{ categories: true, products: true, excludeProductsIds: [] }}
          onCategoryClick={onSearchCategoryRowClick}
          onProductClick={onSearchProductRowClick}
        />
        <div onClick={openPopup} className={cl(styles["header__profile"])} ref={popupAnchorRef}>
          <img className={cl(styles["header__image"])} src={user?.image || ""} />
          <span className={cl(styles["header__fullName"])}>
            {user?.name} {user?.surname}
          </span>
        </div>
      </div>
      <ProfileModal isOpen={isPopupActive} anchorEl={popupAnchorRef.current} onClose={closePopup} />
    </header>
  );
};
