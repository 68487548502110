import React, { useCallback, useMemo, useState } from "react";
import { SubmitHandler, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import axios from "axios";
import _groupBy from "lodash/groupBy";
import cl from "classnames";
import Handlebars from "handlebars";

// Material UI
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

// Images
import { AddOutlinedIcon, DeleteOutlineOutlinedIcon, RefreshIcon } from "assets/icons";
// Forms
import { AutomationActionsForm } from "blocks/Forms";

// Scss
import styles from "./AutomationSourceForm.module.scss";

// Typescript
import { AutomationFormFields, UrlParameter, AutomationSourceFormProps } from "./AutomationSourceForm.props";

export const AutomationSourceForm: React.FC<AutomationSourceFormProps> = ({ onFormSubmit, onCancel }): JSX.Element => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useFormContext<AutomationFormFields>();
  const watchedFormFields = useWatch({ control });
  const { fields, append, remove } = useFieldArray({ control, name: "urlParams" });

  const [flags, setFlags] = useState({ isTestRequestPending: false, isSubmitting: false });
  const [responsePreview, setResponsePreview] = useState<string>("");

  const handleFormSubmit: SubmitHandler<AutomationFormFields> = async (data, event) => {
    setFlags((state) => ({ ...state, isSubmitting: true }));
    await new Promise((resolve) => {
      resolve(onFormSubmit(data, event));
    }).finally(() => setFlags((state) => ({ ...state, isSubmitting: false })));
  };

  const handleSelectValueChange = (evt: SelectChangeEvent) => {
    setValue(evt.target.name as keyof AutomationFormFields, evt.target.value);
  };

  const handleUrlParameterAppend = () => append({ key: "", value: "" });
  const handleUrlParameterRemove = useCallback((fieldId: number) => () => remove(fieldId), [remove]);

  const executeRequest = useCallback(async () => {
    const urlTemplate = Handlebars.compile(watchedFormFields.url ?? "");
    const groupedUrlParameters = _groupBy(watchedFormFields.urlParams ?? [], (param) => {
      return param.key;
    }) as Record<string, UrlParameter[]>;

    let params: Record<string, string> = {};

    if (watchedFormFields.authorizationFields && watchedFormFields.authorizationType === "url") {
      const authKey = watchedFormFields.authorizationFields.authorizationKey ?? "";
      const authToken = watchedFormFields.authorizationFields.authorizationToken ?? "";

      params = { ...params, [authKey]: authToken };
    }

    params = Object.entries(groupedUrlParameters).reduce((prev, [key, values]) => {
      if (values.length === 0) return prev;
      if (values.length === 1) return { ...prev, [key]: values[0].value };
      return { ...prev, [key]: values.map((v) => v.value).join(",") };
    }, params);

    setFlags((state) => ({ ...state, isTestRequestPending: true }));
    const res = await axios
      .get(urlTemplate(watchedFormFields), { params })
      .then((res) => res.data)
      .finally(() => setFlags((state) => ({ ...state, isTestRequestPending: false })));

    setValue("testResponse", res);
    setResponsePreview(JSON.stringify(res, null, 2));
  }, [setValue, watchedFormFields]);

  const UrlParamsElements = useMemo(
    () =>
      fields.map((field, idx) => {
        return (
          <li key={field.id} className={cl(styles["url-parameter"])}>
            <TextField required fullWidth label='Ключ параметра' {...register(`urlParams.${idx}.key`)} />
            <TextField required fullWidth label='Значение' {...register(`urlParams.${idx}.value`)} />
            <Tooltip title='Удалить параметр'>
              <IconButton type='button' onClick={handleUrlParameterRemove(idx)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </li>
        );
      }),
    [fields, handleUrlParameterRemove, register],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
      <div className={cl(styles["form__request-settings"])}>
        <TextField
          required
          label='Описание автоматизации'
          placeholder='Введите название или описание'
          {...register("description")}
          error={!!errors["description"]}
          helperText={errors["description"]?.message}
        />
        <TextField
          required
          multiline
          minRows={3}
          label='Шаблонная ссылка API'
          placeholder='Используется шаблон Mustache'
          {...register("url")}
          error={!!errors["url"]}
          helperText={errors["url"]?.message}
        />
        <h3 className={cl(styles["form__url-params-title"])}>Настройки авторизации</h3>
        <FormControl fullWidth>
          <InputLabel required id='authorizationType-label'>
            Способ авторизации
          </InputLabel>
          <Select
            required
            label='Способ авторизации'
            labelId='authorizationType-label'
            id='authorizationType'
            name='authorizationType'
            value={watchedFormFields.authorizationType ?? ""}
            onChange={handleSelectValueChange}
            error={!!errors["authorizationType"]}
          >
            <MenuItem value='url'>Параметр ссылки</MenuItem>
          </Select>
          {!!errors["authorizationType"] && <FormHelperText>{errors["authorizationType"]?.message}</FormHelperText>}
        </FormControl>
        <div className={cl(styles["form__authorization"])}>
          <TextField
            required
            label='Имя ключа'
            {...register("authorizationFields.authorizationKey")}
            error={!!errors["authorizationFields"]?.authorizationKey}
            helperText={errors["authorizationFields"]?.authorizationKey?.message}
          />
          <TextField
            required
            label='Токен'
            {...register("authorizationFields.authorizationToken")}
            error={!!errors["authorizationFields"]?.authorizationToken}
            helperText={errors["authorizationFields"]?.authorizationToken?.message}
          />
        </div>
        <h3 className={cl(styles["form__url-params-title"])}>URL параметры запроса</h3>
        {fields.length > 0 && <ul className={cl(styles["form__url-params"])}>{UrlParamsElements}</ul>}
        <Button variant='text' type='button' startIcon={<AddOutlinedIcon />} onClick={handleUrlParameterAppend}>
          Добавить параметр в строку запроса
        </Button>
        <h3 className={cl(styles["form__url-params-title"])}>Настройка действий</h3>
        <FormControl fullWidth>
          <InputLabel required id='actionType-label'>
            Действие автоматизации
          </InputLabel>
          <Select
            required
            label='Действие автоматизации'
            labelId='actionType-label'
            id='actionType'
            name='actionType'
            value={watchedFormFields.actionType ?? ""}
            onChange={handleSelectValueChange}
            error={!!errors["actionType"]}
          >
            <MenuItem value='create-product'>Создание товара</MenuItem>
            <MenuItem value='product-with-parameters'>Настройка товара и его параметров</MenuItem>
          </Select>
          {!!errors["actionType"] && <FormHelperText>{errors["actionType"]?.message}</FormHelperText>}
        </FormControl>
        <AutomationActionsForm />
      </div>
      <div className={cl(styles["form__request-preview"])}>
        <textarea readOnly className={cl(styles["form__request-preview-json"])} value={responsePreview}></textarea>
        <LoadingButton
          fullWidth
          type='button'
          variant='outlined'
          disabled={flags.isTestRequestPending}
          loading={flags.isTestRequestPending}
          startIcon={<RefreshIcon />}
          onClick={executeRequest}
          sx={{ marginTop: "20px" }}
        >
          Выполнить тестовый запрос
        </LoadingButton>
      </div>
      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          fullWidth
          type='submit'
          variant='contained'
          loading={flags.isSubmitting}
          disabled={flags.isSubmitting}
        >
          Запустить автоматизацию
        </LoadingButton>
        <Button size='large' fullWidth type='button' variant='outlined' onClick={onCancel}>
          Отмена
        </Button>
      </div>
    </form>
  );
};
