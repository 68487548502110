import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Services
import { AutomationAPI } from "services/catalogue";

// Layouts
import { PopupLayout } from "layouts";
// Elements
import { Loader } from "components/Elements";
// Images
import { AddOutlinedIcon } from "assets/icons";
// Popups
import { AutomationExecutePopup, AutomationFormPopup } from "popups";

// Scss
import styles from "./AutomationListPopup.module.scss";

// Typescript
import { AutomationListPopupProps } from "./AutomationListPopup.props";
import { AutomationAuthorizationTypesTranslation, IAutomation } from "types/models";

export const AutomationListPopup: React.FC<AutomationListPopupProps> = ({
  isOpen,
  categoryId,
  onClose = () => {},
}): JSX.Element => {
  const popupManager = usePopupManager();

  const [isPending, setPendingStatus] = useState(true);
  const [isPopupHidden, setPopupAppearance] = useState(false);
  const [automationList, setAutomationList] = useState<IAutomation[]>([]);

  const loadAutomationList = async () => {
    setPendingStatus(true);

    const dbAutomationList = await AutomationAPI.findAll();
    setAutomationList(dbAutomationList);

    setTimeout(() => setPendingStatus(false), 200);
  };

  useEffect(() => {
    loadAutomationList();
  }, []);

  const handleAddAutomationClick = () => {
    setPopupAppearance(true);

    popupManager.open(AutomationFormPopup, {
      categoryId,
      onSuccessSubmit: loadAutomationList,
      onClose: () => setPopupAppearance(false),
    });
  };

  const handleAutomationClick = useCallback(
    (automation: IAutomation) => () => {
      setPopupAppearance(true);

      popupManager.open(AutomationExecutePopup, { automation, categoryId, onClose: () => setPopupAppearance(false) });
    },
    [categoryId, popupManager],
  );

  const AutomationElements = useMemo(
    () =>
      automationList.map((automation) => (
        <li key={automation.id} className={cl(styles["automation-card"])} onClick={handleAutomationClick(automation)}>
          <h3 className={cl(styles["automation-card__title"])}>{automation.description}</h3>
          <span className={cl(styles["automation-card__url"])}>Источник данных: {automation.url}</span>
          <span className={cl(styles["automation-card__authorization-type"])}>
            Способ авторизации: {AutomationAuthorizationTypesTranslation[automation.authorizationType]}
          </span>
        </li>
      )),
    [automationList, handleAutomationClick],
  );

  return (
    <PopupLayout
      isOpen={isOpen}
      isHidden={isPopupHidden}
      title='Выбор автоматизации'
      onClose={onClose}
      headerButton={
        <Button
          type='button'
          variant='text'
          startIcon={<AddOutlinedIcon />}
          onClick={handleAddAutomationClick}
          sx={{ marginLeft: "auto" }}
        >
          Добавить автоматизацию
        </Button>
      }
    >
      <Loader isActive={isPending} />
      <ul className={cl(styles["automation-list"])}>{AutomationElements}</ul>
    </PopupLayout>
  );
};
