import React, { useState } from "react";
import cl from "classnames";
import { SubmitHandler, useFormContext } from "react-hook-form";

// Material UI
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

// Scss
import styles from "./ProductTypeForm.module.scss";

// Typescript
import { ProductTypeFormFields, ProductTypeFormProps } from "./ProductTypeForm.props";

export const ProductTypeForm: React.FC<ProductTypeFormProps> = ({ actionType, onSubmit, onCancel }): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ProductTypeFormFields>();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleFormSubmit: SubmitHandler<ProductTypeFormFields> = async (data, event) => {
    setSubmitting(true);

    await new Promise((resolve) => {
      resolve(onSubmit(data, event));
    }).finally(() => setSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
      <TextField
        required
        fullWidth
        type='text'
        label='Название'
        placeholder='Введите название'
        {...register("title")}
        error={errors["title"]?.message !== undefined}
        helperText={errors["title"]?.message}
      />
      <TextField
        required
        fullWidth
        type='text'
        label='Идентификатор'
        placeholder='Введите уникальный идентификатор'
        {...register("identifier")}
        error={errors["identifier"]?.message !== undefined}
        helperText={errors["identifier"]?.message ?? "Значение может состоять только из букв латинского алфавита или -"}
      />
      <TextField
        rows={4}
        fullWidth
        multiline
        type='text'
        label='Описание'
        placeholder='Введите описание'
        {...register("description")}
        error={errors["description"]?.message !== undefined}
        helperText={errors["description"]?.message}
      />

      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          sx={{ width: "100%" }}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {actionType === "creation" ? "Создать" : "Сохранить"}
        </LoadingButton>
        <Button size='large' onClick={onCancel} type='button' variant='outlined' sx={{ width: "100%" }}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
