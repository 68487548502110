// Интерфейс, описывающий минимальные поля параметра из базы
export interface IParam {
  id: number;
  translation: {
    title: string;
    description?: string;
    prefix?: string;
    suffix?: string;
    appLanguageId: string;
  };
  identifier: string;
  type: ParamsType;
  isRequired: boolean;
  isFilter: boolean;
  allowedValues: { id: string; as: string }[];
}

export interface IParamWithProductsValues extends IParam {
  productsValues: unknown[];
}

export interface INewParam {
  title: string;
  identifier: string;
  description: string;
  type: ParamsType;
  isRequired: boolean;
  isFilter: boolean;
}

export const ParamsTypeDropdownValues = [
  { value: "STRING", as: "Строка" },
  { value: "BOOLEAN", as: "Булевый" },
  { value: "DATE", as: "Дата" },
  { value: "INT", as: "Число" },
  { value: "FLOAT", as: "Число с точкой" },
  { value: "DIRECTORY", as: "Справочник" },
  { value: "COLOR", as: "Цвет" },
];

export type ParamsType = "STRING" | "DATE" | "INT" | "FLOAT" | "COLOR" | "BOOLEAN" | "DIRECTORY";

export const ParamsLangDecode = {
  BOOLEAN: "Булевый",
  STRING: "Строка",
  DATE: "Дата",
  INT: "Целое число",
  FLOAT: "Число с точкой",
  COLOR: "Цвет",
  DIRECTORY: "Справочник",
};
