export class HttpError {
  status: number;

  message: string;

  constructor(errorStatus = 500, errorMessage = "Произошла неизвестная ошибка. Обратитесь к администратору.") {
    this.status = errorStatus;
    this.message = errorMessage;
  }
}
