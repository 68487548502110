import React, { useRef, useState } from "react";
import cl from "classnames";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";
import { NavLink } from "react-router-dom";

// Images
import { MoreVertOutlinedIcon } from "assets/icons";
import { ReactComponent as ReferencedSVG } from "assets/images/referenced.svg";
// Components
import { Loader } from "components/Elements";

// Typescript
import { ProductCardProps } from "./ProductElement.props";

// Scss
import styles from "./ProductElement.module.scss";

// Constants
import { Constants } from "utils/Constants";

// Utils
import { checkAccessLevel } from "utils/ACL";

const ProductElement: React.ForwardRefRenderFunction<HTMLDivElement, ProductCardProps> = (
  { to, image, product, preview = "card", isSelected, isReference, onModalOpen, onReferenceIconClick, ...props },
  customRef,
): JSX.Element => {
  const ref = useRef<HTMLLIElement>(null);

  const [isPhotoPending, setPhotoPendingStatus] = useState(true);
  const [isImageLoadError, setImageLoadError] = useState(false);

  const handleImageLoadError = () => {
    setImageLoadError(true);
    setTimeout(() => setPhotoPendingStatus(false), 100);
  };

  const handleProductImageLoadEnd = () => {
    setTimeout(() => setPhotoPendingStatus(false), 100);
  };

  return preview === "card" ? (
    <li
      ref={ref}
      id={product.id + ""}
      className={cl(styles["product-card"], { [styles["product-card_selected"]]: isSelected }, "selectable-product")}
      {...props}
    >
      <div ref={customRef} className={cl(styles["product-card__photo-box"])}>
        <Loader style={{ zIndex: 2 }} isActive={isPhotoPending} />
        <div className={cl(styles["product-card__photo-shadow"])} />

        {checkAccessLevel("catalogue.products.editor") && (
          <MoreVertOutlinedIcon onClick={onModalOpen} className={cl(styles["product-card__dots"], "context-btn")} />
        )}

        {isReference && (
          <ReferencedSVG onClick={onReferenceIconClick} className={cl(styles["product-card__reference-icon"])} />
        )}

        <img
          onLoad={handleProductImageLoadEnd}
          onError={handleImageLoadError}
          src={!image || isImageLoadError ? Constants["LOADING_PHOTO_ERROR"] : image}
          alt='Фотография товара'
          className={cl(styles["product-card__photo"], {
            [styles["product-card__photo_unloaded"]]: !image || isImageLoadError,
          })}
        />
      </div>

      <div className={cl(styles["product-card__info"])}>
        <NavLink to={to} title={product.title} className={cl(styles["product-card__title"])}>
          {product.title}
        </NavLink>
        <small title={product.reference} className={cl(styles["product-card__reference"])}>
          {product.reference}
        </small>
      </div>
    </li>
  ) : (
    <li
      ref={ref}
      id={product.id + ""}
      className={cl(styles["product-row"], { [styles["product-row_selected"]]: isSelected }, "selectable-product")}
      {...props}
    >
      <div ref={customRef} className={cl(styles["product-row__photo-box"])}>
        <div className={cl(styles["product-row__photo-shadow"])} />
        <Loader style={{ zIndex: 2 }} isActive={isPhotoPending} />

        {isReference && (
          <ReferencedSVG onClick={onReferenceIconClick} className={cl(styles["product-row__reference-icon"])} />
        )}

        <img
          onLoad={handleProductImageLoadEnd}
          onError={handleImageLoadError}
          src={!image || isImageLoadError ? Constants["LOADING_PHOTO_ERROR"] : image}
          alt='Фотография товара'
          className={cl(styles["product-row__photo"], {
            [styles["product-row__photo_unloaded"]]: !image || isImageLoadError,
          })}
        />
      </div>
      <div className={cl(styles["product-row__info"])}>
        <NavLink to={to} title={product.title} className={cl(styles["product-row__title"])}>
          {product.title}
        </NavLink>
        <small title={product.reference} className={cl(styles["product-row__reference"])}>
          {product.reference}
        </small>
      </div>

      <div className={cl(styles["product-row__footer"])}>
        <span className={cl(styles["product-row__date"])}>
          {format(new Date(product.createdAt), "d MMMM yyyy", { locale: ru })}
        </span>

        {checkAccessLevel("catalogue.products.editor") && (
          <MoreVertOutlinedIcon onClick={onModalOpen} className={cl(styles["product-row__dots"], "context-btn")} />
        )}
      </div>
    </li>
  );
};

export default React.memo(React.forwardRef<HTMLDivElement, ProductCardProps>(ProductElement));
