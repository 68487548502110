import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Types
import { IProducer } from "types/models";
import { MultiResponse } from "types/MultiResponse";

class ProducersAPI extends GlobalAPI {
  async create(formData: FormData): Promise<IProducer> {
    const key = enqueueSnackbar("Идет создание производителя", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/producers`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async update(producerId: string, formData: FormData): Promise<IProducer> {
    const key = enqueueSnackbar("Идет обновление производителя", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .patch(`/producers/${producerId}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findAll(): Promise<MultiResponse<IProducer>> {
    return this.axios.get(`/producers`).then((res) => res.data);
  }

  async findAllByCategoryId(categoryId: number | string): Promise<IProducer[]> {
    return this.axios.get(`/categories/${categoryId}/producers`).then((res) => res.data);
  }

  async delete(producerId: string): Promise<IProducer> {
    const key = enqueueSnackbar("Идет удаление производителя", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/producers/${producerId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new ProducersAPI();
