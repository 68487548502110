import React, { useCallback, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import cl from "classnames";

// Material UI
import Collapse from "@mui/material/Collapse";

// Images
import { MoreVertOutlinedIcon, ArrowForwardIosOutlinedIcon } from "assets/icons";
// Components
import { ParamItem } from "./ParamItem";
// Modals
import { CallbackModal } from "components/Modals";

// Typescript
import { IParam } from "types/models";
import { GroupParamsListProps } from "./GroupParamsList.props";

// Scss
import styles from "./GroupParamsList.module.scss";

// Utils
import { checkAccessLevel } from "utils/ACL";

const GroupParamsList: React.FC<GroupParamsListProps> = ({
  params,
  paramsGroup,
  onModalOpen,
  onParamDelete,
}): JSX.Element => {
  const popupManager = usePopupManager();

  const [isOpened, setOpened] = useState(false);
  const [selectedParamId, setSelectedParamId] = useState<number | null>(null);

  const handleOpenClick = () => setOpened((state) => !state);

  const handleParamDeleteClick = useCallback(
    (param: IParam) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setSelectedParamId(param.id);

      popupManager.open(CallbackModal, {
        key: "param-delete-modal",
        title: "Удалить связь с параметром?",
        anchorEl: evt.target as HTMLElement,
        anchorOrigin: { vertical: "center", horizontal: "left" },
        transformOrigin: { vertical: "center", horizontal: "right" },
        successButtonContext: { label: "Да", onClick: () => onParamDelete(param) },
        cancelButtonContext: { label: "Нет", onClick: () => {} },
        onClose: () => setSelectedParamId(null),
      });
    },
    [popupManager, onParamDelete],
  );

  const ParamsItems = useMemo(
    () =>
      params.map((param) => (
        <ParamItem
          key={param.id}
          param={param}
          selected={param.id === selectedParamId}
          onDelete={handleParamDeleteClick(param)}
        />
      )),
    [params, selectedParamId, handleParamDeleteClick],
  );

  return (
    <li className={cl(styles["group"], { [styles["group_expanded"]]: isOpened })}>
      <div className={cl(styles["group__info"])}>
        <h4 className={cl(styles["group__title"])}>{paramsGroup.translation.title}</h4>

        {checkAccessLevel("catalogue.params-groups.editor") && (
          <MoreVertOutlinedIcon onClick={onModalOpen} className={cl(styles["group__vertical-dots"])} />
        )}

        <ArrowForwardIosOutlinedIcon
          onClick={handleOpenClick}
          className={cl(styles["group__tree-arrow"])}
          sx={{ fontSize: "20px" }}
        />
      </div>
      <Collapse in={isOpened} collapsedSize='0px'>
        <ul className={cl(styles["group__param-list"])}>{ParamsItems}</ul>
      </Collapse>
    </li>
  );
};

export default React.memo(GroupParamsList);
