import React from "react";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Images
import { BackHandOutlinedIcon, SmartToyOutlinedIcon } from "assets/icons";

// Scss
import styles from "./ActionField.module.scss";

// Typescript
import { ActionFieldProps } from "./ActionField.props";

const ActionField: React.ForwardRefRenderFunction<HTMLInputElement, ActionFieldProps> = (
  { fillMode, fillModeName, onFillModeChange, ...props },
  ref,
): JSX.Element => {
  const handleFillModeChange = () => {
    onFillModeChange(fillModeName, fillMode === "default" ? "auto" : "default");
  };

  return (
    <div className={cl(styles["field"])}>
      <TextField ref={ref} fullWidth {...props} />
      <Tooltip title={fillMode === "auto" ? "Подставлять из запроса" : "Подставлять константу"}>
        <IconButton size='small' type='button' onClick={handleFillModeChange}>
          {fillMode === "auto" ? <SmartToyOutlinedIcon /> : <BackHandOutlinedIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default React.forwardRef(ActionField);
