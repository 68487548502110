import React, { useCallback } from "react";
import _isEqual from "lodash/isEqual";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Services
import CategoriesAPI from "services/catalogue/CategoriesAPI";

// Layouts
import { RightPopup } from "layouts";
// Blocks
import { CategoryForm, CategoryFormFields, categoryValidationSchema } from "blocks/Forms";

// Typescript
import { CategoryFormProps } from "./CategoryFormPopup.props";

export const CategoryFormPopup: React.FC<CategoryFormProps> = ({
  action,
  context,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const formContext = useForm({
    resolver: yupResolver(categoryValidationSchema),
    reValidateMode: "onBlur",
    mode: "all",
    defaultValues,
  });

  // Слушатель создания категории
  const handleCategoryCreate: SubmitHandler<CategoryFormFields> = useCallback(
    async (values) => {
      await CategoriesAPI.create({ ...values, parentCategoryId: context.parentCategoryId }).then(
        async (createdCategory) => {
          // Если категория успешно создана, вызываем колбэк действие и закрываем поп-ап
          await onSuccessServerResponse(createdCategory);
          onClose();
        },
      );
    },
    [context.parentCategoryId, onClose, onSuccessServerResponse],
  );

  const handleCategoryUpdate: SubmitHandler<CategoryFormFields> = useCallback(
    async (values) => {
      // Обновляем категорию только в случае, если значение какого-то поля изменилось
      if (!_isEqual(defaultValues, values) && context.editCategoryId) {
        await CategoriesAPI.update(context.editCategoryId, values).then(async (updatedCategory) => {
          // Если категория успешно обновлена, вызываем колбэк действие и закрываем поп-ап
          await onSuccessServerResponse(updatedCategory);
          onClose();
        });
      }

      onClose();
    },
    [context.editCategoryId, defaultValues, onClose, onSuccessServerResponse],
  );

  return (
    <RightPopup
      title={action === "editing" ? "Редактирование категории" : "Создание категории"}
      onClose={onClose}
      {...props}
    >
      <FormProvider {...formContext}>
        <CategoryForm
          actionType={action}
          onCancel={onClose}
          onSubmit={action === "editing" ? handleCategoryUpdate : handleCategoryCreate}
        />
      </FormProvider>
    </RightPopup>
  );
};
