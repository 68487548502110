import React, { useState } from "react";
import cl from "classnames";

// Scss
import styles from "./ProductSearchElement.module.scss";

// Typescript
import { ProductSearchElementProps } from "./ProductSearchElement.props";

// Constants
import { Constants } from "utils/Constants";

const ProductSearchElement: React.FC<ProductSearchElementProps> = ({ product, onClick }): JSX.Element => {
  const [isImageLoadError, setImageLoadError] = useState(false);
  const handleImageLoadError = () => setImageLoadError(true);

  return (
    <li className={cl(styles["product"])}>
      <div className={cl(styles["product__link"])} onClick={onClick}>
        <img
          onError={handleImageLoadError}
          src={
            product.images[0]
              ? isImageLoadError
                ? Constants["LOADING_PHOTO_ERROR"]
                : product.images[0].url
              : Constants["LOADING_PHOTO_ERROR"]
          }
          alt='Фотография товара'
          className={cl(styles["product__photo"])}
        />
        <div className={cl(styles["product__info"])}>
          <h2 className={cl(styles["product__name"])}>{product.title}</h2>
          <span className={cl(styles["product__reference"])}>{product.reference}</span>
        </div>
      </div>
    </li>
  );
};

export default React.memo(ProductSearchElement);
