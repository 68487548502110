import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntersectionObserver, useIsomorphicLayoutEffect } from "usehooks-ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePopupManager } from "react-popup-manager";
import { useHotkeys } from "react-hotkeys-hook";
import { mergeRefs } from "react-merge-refs";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import Portal from "@mui/material/Portal";

// Components
import { ProductElement } from "components/Elements";
// Modals
import { BaseModal, BaseModalButton } from "components/Modals";
// Images
import { CloseOutlinedIcon, DeleteOutlineOutlinedIcon } from "assets/icons";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./ProductsList.module.scss";

// Typescript
import { ProductsListProps } from "./ProductsList.props";
import { IProduct } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";
import {
  useManyProductsDeleteAction,
  useProductAnaloguesViewAction,
  useProductDeleteAction,
  useProductDuplicateAction,
  useProductEditAction,
  useProductMoveAction,
  useProductParametersEditAction,
} from "./ProductsList.actions";

export const ProductsList: React.FC<ProductsListProps> = ({
  products,
  currentCategoryId,
  stickyHeaderPortalRef,
  onObservableCardEnterView,
}): JSX.Element => {
  const navigate = useNavigate();
  const popupManager = usePopupManager();
  const [searchParams] = useSearchParams();
  const [isShiftPressed, setShiftStatus] = useState(false);

  const handleShiftStateChange = (e: KeyboardEvent) => {
    if (e.type === "keyup") setShiftStatus(false);
    else setShiftStatus(true);
  };

  useHotkeys("Shift", handleShiftStateChange, { keyup: true, keydown: true });

  const { viewOrder, elementsPreview, productsPagination } = useAppSelector((state) => state.catalogueReducer);

  // Выбранные карточки продуктов
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  // Референс элемента, к которому будет производиться скролл
  const selectedByURLProductRef = useRef<HTMLDivElement>(null);

  // Референс элемента, к которому будет производиться скролл
  const observableProduct = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(observableProduct, {});
  const isVisible = !!entry?.isIntersecting;

  // Эффект для автоматической загрузке недостающих данных при пролистывании
  useIsomorphicLayoutEffect(() => {
    if (isVisible) onObservableCardEnterView();
  }, [onObservableCardEnterView]);

  // Скролл к выбранному продукту (например, при глобальном поиске по каталогу)
  // Мы получаем выбранный продукт из url и перемещаем пользователя к нему
  useIsomorphicLayoutEffect(() => {
    const productId = searchParams.get("product");

    if (selectedByURLProductRef.current && productId) {
      // setSelectedProducts(productId ? [Number(productId)] : []);
      // Скролл страницы к продукту
      selectedByURLProductRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [products, selectedByURLProductRef, searchParams]);

  useEffect(() => {
    const handleEscapePress = (evt: KeyboardEvent) => {
      if (evt.key.toLowerCase() === "escape") {
        setSelectedProducts([]);
      }
    };

    document.addEventListener("keyup", handleEscapePress);

    return () => {
      document.removeEventListener("keyup", handleEscapePress);
    };
  }, []);

  const viewAnalogueAction = useProductAnaloguesViewAction();
  const productParametersEditAction = useProductParametersEditAction();
  const editProductAction = useProductEditAction(Number(currentCategoryId), productsPagination.currentPage);
  const moveProductAction = useProductMoveAction(Number(currentCategoryId), productsPagination.currentPage);
  const deleteProductAction = useProductDeleteAction(Number(currentCategoryId), productsPagination.currentPage);
  const duplicateProductAction = useProductDuplicateAction(Number(currentCategoryId), productsPagination.currentPage);

  const handleProductModalOpen = useCallback(
    (product: IProduct, isProductRootCategory: boolean) => (evt: React.MouseEvent) => {
      evt.stopPropagation();
      const buttons: BaseModalButton[] = [];

      if (checkAccessLevel("catalogue.products.editor")) {
        buttons.push(editProductAction(product));
        buttons.push(productParametersEditAction(product));
      }

      if (checkAccessLevel("catalogue.products.admin") && isProductRootCategory) {
        buttons.push(moveProductAction(product));
        buttons.push(duplicateProductAction(product));
      }

      if (checkAccessLevel("catalogue.products.admin") && isProductRootCategory) {
        buttons.push(viewAnalogueAction(product));
      }

      if (checkAccessLevel("catalogue.products.admin")) {
        buttons.push(deleteProductAction(product));
      }

      setSelectedProducts([product]);
      popupManager.open(BaseModal, { anchorEl: evt.target as HTMLElement, key: "product-modal", buttons });
    },
    [
      popupManager,
      editProductAction,
      productParametersEditAction,
      moveProductAction,
      duplicateProductAction,
      viewAnalogueAction,
      deleteProductAction,
    ],
  );

  const handleReferenceIconClick = useCallback(
    (product: IProduct) => (evt: React.MouseEvent) => {
      evt.stopPropagation();

      const parentalCategoryId = product.categories.find((c) => c.productCategory.parental)?.id + "";
      navigate(`/categories/${parentalCategoryId}?product=${product.id}`);
    },
    [navigate],
  );

  const handleProductClick = useCallback(
    (product: IProduct) => () => {
      const isSelected = selectedProducts.findIndex((p) => p.id === product.id) !== -1;

      if (document.getSelection()) {
        document.getSelection()?.removeAllRanges();
      }

      if (!isSelected && isShiftPressed) setSelectedProducts([...selectedProducts, product]);
      else if (!isSelected && !isShiftPressed) setSelectedProducts([product]);
      else setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    },
    [isShiftPressed, selectedProducts],
  );

  const handleUnselectAll = () => setSelectedProducts([]);

  const manyDeleteAction = useManyProductsDeleteAction(Number(currentCategoryId), productsPagination.currentPage);

  // Рендер карточек продуктов
  const ProductsElements = useMemo(
    () =>
      (products ?? []).map((product, index) => {
        const scrollRef = searchParams.get("product") === product.id + "" ? selectedByURLProductRef : null;
        const observerRef = index === products.length - 2 ? observableProduct : null;
        const productCategory = product.categories.find((p) => p.id == currentCategoryId);
        const isProductParentCategory = productCategory?.productCategory.parental;

        return (
          <ProductElement
            key={product.id}
            product={product}
            to={`/products/${product.id}`}
            isReference={!isProductParentCategory}
            ref={mergeRefs([scrollRef, observerRef])}
            isSelected={selectedProducts.includes(product)}
            preview={elementsPreview === "cards" ? "card" : "row"}
            image={product.images[0] ? product.images[0].url : undefined}
            onModalOpen={handleProductModalOpen(product, isProductParentCategory ?? false)}
            onReferenceIconClick={handleReferenceIconClick(product)}
            onClick={handleProductClick(product)}
          />
        );
      }),
    [
      products,
      searchParams,
      selectedProducts,
      elementsPreview,
      handleProductModalOpen,
      handleReferenceIconClick,
      handleProductClick,
      currentCategoryId,
    ],
  );

  return (
    <>
      {selectedProducts.length > 1 && (
        <Portal container={stickyHeaderPortalRef.current}>
          <Button
            type='button'
            variant='text'
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={manyDeleteAction(selectedProducts)}
          >
            Удалить выбранные продукты
          </Button>
          <Button type='button' variant='text' startIcon={<CloseOutlinedIcon />} onClick={handleUnselectAll}>
            Снять выделение
          </Button>
        </Portal>
      )}

      <div
        style={{ order: viewOrder === "categories" ? "2" : "1" }}
        className={cl(styles["products-list"], styles[`products-list_${elementsPreview}`], {
          [styles["products-list_hidden"]]: products.length === 0,
        })}
      >
        {ProductsElements}
      </div>
    </>
  );
};
