import React from "react";
import cl from "classnames";

// Material UI
import Fade from "@mui/material/Fade";

// Images
import { ReactComponent as LoaderSVG } from "assets/images/loader.svg";

// Scss
import styles from "./Loader.module.scss";

// Typescript
import { LoaderProps } from "./Loader.props";

export const Loader: React.FC<LoaderProps> = ({ isActive, className, ...props }): JSX.Element => {
  return (
    <Fade in={isActive} timeout={{ enter: 100, exit: 300 }}>
      <div className={cl(styles["loader"], className)} {...props}>
        <LoaderSVG className={cl(styles["loader__icon"])} />
      </div>
    </Fade>
  );
};
