import React, { useRef, useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { v4 } from "uuid";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

// Images
import { FileUploadOutlinedIcon } from "assets/icons";
// Blocks
import { ImagesList } from "blocks";

// Scss
import styles from "./ProducerForm.module.scss";

// Typescript
import { ProducerFormFields, ProducerFormProps } from "./ProducerForm.props";
import { ListImage } from "types/models";

export const ProducerForm: React.FC<ProducerFormProps> = ({ actionType, onSubmit, onCancel }): JSX.Element => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ProducerFormFields>();

  const filesInputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [image, setImage] = useState<ListImage | null>(null);

  const handleFormSubmit: SubmitHandler<ProducerFormFields> = async (data, event) => {
    setSubmitting(true);

    await new Promise((resolve) => {
      resolve(onSubmit(data, event));
    }).finally(() => setSubmitting(false));
  };

  // Слушатель открытия поп-ап окна выбора фотографии
  const handleAttachPhotoPopupOpen = () => {
    if (filesInputRef && filesInputRef.current) {
      filesInputRef.current.click();
    }
  };

  // Слушатель изменения поля ввода для фотографий
  const handleFilesInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;

    if (target && target.files) {
      const file = target.files[0];
      const fileId = v4();

      setValue("image", file);
      setImage({ id: fileId, name: file.name, url: window.URL.createObjectURL(file) });
    }
  };

  const onImageDeleteClick = () => {
    setValue("image", null);
    setImage(null);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
      <TextField
        required
        fullWidth
        type='text'
        label='Название'
        placeholder='Введите название производителя'
        {...register("name")}
        error={errors["name"]?.message !== undefined}
        helperText={errors["name"]?.message}
      />
      <TextField
        rows={4}
        fullWidth
        multiline
        type='text'
        label='Описание'
        placeholder='Введите описание'
        {...register("description")}
        error={errors["description"]?.message !== undefined}
        helperText={errors["description"]?.message}
      />

      <Button type='button' variant='text' startIcon={<FileUploadOutlinedIcon />} onClick={handleAttachPhotoPopupOpen}>
        Добавить фото
      </Button>

      <input
        multiple
        type='file'
        ref={filesInputRef}
        style={{ display: "none" }}
        onChange={handleFilesInputChange}
        accept='image/jpeg,image/pjpeg,image/png,image/svg+xml,image/webp'
      />

      <ImagesList
        images={image ? [image] : []}
        onImageDelete={onImageDeleteClick}
        className={cl(styles["form__images"])}
      />

      <div className={cl(styles["form__buttons"])}>
        <LoadingButton
          size='large'
          loading={isSubmitting}
          disabled={isSubmitting}
          type='submit'
          variant='contained'
          sx={{ width: "100%" }}
        >
          {actionType === "creation" ? "Создать" : "Сохранить"}
        </LoadingButton>
        <Button size='large' onClick={onCancel} type='button' variant='outlined' sx={{ width: "100%" }}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
