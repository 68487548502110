import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Types
import { IProductType } from "types/models";
import { ProductTypeFormFields } from "blocks/Forms";
import { MultiResponse } from "types/MultiResponse";

class ProductsTypesAPI extends GlobalAPI {
  async create(formData: ProductTypeFormFields): Promise<IProductType> {
    const key = enqueueSnackbar("Идет создание типа товаров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/products/types`, formData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async findAll(): Promise<MultiResponse<IProductType>> {
    return this.axios.get(`/products/types`).then((res) => res.data);
  }

  async update(productTypeId: string, formData: Partial<ProductTypeFormFields>): Promise<IProductType> {
    const key = enqueueSnackbar("Идет обновление типа товаров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .patch(`/products/types/${productTypeId}`, formData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async delete(productTypeId: string): Promise<IProductType> {
    const key = enqueueSnackbar("Идет удаление типа товаров", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/products/types/${productTypeId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new ProductsTypesAPI();
