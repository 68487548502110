import React, { useCallback, useState } from "react";
import cl from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import _get from "lodash/get";

// Material UI
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Images
import { BugReportOutlinedIcon } from "assets/icons";
// Components
import { ActionField } from "components/Fields";
// Layouts
import { AutomationAction } from "layouts";
// Forms
import { AutomationFormFields, ProductFormFields } from "blocks/Forms";

// Scss
import styles from "./AutomationProductForm.module.scss";

export const AutomationProductForm: React.FC = (): JSX.Element => {
  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext<AutomationFormFields>();
  const watchedFormFields = useWatch({ control });

  const [currentMode, setCurrentMode] = useState<"default" | "debug">("default");
  const [productDebugHelperTexts, setProductDebugHelperTexts] = useState<
    Partial<Record<keyof ProductFormFields, string>>
  >({});

  const handleProductFieldsFill = useCallback(() => {
    if (currentMode === "debug") {
      setCurrentMode("default");
      return;
    }

    const startPoint = watchedFormFields.startPoint?.isArray
      ? watchedFormFields.startPoint.startKey + "[0]."
      : watchedFormFields.startPoint?.startKey + "";

    const newHelperTexts = Object.entries(watchedFormFields?.fieldsMap?.product ?? {}).reduce(
      (prev, [key, { value, fillMode }]) => {
        if (fillMode === "auto")
          return { ...prev, [key]: _get(watchedFormFields.testResponse, (startPoint + value) as string) };
        else return { ...prev, [key]: value };
      },
      productDebugHelperTexts,
    );

    setProductDebugHelperTexts(newHelperTexts);
    setCurrentMode("debug");
  }, [
    currentMode,
    productDebugHelperTexts,
    watchedFormFields?.fieldsMap?.product,
    watchedFormFields.testResponse,
    watchedFormFields.startPoint,
  ]);

  const handleFillModeChange = (fieldName: string, mode: "auto" | "default") => {
    setValue(fieldName as keyof AutomationFormFields, mode);
  };

  return (
    <AutomationAction
      title='Создание товара'
      buttons={
        <Tooltip title='Проверка подстановки'>
          <IconButton size='small' type='button' onClick={handleProductFieldsFill}>
            <BugReportOutlinedIcon color={currentMode === "default" ? "inherit" : "primary"} />
          </IconButton>
        </Tooltip>
      }
    >
      <ul className={cl(styles["fields"])}>
        <ActionField
          required
          size='small'
          variant='outlined'
          label='Название товара'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.product.title.value")}
          fillModeName='fieldsMap.product.title.fillMode'
          error={!!errors["fieldsMap"]?.product?.title?.value}
          helperText={errors["fieldsMap"]?.product?.title?.value?.message}
          fillMode={watchedFormFields.fieldsMap?.product?.title?.fillMode ?? "default"}
          onFillModeChange={handleFillModeChange}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.product?.title?.value ?? ""
              : productDebugHelperTexts.title ?? ""
          }
        />
        <ActionField
          required
          size='small'
          variant='outlined'
          label='Артикул товара'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.product.reference.value")}
          fillModeName='fieldsMap.product.reference.fillMode'
          error={!!errors["fieldsMap"]?.product?.reference?.value}
          helperText={errors["fieldsMap"]?.product?.reference?.value?.message}
          fillMode={watchedFormFields.fieldsMap?.product?.reference?.fillMode ?? "default"}
          onFillModeChange={handleFillModeChange}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.product?.reference?.value ?? ""
              : productDebugHelperTexts.reference ?? ""
          }
        />
        <ActionField
          required
          size='small'
          variant='outlined'
          label='Описание товара'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.product.description.value")}
          fillModeName='fieldsMap.product.description.fillMode'
          error={!!errors["fieldsMap"]?.product?.description?.value}
          helperText={errors["fieldsMap"]?.product?.description?.value?.message}
          fillMode={watchedFormFields.fieldsMap?.product?.description?.fillMode ?? "default"}
          onFillModeChange={handleFillModeChange}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.product?.description?.value ?? ""
              : productDebugHelperTexts.description ?? ""
          }
        />
        <ActionField
          required
          size='small'
          variant='outlined'
          label='Производитель товара'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.product.producer.value")}
          fillModeName='fieldsMap.product.producer.fillMode'
          error={!!errors["fieldsMap"]?.product?.producer?.value}
          helperText={errors["fieldsMap"]?.product?.producer?.value?.message}
          fillMode={watchedFormFields.fieldsMap?.product?.producer?.fillMode ?? "default"}
          onFillModeChange={handleFillModeChange}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.product?.producer?.value ?? ""
              : productDebugHelperTexts.producer ?? ""
          }
        />
        <ActionField
          required
          size='small'
          variant='outlined'
          label='Тип / класс товара'
          disabled={currentMode === "debug"}
          {...register("fieldsMap.product.productType.value")}
          fillModeName='fieldsMap.product.productType.fillMode'
          error={!!errors["fieldsMap"]?.product?.productType?.value}
          helperText={errors["fieldsMap"]?.product?.productType?.value?.message}
          fillMode={watchedFormFields.fieldsMap?.product?.productType?.fillMode ?? "default"}
          onFillModeChange={handleFillModeChange}
          value={
            currentMode === "default"
              ? watchedFormFields.fieldsMap?.product?.productType?.value ?? ""
              : productDebugHelperTexts.productType ?? ""
          }
        />
      </ul>
    </AutomationAction>
  );
};
