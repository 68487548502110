import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Services
import { CategoriesAPI } from "services/catalogue";

// Components
import { DropdownSelect } from "components/Fields";
// Images
import {
  GridViewRoundedIcon,
  TableRowsRoundedIcon,
  FileDownloadOutlinedIcon,
  FileUploadOutlinedIcon,
  EditOutlinedIcon,
  ArrowBackOutlinedIcon,
  PrecisionManufacturingOutlinedIcon,
} from "assets/icons";
// Components
import { BreadCrumb } from "components/Elements";
// Popups
import { AutomationListPopup, CategoryViewPopup } from "popups";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { catalogueSlice } from "store/reducers/CatalogueSlice";
import { changeElementsPreview } from "store/actions/CatalogueActions";

// Scss
import styles from "./CatalogueHeader.module.scss";

// Utils
import { checkAccessLevel } from "utils/ACL";

const SortingDictionary = {
  DATE_ASC: "Сначала старые",
  DATE_DESC: "Сначала новые",
  TEXT_ASC: "По названию (А-Я)",
  TEXT_DESC: "По названию (Я-А)",
};

export const CatalogueHeader: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { categoryId } = useParams();

  const xlsxInputRef = useRef<HTMLInputElement>(null);

  const { sortingType, currentCategory, parentCategories, elementsPreview } = useAppSelector(
    (state) => state.catalogueReducer,
  );

  useEffect(() => {
    const previewType = Cookies.get("rms-catalogue_elements-preview") as "cards" | "rows";
    dispatch(changeElementsPreview(previewType ?? "cards"));
  }, [dispatch]);

  const setCardsPreviewSelect = () => dispatch(changeElementsPreview("cards"));
  const setRowsPreviewSelect = () => dispatch(changeElementsPreview("rows"));

  const CrumbsElements = useMemo(
    () =>
      parentCategories
        .filter((i) => i.parentCategoryId)
        .map((category, index) => (
          <BreadCrumb
            key={category.id}
            isArrowActive={index !== 0}
            text={category.translation.title}
            to={`/categories/${category.id}`}
          />
        )),
    [parentCategories],
  );

  const handleCategoryEdit = () => {
    if (currentCategory) {
      popupManager.open(CategoryViewPopup, {
        initialCategory: currentCategory,
      });
    }
  };

  const handleAutomationClick = () => {
    if (currentCategory) {
      popupManager.open(AutomationListPopup, { categoryId: currentCategory?.id });
    }
  };

  const downloadTemplate = async () => {
    await CategoriesAPI.downloadTemplate(categoryId || "1").then(() => {
      enqueueSnackbar("Файл подготовлен, скоро начнется его скачивание...", { variant: "success" });
    });
  };

  const uploadTemplate = () => {
    if (xlsxInputRef.current) {
      xlsxInputRef.current.click();
    }
  };

  const handleFileUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;

    if (target && target.files) {
      const file = target.files[0];

      const formData = new FormData();
      formData.append("file", file);

      await CategoriesAPI.uploadTemplate(categoryId || "1", formData).then(() => {
        enqueueSnackbar("Шаблон успешно загружен. Через 5 секунд произойдет перезагрузка страницы", {
          variant: "success",
        });

        setTimeout(() => window.location.reload(), 5000);
      });
    }
  };

  const handleSortFieldValueChange = (value: string) => {
    dispatch(catalogueSlice.actions.setDataSortingState(value));
  };

  const handleGoBackClick = () => {
    navigate(`/categories/${currentCategory?.parentCategoryId}`);
  };

  return (
    <div
      className={cl(styles["catalogue-header"], {
        [styles["catalogue-header_short"]]: !currentCategory?.parentCategoryId,
      })}
    >
      {typeof currentCategory?.parentCategoryId === "number" && (
        <ul className={cl(styles["catalogue-header__bread-crumbs"])}>
          <BreadCrumb isArrowActive text='Главная' to={`/categories/${process.env.REACT_APP_ROOT_ID}`} />
          <ul className={cl(styles["catalogue-header__crumbs-child-list"])}>{CrumbsElements}</ul>
        </ul>
      )}

      {currentCategory?.parentCategoryId !== undefined && (
        <>
          <div className={cl(styles["catalogue-header__buttons"])}>
            <DropdownSelect
              className={cl(styles["catalogue-header__sort-btn"])}
              title='Показать:'
              values={SortingDictionary}
              value={sortingType}
              onChange={handleSortFieldValueChange}
            />
            <GridViewRoundedIcon
              onClick={setCardsPreviewSelect}
              className={cl(styles["catalogue-header__view-btn"], {
                [styles["catalogue-header__view-btn_active"]]: elementsPreview === "cards",
              })}
            />
            <TableRowsRoundedIcon
              onClick={setRowsPreviewSelect}
              className={cl(styles["catalogue-header__view-btn"], {
                [styles["catalogue-header__view-btn_active"]]: elementsPreview === "rows",
              })}
            />
          </div>

          <div className={cl(styles["catalogue-header__info"])}>
            {currentCategory.parentCategoryId !== null && (
              <Tooltip title='Вернуться назад'>
                <IconButton type='button' onClick={handleGoBackClick} className={cl(styles["catalogue-header__back"])}>
                  <ArrowBackOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}

            <h1 className={cl(styles["catalogue-header__title"])}>
              {currentCategory?.translation.title ?? "Неизвестно"}
            </h1>

            {typeof currentCategory?.parentCategoryId === "number" &&
              checkAccessLevel("catalogue.categories.editor") && (
                <>
                  <Tooltip title='Редактировать категорию'>
                    <IconButton
                      type='button'
                      onClick={handleCategoryEdit}
                      className={cl(styles["catalogue-header__edit"])}
                      sx={{ marginLeft: "16px" }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Автоматизация'>
                    <IconButton type='button' onClick={handleAutomationClick}>
                      <PrecisionManufacturingOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}

            {typeof currentCategory?.parentCategoryId === "number" && checkAccessLevel("catalogue.admin") && (
              <div className={cl(styles["catalogue-header__files-buttons"])}>
                <Button variant='text' startIcon={<FileDownloadOutlinedIcon />} onClick={downloadTemplate}>
                  Экспорт в XLSX
                </Button>
                <Button variant='text' startIcon={<FileUploadOutlinedIcon />} onClick={uploadTemplate}>
                  Загрузить файл
                </Button>
                <input
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  style={{ display: "none" }}
                  ref={xlsxInputRef}
                  type='file'
                  id='uploadTemplate'
                  onChange={handleFileUpload}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
