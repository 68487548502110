import { store } from "..";

// Массив уровней доступа
const actions = ["*", "admin", "editor", "viewer"];

/**
 * Функция, отвечающая за проверку у пользователя прав доступа определенного уровня
 *
 * @param permissionsArray массив ACL ("passport.users.viewer") пользователя
 * @param lowAccessPermissions итератор минимальных уровней доступа (работает по принципу логического "ИЛИ")
 * @returns true – пользователь имеет право доступа, false – у пользователя нет доступа
 */
export const checkAccessLevel = (...lowAccessPermissions: (keyof typeof Permissions)[]): boolean => {
  const { authReducer } = store.getState();
  const { acl } = authReducer;

  return lowAccessPermissions.reduce((prevStatus: boolean, lowAccessPermission) => {
    const iterationActions = [...actions];

    // Если у пользователя был результат прошлой проверки true, то возвращаем предидущий результат
    if (prevStatus) {
      return prevStatus;
    }

    // Разделяем ACL строку на части (по ".")
    // passport.users.viewer =>  ["passport", "users", "viewer"]
    const lowAccessPermissionParts: string[] = lowAccessPermission.split(".");

    // Теперь убираем из массива действий те, что ниже по значимости
    // Например, если передали editor, то убираем viewer
    const action = lowAccessPermissionParts.pop();
    const actionIndexInActionsArray = iterationActions.findIndex((el) => el === action);
    const availableActions = iterationActions.splice(0, actionIndexInActionsArray + 1);

    // Создаем статусную переменную для остановки цикла и сохранения результата
    let isPermissionsArrayContainsOneOf = false;

    do {
      // Объединяем части
      const availablePermissionString = lowAccessPermissionParts.join(".");

      // Проверяем, что массив прав пользователя имеет хотя бы одно вхождение в массив достпных прав
      // Например: ["passport.users.admin"] => ["passport.users.*", "passport.users.admin"]
      isPermissionsArrayContainsOneOf = availableActions.some((availableAction) => {
        return acl.includes(`${availablePermissionString}.${availableAction}`);
      });

      // Удаляем один уровень ACL с конца
      lowAccessPermissionParts.pop();
    } while (lowAccessPermissionParts.length > 0 && !isPermissionsArrayContainsOneOf);

    return isPermissionsArrayContainsOneOf;
  }, false);
};

export enum Permissions {
  "catalogue.admin" = "catalogue.admin",
  "catalogue.editor" = "catalogue.editor",

  "catalogue.categories.admin" = "catalogue.categories.admin",
  "catalogue.categories.editor" = "catalogue.categories.editor",
  "catalogue.categories.viewer" = "catalogue.categories.viewer",

  "catalogue.params-groups.admin" = "catalogue.params-groups.admin",
  "catalogue.params-groups.editor" = "catalogue.params-groups.editor",

  "catalogue.products.admin" = "catalogue.products.admin",
  "catalogue.products.editor" = "catalogue.products.editor",
  "catalogue.products.viewer" = "catalogue.products.viewer",

  "catalogue.params.admin" = "catalogue.params.admin",
  "catalogue.params.editor" = "catalogue.params.editor",
  "catalogue.params.viewer" = "catalogue.params.viewer",
}
