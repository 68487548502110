import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Pages
import { CategoryPage, ProductPage } from "pages";
// Components
import { Loader } from "components/Elements";
// Blocks
import { HeaderWrapper, Footer } from "blocks";
// Layouts
import { PageWrapper } from "layouts";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchUserData } from "store/actions/AuthActions";

// Utils
import { checkAccessLevel } from "utils/ACL";

export const App: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAuthorized } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    // По умолчанию устанавливаем язык
    // TODO добавить поддержку мультиязычности
    localStorage.setItem("lang", "RU");
  }, []);

  useEffect(() => {
    if (isAuthorized === false) {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}?referer=${window.location.href}`;
    }
  }, [isAuthorized]);

  return isAuthorized === null ? (
    <Loader isActive />
  ) : isAuthorized === true ? (
    <>
      <HeaderWrapper />

      <Routes>
        {checkAccessLevel("catalogue.products.viewer") && (
          <Route
            path='/products/:productId'
            element={
              <PageWrapper>
                <ProductPage />
              </PageWrapper>
            }
          />
        )}

        {checkAccessLevel("catalogue.categories.viewer") && (
          <Route
            path='/categories/:categoryId'
            element={
              <PageWrapper>
                <CategoryPage />
              </PageWrapper>
            }
          />
        )}

        <Route path='*' element={<Navigate to={`/categories/${process.env.REACT_APP_ROOT_ID || "1"}`} />} />
      </Routes>

      <Footer />
    </>
  ) : (
    <></>
  );
};
