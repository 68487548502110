import { closeSnackbar, enqueueSnackbar } from "notistack";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { IParam } from "types/models";
import { MultiResponse } from "types/MultiResponse";
import { ParamFormFields } from "blocks/Forms";

class ParamsAPI extends GlobalAPI {
  async create(paramData: ParamFormFields): Promise<IParam> {
    const key = enqueueSnackbar("Идет создание параметра", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .post(`/params`, paramData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  findAll(params: { page: number; amount: number; searchText?: string }): Promise<MultiResponse<IParam>> {
    return this.axios.get("/params", { params }).then((res) => res.data);
  }

  async getByParamsGroup(groupId: number): Promise<MultiResponse<IParam>> {
    return this.axios.get(`/params-groups/${groupId}/params`).then((res) => res.data);
  }

  async update(paramId: number | string, paramData: Partial<ParamFormFields>): Promise<IParam> {
    const key = enqueueSnackbar("Идет обновление параметра", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .patch(`/params/${paramId}`, paramData)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }

  async delete(paramId: number): Promise<IParam> {
    const key = enqueueSnackbar("Идет удаление параметра", {
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      variant: "info",
    });

    return this.axios
      .delete(`/params/${paramId}`)
      .then((res) => res.data)
      .finally(() => setTimeout(() => closeSnackbar(key), 200));
  }
}

export default new ParamsAPI();
