import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button/Button";

// Layouts
import { PopupLayout } from "layouts";

// Scss
import styles from "./CallbackPopup.module.scss";

// Typescript
import { CallbackPopupProps } from "./CallbackPopup.props";

export const CallbackPopup: React.FC<CallbackPopupProps> = ({
  title,
  submitButtonText,
  cancelButtonText,
  onSubmitClick,
  onCancelClick,
  onClose = () => {},
  ...props
}): JSX.Element => {
  // Слушатель клика по кнопке подтверждения
  const handleSubmitClick = () => {
    onSubmitClick();
    onClose();
  };

  // Слушатель клика по кнопке отмены
  const handleCancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    }

    onClose();
  };

  return (
    <PopupLayout onClose={onClose} {...props}>
      <div className={cl(styles["popup__content"])}>
        <h2 className={cl(styles["popup__title"])}>{title}</h2>
        <div className={cl(styles["popup__buttons"])}>
          <Button variant='contained' size='medium' onClick={handleSubmitClick} type='button'>
            {submitButtonText}
          </Button>
          <Button variant='outlined' size='medium' onClick={handleCancelClick} type='button'>
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </PopupLayout>
  );
};
