import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

// Services
import { AutomationAPI } from "services/catalogue";

// Layouts
import { PopupLayout } from "layouts";
// Forms
import { AutomationSourceForm, AutomationFormFields } from "blocks/Forms";

// Typescript
import { AutomationFormPopupProps } from "./AutomationFormPopup.props";

export const AutomationFormPopup: React.FC<AutomationFormPopupProps> = ({
  categoryId,
  onSuccessSubmit,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm<AutomationFormFields>({ mode: "all" });

  const handleFormSubmit: SubmitHandler<AutomationFormFields> = async (values) => {
    await AutomationAPI.executeAutomation(categoryId, values).then(async () => {
      enqueueSnackbar({ message: "Автоматизация успешно выполнена", variant: "success" });
      await onSuccessSubmit();
    });
  };

  return (
    <PopupLayout title='РМС Автоматизация' onClose={onClose} titleStyle={{ textAlign: "center" }} {...props}>
      <FormProvider {...formContext}>
        <AutomationSourceForm onFormSubmit={handleFormSubmit} onCancel={onClose} />
      </FormProvider>
    </PopupLayout>
  );
};
