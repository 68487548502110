import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Services
import { ProductsAPI } from "services/catalogue";

// Layouts
import { RightPopup } from "layouts";
// Components
import { CatalogueSearch, Loader, ProductAnalogueRow } from "components/Elements";

// Styles
import styles from "./AnaloguesPopup.module.scss";

// Typescript
import { AnaloguesPopupProps } from "./AnaloguesPopup.props";
import { IProduct } from "types/models";

export const AnaloguesPopup: React.FC<AnaloguesPopupProps> = ({ product, isOpen, onClose = () => {} }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  // Индикатор загрузки данных поп-ап окна
  const [isPending, setPendingStatus] = useState(true);
  // Массив аналогов
  const [analogues, setAnalogues] = useState<IProduct[]>([]);

  // Метод для загрузки аналогов, которые уже сохранены
  const loadAnalogues = useCallback(async () => {
    setPendingStatus(true);

    const analogues = await ProductsAPI.findAnalogues(product.id, { scope: ["withProducer", "withImages"] });
    setAnalogues(analogues.rows);

    setTimeout(() => setPendingStatus(false), 200);
  }, [product.id]);

  useEffect(() => {
    loadAnalogues();
  }, [loadAnalogues, product]);

  const handleAnalogueDeleteClick = useCallback(
    async (analogueId: string | number) => {
      setPendingStatus(true);

      await ProductsAPI.deleteAnalogue(product.id, analogueId).then(() => {
        enqueueSnackbar(`Аналог товара успешно удален`, { variant: "success" });
        setAnalogues((state) => state.filter((pA) => pA.id != analogueId));

        setTimeout(() => setPendingStatus(false), 200);
      });
    },
    [enqueueSnackbar, product.id],
  );

  // Слушатель клика по товару в поле поиска
  const onSearchProductRowClick = async (newAnalogueProduct: IProduct) => {
    const isProductNotExists = analogues.findIndex((pA) => pA.id == newAnalogueProduct.id) === -1;

    // Если пользователь не пытается прикрепить товару его же аналог
    // И, если этот аналог уже существует в списке аналогов
    if (newAnalogueProduct.id != product.id && isProductNotExists) {
      setPendingStatus(true);

      await ProductsAPI.createAnalogue(product.id, newAnalogueProduct.id).then(async () => {
        enqueueSnackbar(`Аналог "${newAnalogueProduct.title}" успешно добавлен`, { variant: "success" });
        await loadAnalogues();

        setTimeout(() => setPendingStatus(false), 200);
      });
    }
  };

  const AnaloguesRows = useMemo(
    () =>
      analogues.map((analogue) => (
        <ProductAnalogueRow key={analogue.id} product={analogue} onDelete={handleAnalogueDeleteClick} />
      )),
    [analogues, handleAnalogueDeleteClick],
  );

  return (
    <RightPopup
      title={`Аналоги товара "${product.title}" (${product.reference})`}
      isOpen={isOpen}
      onClose={onClose}
      contentBoxClassName={cl(styles["popup__content"])}
    >
      <Loader isActive={isPending} />

      <CatalogueSearch
        onProductClick={onSearchProductRowClick}
        searchOptions={{
          excludeProductsIds: [...analogues.map((a) => a.id), product.id],
          categories: false,
          products: true,
        }}
      />

      <div className={cl(styles["popup__analogues"])}>
        <h2 className={cl(styles["popup__analogues-title"])}>Добавленные аналоги</h2>
        <ul className={cl(styles["popup__analogues-list"])}>{AnaloguesRows}</ul>
      </div>
    </RightPopup>
  );
};
