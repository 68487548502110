import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Layouts
import { AutomationAction } from "layouts";
// Blocks
import { AutomationFormFields } from "blocks/Forms";
import { AutomationProductForm, SetupParametersForm } from "blocks/Forms/AutomationActions";

// Scss
import styles from "./AutomationActionsForm.module.scss";

export const AutomationActionsForm: React.FC = (): JSX.Element => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<AutomationFormFields>();
  const { actionType, startPoint } = useWatch({ control });

  return (
    <>
      <AutomationAction title='Настройки входа'>
        <div className={cl(styles["fields"])}>
          <TextField
            required
            fullWidth
            label='Ключ данных'
            {...register("startPoint.startKey")}
            error={!!errors["startPoint"]?.startKey}
            helperText={errors["startPoint"]?.startKey?.message}
          />
          <FormControlLabel
            control={<Checkbox checked={startPoint?.isArray ?? false} {...register("startPoint.isArray")} />}
            label='Входные данные являются массивом?'
          />
        </div>
      </AutomationAction>
      {["create-product", "product-with-parameters"].includes(actionType ?? "") && <AutomationProductForm />}
      {["product-with-parameters"].includes(actionType ?? "") && <SetupParametersForm />}
    </>
  );
};
