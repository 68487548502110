import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Typescript
import { ICategory, IProduct } from "types/models";

interface PaginationInfo {
  currentPage: number;
  maxPage: number;
}

export interface LoadingFlags {
  isCategoriesLoading: boolean;
  isProductsLoading: boolean;
  isCurrentCategoryLoading: boolean;
  isParentCategoriesLoading: boolean;
}

// 1. Описываем стейт в интерфейсе
interface CatalogueState {
  flags: LoadingFlags;
  error: string | null;

  currentCategory: ICategory | null;

  parentCategories: ICategory[];

  viewOrder: "categories" | "products";
  elementsPreview: "cards" | "rows";

  categories: ICategory[];
  products: IProduct[];

  categoriesPagination: PaginationInfo;
  productsPagination: PaginationInfo;

  sortingType: "DATE_DESC" | "DATE_ASC" | string;
  showParental: boolean;
}

// 2. Подключаем и устанавливаем начальные значения в стейт
const initialState: CatalogueState = {
  flags: {
    isProductsLoading: true,
    isCategoriesLoading: true,
    isCurrentCategoryLoading: true,
    isParentCategoriesLoading: true,
  },

  error: null,
  currentCategory: null,
  categories: [],
  parentCategories: [],
  viewOrder: "categories",
  elementsPreview: "cards",

  products: [],
  categoriesPagination: { currentPage: 0, maxPage: 0 },
  productsPagination: { currentPage: 0, maxPage: 0 },

  sortingType: "DATE_DESC",
  showParental: false,
};

// 3. Описываем функции для слайса (редьюсеры)
export const catalogueSlice = createSlice({
  name: "catalogue",
  initialState,
  reducers: {
    changeCurrentCategory(state, action: PayloadAction<ICategory | null>) {
      state.currentCategory = action.payload;
    },
    changeParentCategories(state, action: PayloadAction<ICategory[]>) {
      state.parentCategories = action.payload;
    },
    changeViewOrder(state) {
      state.viewOrder = state.viewOrder === "categories" ? "products" : "categories";
    },
    changeElementsPreview(state, action: PayloadAction<"rows" | "cards">) {
      state.elementsPreview = action.payload;
    },
    setFlags(state, action: PayloadAction<Partial<LoadingFlags>>) {
      state.flags = { ...state.flags, ...action.payload };
    },
    setChildrenCategoriesState(state, action: PayloadAction<ICategory[]>) {
      state.categories = action.payload;
    },
    expandChildrenCategoriesState(state, action: PayloadAction<ICategory[]>) {
      state.categories = [...state.categories, ...action.payload];
    },
    setProductsState(state, action: PayloadAction<IProduct[]>) {
      state.products = action.payload;
    },
    expandProductsState(state, action: PayloadAction<IProduct[]>) {
      state.products = [...state.products, ...action.payload];
    },
    setCategoriesPaginationState(state, action: PayloadAction<PaginationInfo>) {
      state.categoriesPagination = action.payload;
    },
    setProductsPaginationState(state, action: PayloadAction<PaginationInfo>) {
      state.productsPagination = action.payload;
    },
    setDataSortingState(state, action: PayloadAction<string>) {
      state.sortingType = action.payload;
    },
  },
});

// 4. Экспортируем и подключаем импортом там, где необходимо вызвать
export default catalogueSlice.reducer;
