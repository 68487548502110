// GlobalAPI
import { IUser } from "types/models/User";
import { GlobalAPI } from "../GlobalAPI";

class PassportAPI extends GlobalAPI {
  async loadUser(): Promise<IUser> {
    return this.axios.get(`${process.env.REACT_APP_PASSPORT_BASE_URL}/users/me`).then((res) => res.data);
  }
}

export default new PassportAPI();
