import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

// Services
import { ProductsTypesAPI } from "services/catalogue";

// Layouts
import { RightPopup } from "layouts";
// Blocks
import { ProductTypeForm, productTypeValidationSchema } from "blocks/Forms";

// Typescript
import { ProductTypeFormFields, ProductTypeFormProps } from "./ProductTypeFormPopup.props";

export const ProductTypeFormPopup: React.FC<ProductTypeFormProps> = ({
  isOpen,
  context,
  actionType,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm({ mode: "all", resolver: yupResolver(productTypeValidationSchema), defaultValues });

  const handleProductTypeCreate = async (values: ProductTypeFormFields) => {
    await ProductsTypesAPI.create(values).then((productType) => {
      enqueueSnackbar(`Новый тип товаров "${values.title}" успешно создан`, { variant: "success" });
      onSuccessServerResponse(productType);
      onClose();
    });
  };

  const handleProductTypeUpdate = async (values: ProductTypeFormFields) => {
    await ProductsTypesAPI.update(context.editProductTypeId + "", values).then((productType) => {
      enqueueSnackbar(`Тип товаров "${values.title}" успешно обновлен`, { variant: "success" });
      onSuccessServerResponse(productType);
      onClose();
    });
  };

  return (
    <RightPopup
      isOpen={isOpen}
      onClose={onClose}
      title={actionType === "creation" ? "Создание типа товаров" : "Редактирование типа товаров"}
    >
      <FormProvider {...formContext}>
        <ProductTypeForm
          actionType={actionType}
          onSubmit={actionType === "creation" ? handleProductTypeCreate : handleProductTypeUpdate}
          onCancel={onClose}
        />
      </FormProvider>
    </RightPopup>
  );
};
