import React, { useCallback, useEffect, useMemo, useState } from "react";
import cl from "classnames";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { usePopupManager } from "react-popup-manager";

// Material UI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Services
import ProductsAPI from "services/catalogue/ProductsAPI";

// Images
import { EditOutlinedIcon, ArrowBackOutlinedIcon } from "assets/icons";
// Popups
import { ProductFormPopup } from "popups";
// Blocks
import { ProductCharacteristics } from "blocks";
// Components
import { Loader } from "components/Elements";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./ProductPage.module.scss";

// Typescript
import { IImage, IProduct, IProductWithParamsValues } from "types/models";

// Utils
import { checkAccessLevel } from "utils/ACL";
import { Constants } from "utils/Constants";

export const ProductPage: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { currentCategory } = useAppSelector((state) => state.catalogueReducer);
  const { productId } = useParams();

  const popupManager = usePopupManager();

  const [isPending, setPendingStatus] = useState(true);
  const [product, setProduct] = useState<IProductWithParamsValues>();

  const [selectedImage, setSelectedImage] = useState<IImage>();
  const [images, setImages] = useState<IImage[]>([]);

  const loadProduct = useCallback(async () => {
    setPendingStatus(true);

    const dbProduct = await ProductsAPI.findOne<IProductWithParamsValues>(productId || "", [
      "withProducer",
      "withProductType",
      "withParamValues",
      "withImages",
      "withCategory",
    ]);

    setProduct(dbProduct);

    setImages(dbProduct.images);

    if (dbProduct.images.length > 0) {
      setSelectedImage(dbProduct.images[0]);
    }

    setTimeout(() => setPendingStatus(false), 200);
  }, [productId]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct, productId]);

  const handleEditClick = () => {
    if (product) {
      const categoryId = product.categories.find((i) => i.productCategory.parental)?.id;

      popupManager.open(ProductFormPopup, {
        actionType: "editing",
        defaultValues: {
          title: product.title,
          description: product.description,
          reference: product.reference,
          producer: product.producer,
          productType: product.productType,
        },
        initialImages: product.images,
        context: { categoryId: categoryId || Number(process.env.REACT_APP_ROOT_ID) || 1, editProductId: product.id },
        onSuccessServerResponse: (updatedProduct: IProduct | null) => {
          if (updatedProduct) {
            enqueueSnackbar(`Товар "${updatedProduct.title}" обновлен`, { variant: "success" });
            loadProduct();
          }
        },
      });
    }
  };

  function handleImageSelect(this: IImage) {
    setSelectedImage(this);
  }

  const Images = useMemo(
    () =>
      images.map((image) => (
        <li
          key={image.id}
          onClick={handleImageSelect.bind(image)}
          className={cl(styles["image"], {
            [styles["image_selected"]]: image.id === selectedImage?.id,
          })}
        >
          <img className={cl(styles["image__photo"])} alt='' src={image.url} />
        </li>
      )),
    [images, selectedImage],
  );

  const handleLinkClick = () => {
    // Если пользователь перешел в продукт из категории, которая не является родительской,
    // то возвращаем его обратно в нее
    if (currentCategory) navigate(`/categories/${currentCategory.id}`);
    else navigate(`/categories/${product?.categories.find((i) => i.productCategory.parental === true)?.id}`);
  };

  return (
    <div className={cl(styles["product-page"])}>
      <Loader isActive={isPending} />

      <div className={cl(styles["product-page__header"])}>
        <div onClick={handleLinkClick} className={cl(styles["product-page__header-back"])}>
          <ArrowBackOutlinedIcon className={cl(styles["product-page__header-back-icon"])} />
          <span className={cl(styles["product-page__header-back-text"])}>Вернуться к категории</span>
        </div>
      </div>

      <div className={cl(styles["product-page__content"])}>
        <div className={cl(styles["images"])}>
          <img
            className={cl(styles["images__current-image"])}
            alt='Фотография товара'
            src={selectedImage?.url || Constants.LOADING_IMAGE_ERROR}
          />
          <ul className={cl(styles["images__list"])}>{Images}</ul>
        </div>

        <div className={cl(styles["info"])}>
          <div className={cl(styles["info__header"])}>
            <div className={cl(styles["info__main"])}>
              <h2 className={cl(styles["info__title"])}>{product?.title || "Название товара"}</h2>
              {checkAccessLevel("catalogue.products.editor") && (
                <Tooltip title='Редактировать товар'>
                  <IconButton type='button' onClick={handleEditClick} className={cl(styles["info__edit"])}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div className={cl(styles["info__producer"])}>
              {product?.producer.imageUrl ? (
                <img
                  alt='Логотип производителя'
                  src={product.producer.imageUrl}
                  className={cl(styles["info__producer-logo"])}
                />
              ) : (
                <span className={cl(styles["info__producer-name"])}>
                  {product ? product.producer.name : "Производитель"}
                </span>
              )}
            </div>

            <h3 className={cl(styles["info__reference"])}>{product?.reference ?? "Артикул товара не найден"}</h3>
          </div>

          <p className={cl(styles["info__description"])}>{product?.description ?? "Описание товара отсутствует"}</p>

          {checkAccessLevel("catalogue.params.viewer") && (
            <div className={cl(styles["info__characteristics"])}>
              <h4 className={cl(styles["info__characteristics-title"])}>Характеристики:</h4>
              <ProductCharacteristics values={product?.paramValues ?? []} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
