import React from "react";
import ReactDOM from "react-dom/client";
import { PopupProvider } from "react-popup-manager";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

// Redux
import { setupStore } from "store";
import "./styles/variables.scss";

// App
import { App } from "./App";
import { theme } from "./mui-theme";

// Components
import { ActionStatusBox } from "components/Elements";

export const store = setupStore();

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("К сожалению, нам не удалось загрузить данную страницу");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <SnackbarProvider
      autoHideDuration={5000}
      maxSnack={5}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      Components={{
        "action": ActionStatusBox,
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <PopupProvider>
            <App />
          </PopupProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  </Provider>,
);
