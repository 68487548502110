import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cl from "classnames";

// Material UI
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import { ProducersAPI } from "services/catalogue";

// Layouts
import { FiltersGroupLayout } from "layouts";

// Scss
import styles from "./GlobalFilters.module.scss";

// Typescript
import { GlobalFiltersProps } from "./GlobalFilters.props";

export const GlobalFilters: React.FC<GlobalFiltersProps> = ({ categoryId }): JSX.Element => {
  const { setValue, control } = useFormContext();
  const watchedFields = useWatch({ control });

  const [flags, setFlags] = useState({ isProducersLoading: true });
  const [producers, setProducers] = useState<string[]>([]);

  const loadProducers = useCallback(async () => {
    setFlags((state) => ({ ...state, isProducersLoading: true }));

    const producers = await ProducersAPI.findAllByCategoryId(categoryId);
    setProducers(producers.map((p) => p.name));

    setFlags((state) => ({ ...state, isProducersLoading: false }));
  }, [categoryId]);

  useEffect(() => {
    loadProducers();
  }, [loadProducers]);

  const handleProducersChange = (filterId: string) => (_: React.SyntheticEvent, values: string[]) => {
    setValue(filterId, values);
  };

  return (
    <FiltersGroupLayout>
      <li className={cl(styles["parameter"])}>
        <Autocomplete
          multiple
          size='small'
          limitTags={1}
          id='gf_producerId'
          disableCloseOnSelect
          loading={flags.isProducersLoading}
          groupBy={(option) => option[0]}
          getOptionLabel={(option) => option}
          value={watchedFields["gf_producerId"] ?? []}
          options={producers.sort((a, b) => -b[0].localeCompare(a[0]))}
          onChange={handleProducersChange("gf_producerId")}
          componentsProps={{ "popper": { sx: { zIndex: 4 } } }}
          renderInput={(params) => (
            <TextField
              {...params}
              name='gf_producerId'
              label='Производитель'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {flags.isProducersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </li>
    </FiltersGroupLayout>
  );
};
