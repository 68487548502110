import React, { HTMLAttributes, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cl from "classnames";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./PageWrapper.module.scss";

export const PageWrapper: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children }): JSX.Element => {
  const navigate = useNavigate();

  // Id категории в URL
  const { categoryId } = useParams();
  const { error } = useAppSelector((state) => state.catalogueReducer);

  useEffect(() => {
    // Если во время загрузки информации о категории произошла ошибка,
    // перенаправляем пользователя на ROOT уровень
    if (error && categoryId !== (process.env.REACT_APP_ROOT_ID || "1")) {
      navigate(`/categories/${process.env.REACT_APP_ROOT_ID || "1"}`);
    }
  }, [categoryId, error, navigate]);

  return <section className={cl(styles["catalogue"])}>{children}</section>;
};
