import React, { useCallback, useEffect, useMemo, useState } from "react";
import cl from "classnames";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

// Material UI
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";

// Services
import { AutomationAPI } from "services/catalogue";

// Layouts
import { PopupLayout } from "layouts";
// Images
import { AddOutlinedIcon, DeleteOutlineOutlinedIcon } from "assets/icons";

// Scss
import styles from "./AutomationExecutePopupProps.module.scss";

// Typescript
import { AutomationExecuteFormFields, AutomationExecutePopupProps } from "./AutomationExecutePopup.props";

export const AutomationExecutePopup: React.FC<AutomationExecutePopupProps> = ({
  isOpen,
  categoryId,
  automation,
  onClose = () => {},
}): JSX.Element => {
  const { register, handleSubmit, control } = useForm<AutomationExecuteFormFields>({ mode: "all" });
  const { fields, append, remove } = useFieldArray({ control, name: "urlParams" });

  const [isSubmitting, setSubmitting] = useState(false);
  const handleUrlParameterAppend = () => append({ key: "", value: "" });
  const handleUrlParameterRemove = useCallback((fieldId: number) => () => remove(fieldId), [remove]);

  useEffect(() => {
    append(automation.urlParams);
  }, [append, automation.urlParams]);

  const handleFormSubmit: SubmitHandler<AutomationExecuteFormFields> = async (values) => {
    setSubmitting(true);

    await AutomationAPI.executeOne(categoryId, automation.id, automation.actionType, values)
      .then(() => window.location.reload())
      .finally(() => setSubmitting(false));
  };

  const UrlParamsElements = useMemo(
    () =>
      fields.map((field, idx) => {
        return (
          <li key={field.id} className={cl(styles["url-parameter"])}>
            <TextField required fullWidth label='Ключ параметра' {...register(`urlParams.${idx}.key`)} />
            <TextField required fullWidth label='Значение' {...register(`urlParams.${idx}.value`)} />
            <Tooltip title='Удалить параметр'>
              <IconButton type='button' onClick={handleUrlParameterRemove(idx)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </li>
        );
      }),
    [fields, handleUrlParameterRemove, register],
  );

  return (
    <PopupLayout title={automation.description} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className={cl(styles["form"])}>
        <ul className={cl(styles["form__params-list"])}>{UrlParamsElements}</ul>
        <Button
          type='button'
          variant='text'
          startIcon={<AddOutlinedIcon />}
          sx={{ marginTop: "24px" }}
          onClick={handleUrlParameterAppend}
        >
          Добавить параметр
        </Button>
        <div className={cl(styles["form__buttons"])}>
          <LoadingButton type='submit' variant='contained' loading={isSubmitting} disabled={isSubmitting}>
            Запустить автоматизацию
          </LoadingButton>
          <Button type='button' variant='outlined' onClick={onClose}>
            Отмена
          </Button>
        </div>
      </form>
    </PopupLayout>
  );
};
