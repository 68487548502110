import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { usePopupManager } from "react-popup-manager";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Layouts
import { CatalogueHeader, CatalogueContent } from "layouts";
// Blocks
import { FiltersSection } from "blocks";
// Modals
import { BaseModal } from "components/Modals";
// Popups
import { CategoryFormPopup, CategoryViewPopup, ParamsForm, ProductFormPopup } from "popups";
// Components
import { Loader } from "components/Elements";

// Scss
import styles from "./CategoryPage.module.scss";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchCategoryData, fetchChildrenCategories, fetchProducts } from "store/actions/CatalogueActions";

// Utils
import { checkAccessLevel } from "utils/ACL";

// Typescript
import { IProduct } from "types/models";

export const CategoryPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();
  const [search] = useSearchParams();
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const { sortingType, flags } = useAppSelector((state) => state.catalogueReducer);

  // Id категории в URL
  const { categoryId } = useParams();

  // Запрашиваем данные о категории каждый раз, когда:
  // 1. Меняется id в URL
  // 2. Меняется тип сортировки данных
  useEffect(() => {
    // Загружаем данные по categoryId из url
    if (categoryId) dispatch(fetchCategoryData(categoryId));
  }, [categoryId, dispatch, sortingType]);

  useEffect(() => {
    if (categoryId) dispatch(fetchProducts(categoryId, 1, 50));
  }, [search, categoryId, dispatch]);

  const openCategoryPopup = useCallback(() => {
    popupManager.open(CategoryFormPopup, {
      action: "creation",
      context: { parentCategoryId: Number(categoryId) },
      onSuccessServerResponse: (createdCategory) => {
        enqueueSnackbar(`Категория "${createdCategory.translation.title}" успешно создана`, { variant: "success" });
        dispatch(fetchChildrenCategories(categoryId + "", 1, 50, true));

        popupManager.open(CategoryViewPopup, {
          initialCategory: createdCategory,
        });
      },
    });
  }, [popupManager, categoryId, enqueueSnackbar, dispatch]);

  const openProductPopup = () => {
    popupManager.open(ProductFormPopup, {
      actionType: "creation",
      context: { categoryId: Number(categoryId) },
      onSuccessServerResponse: (createdProduct: IProduct | null) => {
        if (createdProduct) {
          enqueueSnackbar(`Товар "${createdProduct.title}" успешно создан`, { variant: "success" });
          dispatch(fetchProducts(categoryId + "", 1, 50));
          popupManager.open(ParamsForm, {
            product: createdProduct,
            categoryId: createdProduct.categories.find((i) => i.productCategory.parental)?.id + "",
          });
        }
      },
    });
  };

  const handleWrapperContextMenuCapture = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const buttons = [];

    if (checkAccessLevel("catalogue.categories.admin")) {
      buttons.push({
        key: "1",
        text: "Добавить категорию",
        onClick: openCategoryPopup,
      });
    }

    if (checkAccessLevel("catalogue.products.admin")) {
      buttons.push({
        key: "2",
        text: "Добавить товар",
        onClick: openProductPopup,
      });
    }

    popupManager.open(BaseModal, {
      anchorReference: "anchorPosition",
      anchorPosition: { top: evt.clientY, left: evt.clientX },
      key: "category-modal",
      buttons,
    });
  };

  return (
    <div className={cl(styles["category-content"])}>
      <Loader
        isActive={flags.isCurrentCategoryLoading}
        style={{ zIndex: 100, position: "fixed", top: 0, left: 0, height: "100vh" }}
      />
      <CatalogueHeader />
      <div
        className={cl(styles["category-content__content"], {
          [styles["category-content__content_united"]]: pathname.includes("/products"),
        })}
      >
        {!pathname.includes("/products") && (
          <div className={cl(styles["category-content__section"])}>
            <FiltersSection />
          </div>
        )}

        <div className={cl(styles["category-content__section"])}>
          <Loader isActive={flags.isCategoriesLoading || flags.isProductsLoading} style={{ zIndex: 99 }} />
          <div className={cl(styles["category-content__section-header"])}>
            <h2 className={cl(styles["category-content__section-title"])}>Категории и товары</h2>
            <div ref={stickyHeaderRef} className={cl(styles["category-content__section-header-buttons"])} />
          </div>
          <div
            className={cl(styles["category-content__section-content"])}
            onContextMenu={pathname.includes("categories") ? handleWrapperContextMenuCapture : undefined}
          >
            <CatalogueContent stickyHeaderRef={stickyHeaderRef} />
          </div>
        </div>
      </div>
    </div>
  );
};
