import axios from "axios";
import { GlobalAPI } from "services/GlobalAPI";

class SessionsAPI extends GlobalAPI {
  async logout() {
    return axios
      .delete(`${process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1"}/sessions/logout`)
      .then((res) => res.data);
  }
}

export default new SessionsAPI();
