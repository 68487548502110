import React, { useRef } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";
import cl from "classnames";
import { NavLink } from "react-router-dom";
import { mergeRefs } from "react-merge-refs";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";

// Components
import { MoreVertOutlinedIcon, FolderIcon } from "assets/icons";

// Typescript
import { CategoryElementProps } from "./CategoryElement.props";

// Scss
import styles from "./CategoryElement.module.scss";

// Utils
import { checkAccessLevel } from "utils/ACL";

const CategoryElement: React.ForwardRefRenderFunction<HTMLLIElement, CategoryElementProps> = (
  { category, to, variant, isSelected, onModalOpen, onCategoryUnSelect },
  componentRef,
): JSX.Element => {
  const ref = useRef<HTMLLIElement>(null);

  // При любом действии сбрасываем выбранную категорию
  useIsomorphicLayoutEffect(() => {
    const handleSelectOff = (evt: MouseEvent) => {
      if (!ref.current?.contains(evt.target as Node)) {
        onCategoryUnSelect(category.id);
      }
    };

    if (isSelected && ref.current) {
      document.addEventListener("click", handleSelectOff);
      document.addEventListener("contextmenu", handleSelectOff);
    }

    return () => {
      document.removeEventListener("click", handleSelectOff);
      document.removeEventListener("contextmenu", handleSelectOff);
    };
  }, [category, isSelected, onCategoryUnSelect]);

  const handleMouseEvent = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    onModalOpen(evt);
  };

  return (
    <li
      ref={mergeRefs([componentRef, ref])}
      onContextMenu={handleMouseEvent}
      className={cl(styles["category-element"], styles[`category-element_variant-${variant}`], {
        [styles["category-element_selected"]]: isSelected,
      })}
    >
      <NavLink className={cl(styles["category-element__link"])} to={to}>
        <FolderIcon className={cl(styles["category-element__icon"])} />

        <h4 title={category.translation.title} className={cl(styles["category-element__title"])}>
          {category.translation.title}
        </h4>

        {variant === "row" && (
          <span className={cl(styles["category-element__date"])}>
            {format(new Date(category.createdAt), "dd MMMM yyyy", { locale: ru })}
          </span>
        )}

        {checkAccessLevel("catalogue.categories.editor") && (
          <MoreVertOutlinedIcon onClick={handleMouseEvent} className={cl(styles["category-element__dots"])} />
        )}
      </NavLink>
    </li>
  );
};

export default React.memo(React.forwardRef<HTMLLIElement, CategoryElementProps>(CategoryElement));
